import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl, ApiUrlStripe } from "../services/restAPI";

const PlanStatusContent = ({ userSpaceFinance }) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const userName = userSpaceFinance.email || userSpaceFinance.name; // Asegúrate de que usas el identificador correcto

        const response = await axios.get(
          `${ApiUrlStripe}BillingCustomer/CustomerUserStateSuscription/${userName}`
        );

        const { item1: subscriptionDetails } = response.data;
        
        if (subscriptionDetails) {
          setSubscriptionInfo(subscriptionDetails);
        }
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      }
    };

    fetchSubscriptionStatus();
  }, [userSpaceFinance.email, userSpaceFinance.name]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  if (!subscriptionInfo) {
    return <p>Loading subscription information...</p>;
  }

  return (
    <div>
      <div className="menu-item content">Plan Status</div>
      <div className="plan-status">
        <p><b>Plan:</b> {subscriptionInfo.suscriptionName}</p>
        <p><b>Email:</b> {subscriptionInfo.userEmail}</p>
        <p><b>Full Name:</b> {subscriptionInfo.customerName}</p>
        <p><b>Subscription State:</b> {subscriptionInfo.userState}</p>
        <p><b>Start Date:</b> {formatDate(subscriptionInfo.startDate)}</p>
        <p><b>End Date:</b> {formatDate(subscriptionInfo.endDate)}</p>
        <p><b>Number of Queries Allowed:</b> {subscriptionInfo.numberQueries}</p>
        <p><b>Number of Queries Left:</b> {subscriptionInfo.numberMissingQueries}</p>
      </div>
    </div>
  );
};

export default PlanStatusContent;
