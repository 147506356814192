// import React from "react";
// import {
//   useReactTable,
//   getCoreRowModel,
//   getSortedRowModel,
// } from "@tanstack/react-table";
// // import { Button } from "@shadcn/ui";

// const Table = ({ columns, data }) => {
//   const table = useReactTable({
//     data,
//     columns,
//     getCoreRowModel: getCoreRowModel(),
//     getSortedRowModel: getSortedRowModel(),
//   });

//   return (
//     <div className="overflow-x-auto">
//       <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
//         <thead className="bg-gray-100">
//           {table.getHeaderGroups().map(headerGroup => (
//             <tr key={headerGroup.id}>
//               {headerGroup.headers.map(column => (
//                 <th
//                   key={column.id}
//                   className="p-4 text-left text-gray-600 font-semibold"
//                   onClick={column.getToggleSortingHandler()}
//                 >
//                   {column.render('Header')}
//                   <span>
//                     {column.getIsSorted() ? (column.getIsSorted() === 'desc' ? ' 🔽' : ' 🔼') : ''}
//                   </span>
//                 </th>
//               ))}
//             </tr>
//           ))}
//         </thead>
//         <tbody className="text-gray-700">
//           {table.getRowModel().rows.map(row => (
//             <tr key={row.id} className="hover:bg-gray-50">
//               {row.getVisibleCells().map(cell => (
//                 <td key={cell.id} className="p-4 border-t border-gray-200">
//                   {cell.render('Cell')}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Table;









import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

const TableTest = () => {
  const data = React.useMemo(
    () => [
      { ticker: 'AAPL', price: 150.0, change: '+1.5%' },
      { ticker: 'GOOGL', price: 2800.0, change: '+2.3%' },
      { ticker: 'AMZN', price: 3400.0, change: '-0.5%' },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'ticker',
        header: () => 'Ticker',
      },
      {
        accessorKey: 'price',
        header: () => 'Price',
      },
      {
        accessorKey: 'change',
        header: () => 'Change',
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true, // Asegura que el ordenamiento esté habilitado
  });

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
        <thead className="bg-gray-100">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  className="p-4 text-left text-gray-600 font-semibold cursor-pointer"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {header.column.getIsSorted() ? (
                    header.column.getIsSorted() === 'desc' ? ' 🔽' : ' 🔼'
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="text-gray-700">
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className="hover:bg-gray-50">
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className="p-4 border-t border-gray-200">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableTest;
