// 1. React and third-party library imports
import React, { useEffect, useState, useRef, Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "react-bootstrap";
import "@fontsource/oxanium"; // Defaults to weight 400

// FontAwesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import ReactDOM from 'react-dom';
import Chart3D from './Chart3D';

// 2. Local component imports
import ChatBot from "./Chatbot";
import Login from "./login/Login";
import GetStarted from "./loginGetStarted/GetStarted";
import ClientCredentials from "./services/ClientCredentials";
import AnimatedText from "./AnimatedText ";
import AboutUsPopup from "./externalPages/AboutUsPopup";
import ContactUsPopup from "./externalPages/ContactUsPopup";
import DocumentationPopup from "./externalPages/DocumentationPopup";
import Countdown from "./externalPages/CountDownLandingPage";
import PrivacyPolicyPopup from "./privacyPolicy/PrivacyPolicyPopup";
import SocialLinks from "./externalPages/SocialLinks";
import SubscriptionPopup from "./externalPages/SubscriptionPopup";
import CanvasAnimation from "./externalPages/CanvasAnimation";

// 3. Redux store
import { store } from "./AudioRecord/store"; // Import your store from the store.js file
import RingLoader from "react-spinners/RingLoader";
import ConfettiExplosion from "react-confetti-explosion";

import Slider from "react-slick";

// 4. Stylesheets
import "./App.css"; // General App styles
import "./AppResponse.css"; // General App styles
import "./AppPp.css"; // Additional App styles
import "./AppStart.css"; // Start page specific styles
import "./indexPortada.css"; // Cover/Index page styles
import "./global.css"; // Global styles applicable across the app
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// 5. Static assets like images
import Logo_XXL from "./assets/newImages/img_sf_positivo_transp.png";
// import Logo_XXL from "./assets/newImages/Logo_XXL.svg";
import IconCryptoXXL from "./assets/newImages/IconCryptoXXL.png";
import IconStockXXL from "./assets/newImages/IconStockXXL.png";
import IconWTFXXL from "./assets/newImages/IconWTFXXL.png";
import SfLogo from "./assets/ourTools/SF_Logo.svg";
import AstronautaBarrasXXL from "./assets/newImages/AstronautaBarrasXXL.gif";
import AstronautaBarrasXXLCelular from "./assets/newImages/AstronautaBarrasXXLCelular.svg";
import AstronautaNumerosXXL from "./assets/newImages/AstronautaNumerosXXL.svg";
import AstronautaNumerosXXLCelular from "./assets/newImages/AstronautaNumerosXXLCelular.svg";
import EstrellasXXL from "./assets/newImages/EstrellasXXL.png";
/*import menuHamburguesa from "./assets/menuHamburguesa.png";*/
import menuHamburguesa from "./assets/menuHamburguesa.svg";
import EugeneGif from "./assets/newImages/EugeneGif.gif";

import globalStocks from "./assets/newImages/img_global_stocks.png";
import globalCrypto from "./assets/newImages/img_crypto.png";
import globalEft from "./assets/newImages/img_etf.png";

import globalRound1 from "./assets/newImages/img_img_1.png";
import globalRound2 from "./assets/newImages/img_img_2.png";
import globalRound3 from "./assets/newImages/img_img_3.png";

import globalGraphicBar from "./assets/newImages/img_dise_o_sin_t_tulo_500x844.png";
import globalGraphicCharts from "./assets/newImages/img_charts.svg";
import globalCurveCharts from "./assets/newImages/presidential.png";
import globalGraphicVector from "./assets/newImages/img_vector_192.svg";
import astronautImg from "./assets/newImages/img_pose_0018_min_2.png";
import sendInput from "./assets/newImages/Send.svg";

import slider1 from "./assets/newImages/img_pose_0018_min_1.png";
import slider2 from "./assets/newImages/AstronautaBarrasXS.png";

// 6. Llamar a la API
import axios from "axios";
import { ApiUrl } from "./services/restAPI";

/*import videoTest from "./assets/test.mp4";*/

// Initialize FontAwesome Icons
library.add(fab, fas, far);

function ImageShowcase({ images }) {
  return (
    <>
      <section className="image-showcase-container">
        {images.map((image, index) => (
          <figure key={index} className="image-container">
            <img
              loading="lazy"
              src={image.src}
              alt={image.alt}
              className="showcase-image"
            />
          </figure>
        ))}
      </section>
    </>
  );
}

function ImageShowcaseGif({ images }) {
  return (
    <>
      <section className="image-showcase-container-gif ">
        {images.map((image, index) => (
          <figure key={index} className="image-container-gif ">
            <img
              loading="lazy"
              src={image.src}
              alt={image.alt}
              className="showcase-image-gif "
            />
          </figure>
        ))}
      </section>
    </>
  );
}

function LazyLoad() {
  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    arrows: true,
    draggable: false
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slider-image">
          <img src={globalCurveCharts} className="responsive-img" />
          <div>
            <h2>PRESIDENTIAL</h2>
            <p>
              Asset trends in U.S. elections, key comparisons to spot movements before and after
            </p>
          </div>
        </div>
        <div className="slider-image">
          <img src={globalGraphicBar} className="responsive-img" />
          <div>
            <h2>TENDENCY</h2>
            <p>
              Interactive trend chart for instant asset customization and
              comparison
            </p>
          </div>
        </div>
        <div className="slider-image">
          <img src={globalCurveCharts} className="responsive-img" />
          <div>
            <h2>CRISIS</h2>
            <p>
              Compare markets between crises with customized charts
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
}
/************************************************
Component for displaying individual testimonials
*************************************************/

const TestimonialCard = ({ testimonialText, imageSrc, altText }) => (
  <div className="testimonial-card">
    <img
      loading="lazy"
      src={imageSrc}
      alt={altText}
      className="testimonial-image"
    />
    <p className="testimonial-text">{testimonialText}</p>
  </div>
);

// Main component
const TestimonialSection = () => {
  // Testimonial data could ideally come from an API or a database
  const testimonials = [
    {
      testimonialText:
        "With Eugene, I save time and get an instant, comprehensive view of the market. Essential for quick and accurate decision-making!",
      imageSrc: EstrellasXXL,
      altText: "Person giving a thumbs up",
    },
    {
      testimonialText:
        "It's the only one that allows optimization with different assets and cryptocurrencies.",
      imageSrc: EstrellasXXL,
      altText: "Person analyzing financial data",
    },
    {
      testimonialText:
        "The accuracy it provides gives me the necessary confidence to make decisions. Fundamental for any serious investor.",
      imageSrc: EstrellasXXL,
      altText: "Person reflecting on investment choices",
    },
  ];

  return (
    <>
      <section className="testimonial-section">
        <h2 className="hero-title-testimonial right-scroll">
          What people are saying about us
        </h2>
        <div className="testimonial-container right-scroll">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              testimonialText={testimonial.testimonialText}
              imageSrc={testimonial.imageSrc}
              alt={testimonial.altText}
            />
          ))}
        </div>
      </section>
    </>
  );
};

/*******************************
OUR ASSETS
********************************/
const data = [
  {
    id: 1,
    imageSrc: IconStockXXL,
    altText: "Global Stocks",
    title: "Global Stocks",
    description: "United States, Brazil, India, Germany, China and more!",
  },
  {
    id: 2,
    imageSrc: IconCryptoXXL,
    altText: "Cryptocurrencies",
    title: "Cryptos",
    description: "+ 6,000 cryptocurrencies at your fingertips",
  },
  {
    id: 3,
    imageSrc: IconWTFXXL,
    altText: "ETFs",
    title: "ETFs",
    description: "+3,700 ETFs at your disposal",
  },
];

const CategoryCard = ({ imageSrc, altText, title, description }) => (
  <div className="card-category">
    <img
      loading="lazy"
      src={imageSrc}
      alt={altText}
      className="card-category-image"
    />
    <h2 className="card-category-title">{title}</h2>
    <p className="card-category-description">{description}</p>
  </div>
);

const AssetsSection = () => (
  <section className="assets-section">
    <h2>Our Assets</h2>
    <div className="container-category">
      {data.map((item) => (
        <CategoryCard key={item.id} {...item} />
      ))}
    </div>
  </section>
);
/**
 * Section youtube
 */
const VideoSection = () => (
  <section className="youtube-section">
    <br />
    <video width="100%" autoPlay={true} muted loop>
      {/*<source src={videoTest} type="video/mp4" />*/}
      Your browser does not support the video tag.
    </video>
  </section>
);

/***************************************
 * WORK SMARTER WITH OUR TOOLS
 ***************************************/
const textStyle = {
  heading: {
    letterSpacing: "-1.5px",
    font: "50px/56px Inter, sans-serif",
    fontWeight: 700,
  },
  subHeading: {
    letterSpacing: "-0.36px",
    marginTop: "35px",
    font: "500 18px/26px Inter, -apple-system, Roboto, Helvetica, sans-serif",
  },
  button: {
    borderRadius: "40px",
    background: "linear-gradient(107deg, #2272ff 0%, #0d03c3 100%)",
    color: "#fff",
    whiteSpace: "nowrap",
    /*textTransform: "uppercase",*/
    letterSpacing: "-0.4px",
    padding: "19px 40px",
    font: "20px/150% Inter, sans-serif",
    cursor: "pointer",
    width: "250px",
  },
};

const FeatureCard = ({ title, description }) => (
  <div className="feature-card fade-in">
    <h2 className="feature-title">{title}</h2>
    <p className="feature-description">{description}</p>
  </div>
);

const ContentSection = () => {
  const features = [
    {
      title: "Optimization Portfolio",
      description:
        "Optimize your portfolio of stocks, cryptocurrencies, and ETFs to find the perfect combination that maximizes your returns and minimizes your risk. Choose your benchmark, your risk level, and more!",
    },
    {
      title: "Stationarity",
      description:
        "With the Times tool, you can analyze the stationarities of different assets and observe how much they have varied over a specific period of time. Gain a detailed insight into the evolution of assets over time.",
    },
    {
      title: "Annualized Volatility",
      description:
        "Assess the risk associated with assets through annualized volatility, and adjust investment strategies to effectively manage the risk of your asset portfolio.",
    },
    {
      title: "Percentage Change",
      description:
        "Examine the percentage change of assets over various time periods, allowing you to input dates in the most granular manner.",
    },
    {
      title: "Correlation",
      description:
        "Correlation reveals how assets move together, enabling effective portfolio diversification by selecting those with low correlations. Understanding these relationships empowers investors to make informed decisions, managing risk and enhancing overall stability.",
    },
    {
      title: "Financial Statements",
      description:
        "Access detailed information on balance sheets, income statements, cash flow, and earnings more easily than ever before.",
    },
  ];

  return (
    <section className="content-section">
      {features.map((feature, index) => (
        <FeatureCard
          key={index}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </section>
  );
};

const socialLinksData = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/space-finance-ai/",
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/channel/UC7i7g6biGjEEyI-0jNAgqyQ",
  },
  { name: "X/Twitter ", url: "https://twitter.com/SpaceFinance_AI" },
  {
    /*name: "Instagram", url: "" */
  }, // Inactivo
  {
    /*name: "Facebook", url: ""*/
  }, // Inactivo
];

const App = () => {
  const [showChatBot, setShowChatBot] = useState();
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentComponent, setCurrentComponent] = useState("login");
  const [userSpaceFinance, setUserSpaceFinance] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutUsPopupOpen, setIsAboutUsPopupOpen] = useState(false);
  const [isContactUsPopupOpen, setIsContactUsPopupOpen] = useState(false);
  const [isDocumentationPopupOpen, setIsDocumentationPopupOpen] =
    useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState(false);

  /*confetti*/
  const [isExploding, setIsExploding] = React.useState(false);
  const confettiRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openAboutUsPopup = () => {
    setIsAboutUsPopupOpen(true);
  };

  const closeAboutUsPopup = () => {
    setIsAboutUsPopupOpen(false);
  };

  const openContactUsPopup = () => {
    setIsContactUsPopupOpen(true);
  };

  const closeContactUsPopup = () => {
    setIsContactUsPopupOpen(false);
  };

  const openDocumentationPopup = () => {
    setIsDocumentationPopupOpen(true);
  };

  const closeDocumentationPopup = () => {
    setIsDocumentationPopupOpen(false);
  };

  const openSubscriptionPopup = () => {
    setIsSubscriptionPopupOpen(true);
  };

  const closeSubscriptionPopup = () => {
    setIsSubscriptionPopupOpen(false);
  };

  const openPrivacyPolicyPopup = () => {};

  const fetchUserSessionToken = async (userToken) => {
    try {
      const response = await axios.get(
        `${ApiUrl}StartUserLogin/UserSesionToken/${userToken}`
      );
      const { item1, item2 } = response.data;
      if (item1.numberError !== 0) {
        // Manejar error de la API
        console.error(item1.message);
        // Eliminar token inválido del localStorage
        localStorage.removeItem("sessionTokenSpaceFinance");
        localStorage.removeItem("usersSpaceFinance");
        return null;
      }

      return item2;
    } catch (error) {
      console.error("Error fetching user session token:", error);
      return null;
    }
  };

  useEffect(() => {
    const validateSession = async () => {
      if (initialLoad) {
        setIsSubmitting(true);
        ClientCredentials.loadCredentials();
        const userToken = localStorage.getItem("sessionTokenSpaceFinance");
        const usersSpaceFinance = localStorage.getItem("usersSpaceFinance");

        if (userToken) {
          const userData = await fetchUserSessionToken(userToken);
          //console.log("RODRIGO: " + userData.name)
          if (userData) {
            setIsAuthenticated(true);
            setUserSpaceFinance({
              email: userData.name,
              emailVerified: userData.name,
              name: userData.fullName,
              givenName: userData.fullName,
              familyName: userData.fullName,
              picture: userData.photo,
              locale: "",
            });
            toggleChatBot();
          } else {
            // Token no válido, remover del localStorage
            localStorage.removeItem("sessionTokenSpaceFinance");
            localStorage.removeItem("usersSpaceFinance");
          }
        }
        setIsSubmitting(false);
        setInitialLoad(false);
      }

      if (isAuthenticated) {
        setCurrentComponent("chatBot");
        setIsSubmitting(false);
      }
    };

    validateSession();
  }, [initialLoad]);

  // Intersection Observer setup
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            triggerExplosion();
          }
        });
      },
      {
        root: null, // observing for viewport
        rootMargin: "0px",
        threshold: 0.5, // Trigger when 50% of the target is visible
      }
    );

    if (confettiRef.current) {
      observer.observe(confettiRef.current);
    }

    return () => {
      if (confettiRef.current) {
        observer.unobserve(confettiRef.current);
      }
    };
  }, []);

  /*useEffect(() => {
    const interval = setInterval(() => {
      triggerExplosion();
    }, 5000); // Establece que el confeti se active cada 5 segundos

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonta
  }, []);*/

  useEffect(() => {
    const elements = document.querySelectorAll(".fade-in, .right-scroll");
    const showOnScroll = () => {
      elements.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add("show");
        }
      });
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", showOnScroll);
    window.addEventListener("touchstart", showOnScroll);

    showOnScroll(); // Mostrar elementos al cargar la página

    return () => {
      window.removeEventListener("scroll", showOnScroll);
      window.removeEventListener("touchstart", showOnScroll);
    };
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll(".right-scroll");
    const showOnScroll = () => {
      elements.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add("show");
        }
      });
    };

    const showOnTouch = () => {
      elements.forEach((element) => {
        element.classList.add("show");
      });
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", showOnScroll);
    window.addEventListener("touchstart", showOnTouch);

    showOnScroll(); // Mostrar elementos al cargar la página

    return () => {
      window.removeEventListener("scroll", showOnScroll);
      window.removeEventListener("touchstart", showOnTouch);
    };
  }, []);

  const toggleChatBot = () => {
    setShowChatBot(!showChatBot);
  };

  const handleAuthentication = (authenticated, userData) => {
    setIsAuthenticated(authenticated);
    if (authenticated) {
      setUserSpaceFinance(userData);
      localStorage.setItem("usersSpaceFinance", JSON.stringify(userData));
      setCurrentComponent("chatBot");
    }
  };

  const showLogin = () => {
    setCurrentComponent("login");
    toggleChatBot(); // También abre el chatbot
  };

  const showGetStarted = () => {
    setCurrentComponent("getStarted");
    toggleChatBot(); // También abre el chatbot
  };

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      showGetStarted();
    }
  }

  const NavigationItem = ({ children, onClick }) => (
    <div className="navigation-item" onClick={onClick}>
      {children}
    </div>
  );

  const HeroSection = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.pageYOffset > 0);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
      <div className="hero-section-wrapper">
        {/* Aquí se coloca la animación del canvas */}
        <CanvasAnimation />

        {/* El contenido de HeroSection */}
        <div className="hero-section-container">
          <header className={`hero-header ${isScrolled ? "scrolled" : ""}`}>
            <img
              // src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b0825b2e79e6f85fec9b1a222d0360ea03e8bafe8c9b2b5a08e7a5aefaf8fae?placeholderIfAbsent=true&amp;apiKey=bfb944dadcb447ea9d9e911e2ef54b0a"
              src={SfLogo}
              alt="Company Logo"
              className="logo"
              style={{ width: "5.5%" }}
            />
            <div className="menu-toggle" onClick={toggleMenu}>
              <img src={menuHamburguesa} alt="Menú" />
            </div>
            <nav className={isMenuOpen ? "nav-open" : ""}>
              <NavigationItem onClick={openDocumentationPopup}>
                Documentation
              </NavigationItem>
              <NavigationItem onClick={openSubscriptionPopup}>
                Pricing
              </NavigationItem>
              <NavigationItem onClick={openAboutUsPopup}>
                About Us
              </NavigationItem>
              <NavigationItem onClick={openContactUsPopup}>
                Contact Us
              </NavigationItem>
            </nav>
            {isAboutUsPopupOpen && <AboutUsPopup onClose={closeAboutUsPopup} />}
            {isContactUsPopupOpen && (
              <ContactUsPopup onClose={closeContactUsPopup} />
            )}
            {isDocumentationPopupOpen && (
              <DocumentationPopup onClose={closeDocumentationPopup} />
            )}
            {isSubscriptionPopupOpen && (
              <SubscriptionPopup
                onClose={closeSubscriptionPopup}
                onGetStarted={showGetStarted}
              />
            )}
            <button className="started-button-normal" onClick={showLogin}>
              Login
            </button>
          </header>
          <section className="hero-content">
            <h2 className="hero-title">
              Redefining the future of investment research
            </h2>
            <p className="hero-subtitle">
              Calculation, speed and reliable - at once
            </p>
            {/*<button className="get-startedButton" onClick={showGetStarted}>*/}
            {/*  Get Started*/}
            {/*</button>*/}
            {/*<input type="text" className="input-get-register" onKeyPress={handleKeyPress}/>*/}
            <div className="message-input-container-shadown">
              <textarea className="textarea-shadown-1" rows="1" placeholder="Please use tickers" onKeyPress={handleKeyPress}></textarea>
              <button className="button-shadown-1" type="button" disabled="">
                <img
                  src={sendInput} alt="Send"
                  className="imageSend-shadown" />
              </button>
            </div>            
          </section>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={AstronautaNumerosXXLCelular}
            />
          </picture>
        </div>
        <div class="wave-section-container">
          <div class="wave-section-animation">
            <svg
              class="waves"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <g class="parallax">
                <use
                  href="#gentle-wave"
                  x="48"
                  y="0"
                  fill="rgba(255,255,255,0.7)"
                />
                <use
                  href="#gentle-wave"
                  x="48"
                  y="3"
                  fill="rgba(255,255,255,0.5)"
                />
                <use
                  href="#gentle-wave"
                  x="48"
                  y="5"
                  fill="rgba(255,255,255,0.3)"
                />
                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  };

  const images = [
    { src: Logo_XXL, alt: "Space Finance" },
    // Repeat for more images
  ];

  const imagesGif = [
    { src: EugeneGif, alt: "Space Finance - Eugene" },
    // Repeat for more images
  ];

  // Reusable components
  const MenuItem = ({ title, items, onClick }) => (
    <section className="menu-item">
      <h3>{title}</h3>
      {items.map((item, index) => (
        <div key={index} onClick={() => onClick(item)}>
          {item}
        </div>
      ))}
    </section>
  );

  const handleMenuItemClick = (itemTitle) => {
    switch (itemTitle) {
      case "About Us":
        openAboutUsPopup();
        break;
      case "Contact Us":
        openContactUsPopup();
        break;
      case "Documentation":
        openDocumentationPopup();
        break;
      case "Privacy Policy":
        window.open("./privacy-policy.html", "_blank");
        break;
      // Añade más casos según sea necesario
      default:
        console.log("No action defined for", itemTitle);
    }
  };

  const companyInfo = {
    title: "Company",
    items: [
      "About Us",
      /*"Newsletters",*/
      "Privacy Policy",
      /*"Follow Us",*/
      "Podcast",
      "Contact Us",
      "Documentation",
    ],
  };

  const SocialLink = ({ name }) => <div className="social-link">{name}</div>;

  /*const partnerInfo = {
    title: "Our Partners",
    items: ["Career", "Contact Us", "FAQ"],
  };*/

  const handleCloseLogin = () => {
    localStorage.removeItem("sessionTokenSpaceFinance");
    localStorage.removeItem("usersSpaceFinance");
    localStorage.clear();

    window.location.href = "/"; // Redirige al usuario a la página inicial
  };

  if (isSubmitting) {
    return (
      <div className="loading-container">
        <RingLoader color={"#133588"} loading={isSubmitting} size={150} />
      </div>
    );
  }

  // Función para manejar la explosión de confeti
  const triggerExplosion = () => {
    setIsExploding(true);
    setTimeout(() => setIsExploding(false), 1500); // Desactiva después de 500ms
  };

  return (
    <Provider store={store}>
      <Router>
        <PrivacyPolicyPopup />
        {showChatBot ? (
          <div className="App holder-pp">
            {/*<header>
              <h1
                className="main-title"
                onClick={toggleChatBot}
                style={{ paddingTop: "15px" }}
              >
                SpaceFinance.AI
              </h1>
            </header>*/}
            {!isAuthenticated ? (
              currentComponent === "login" ? (
                <Login
                  onAuthenticate={handleAuthentication}
                  onClose={handleCloseLogin}
                />
              ) : (
                <GetStarted
                  onAuthenticate={handleAuthentication}
                  onClose={handleCloseLogin}
                />
              )
            ) : (
              <div>
                {userSpaceFinance && (
                  <ChatBot
                    userSpaceFinance={userSpaceFinance}
                    selectedTickers={selectedTickers}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            <HeroSection />
            {/*<Chart3D />*/}
            <section className="banner">
              <div className="content">
                <div className="text right-scroll">
                  {/*<h2 style={textStyle.heading}>*/}
                  <h2 className="banner-h2">
                    <b>Your own</b> personal <b>AI</b> who is always on duty.
                  </h2>
                  <br />
                  <h2 className="banner-h2">
                    Answering your questions <b>in 3.5 seconds</b>
                  </h2>
                </div>
                <div className="image right-scroll">
                  <picture>
                    <img
                      src={AstronautaBarrasXXL}
                      alt="Astronauta y barras"
                      className="responsive-img fade-in"
                    />
                  </picture>
                </div>
              </div>
            </section>
            <section className="section-slider">
              <div>
                <h2>
                  <b>Precision</b> we care about, <b>tendencies</b> we track,
                  and <b>simplicity</b> at its best - delivered <b>fast</b>.
                </h2>
              </div>
              <div>
                <LazyLoad />
              </div>
            </section>
            <section className="section-slider-small">
              <div>
                <h2>
                  <b>Precision</b> we care about, <b>tendencies</b> we track,
                  and <b>simplicity</b> at its best - delivered <b>fast</b>.
                </h2>
              </div>
              <div>
                <LazyLoad />
              </div>
            </section>
            <section className="text-unlock">
              <div className="text">
                <h2 className="banner-h2">
                  Unlock the potential of <b>our assets</b> and elevate your
                  research
                </h2>
              </div>
            </section>
            <section className="icons-test-full">
              <div className="content-images-test">
                <div className="image right-scroll">
                  <img
                    style={{
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      // minHeight: "100%",
                    }}
                    src={globalStocks}
                    alt="Globals imgs"
                    className="responsive-img"
                  />
                  <br/>
                  <h2 className="banner-h3" style={{ textAlign: "center" }}>
                    Global Stocks
                  </h2>
                  <br />
                  <p className="icons-test-full-p">
                    The most important markets in the world
                  </p>
                  <br />
                </div>
                <div className="image right-scroll">
                  <img
                    style={{
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={globalCrypto}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                  <br/>
                  <h2 className="banner-h3" style={{ textAlign: "center" }}>
                    Cryptos
                  </h2>
                  <br />
                  <p
                    className="icons-test-full-p"
                    style={{ padding: "0 2.7rem" }}
                  >
                    <b>6,000+ </b> cryptocurrencies at your fingertips
                  </p>
                  <br />
                </div>
                <div className="image right-scroll">
                  <img
                    style={{
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={globalEft}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                  <br/>
                  <h2 className="banner-h3" style={{ textAlign: "center" }}>
                    ETFs
                  </h2>
                  <br />
                  <p className="icons-test-full-p">
                    <b>3,700 +</b> ETFs at your disposal
                  </p>
                  <br />
                </div>
              </div>
            </section>
            <section className="icons-test-small">
              <div className="content-images-test-small">
                <div className="image">
                  <img
                    style={{
                      width: "95%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={globalStocks}
                    alt="Globals imgs"
                    className="responsive-img"
                  />
                  <div style={{ height: "2rem" }}></div>
                  <h2 style={{ textAlign: "center", fontWeight: "900" }}>Global Stocks</h2>
                  <br />
                  <p className="icons-test-full-p">
                    The most important markets in the world
                  </p>
                  <br />
                </div>
                <div className="image">
                  <img
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={globalCrypto}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                  <div style={{ height: "2rem" }}></div>
                  <h2 className="banner-h3" style={{ textAlign: "center", fontWeight: "900" }}>
                    Cryptos
                  </h2>
                  <br />
                  <p
                    className="icons-test-full-p"
                    style={{ padding: "0 2.7rem" }}
                  >
                    <b>6,000+ </b> cryptocurrencies at your fingertips
                  </p>
                  <br />
                </div>
                <div className="image">
                  <img
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={globalEft}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                  <div style={{ height: "2rem" }}></div>
                  <h2 className="banner-h3" style={{ textAlign: "center", fontWeight: "900" }}>
                    ETFs
                  </h2>
                  <br />
                  <p className="icons-test-full-p">
                    <b>3,700 +</b> ETFs at your disposal
                  </p>
                  <br />
                </div>
              </div>
            </section>
            <section className="text-unlock">
              <div className="text">
                <h2 className="text-unlock-h2">
                  Trusted by those who care about their finances
                </h2>
              </div>
            </section>
            <section className="icons-round-full">
              <div className="image">
                <div className="content-image">
                  <img
                    src={globalRound1}
                    alt="Globals imgs"
                    className="responsive-img"
                  />
                </div>
                <br />
                <div className="rount-start">
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                </div>
                <br />
                <br />
                <br />
                <p className="icons-test-full-p">
                  With Eugene, I save time and get an instant, comprehensive
                  view of the market. Essential for quick and accurate
                  decision-making!
                </p>
                <br />
              </div>
              <div className="image">
                <div className="content-image">
                  <img
                    src={globalRound2}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                </div>
                <br />
                <div className="rount-start">
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                </div>
                <br />
                <br />
                <br />
                <p className="icons-test-full-p">
                  {" "}
                  It's the only one that allows optimization with different
                  assets and cryptocurrencies
                </p>
                <br />
              </div>
              <div className="image">
                <div className="content-image">
                  <img
                    src={globalRound3}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                </div>
                <br />
                <div className="rount-start">
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                </div>
                <br />
                <br />
                <br />
                <p>
                  {" "}
                  The accuracy it provides gives me the necessary confidence to
                  make decisions. Fundamental for any serious investor
                </p>
                <br />
              </div>
            </section>
            <section className="icons-round-small">
              <div className="image">
                <div className="content-image">
                  <img
                    src={globalRound1}
                    alt="Globals imgs"
                    className="responsive-img"
                  />
                </div>
                <br />
                <div className="rount-start">
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                </div>
                <br />
                <br />
                <br />
                <p className="icons-test-full-p">
                  With Eugene, I save time and get an instant, comprehensive
                  view of the market. Essential for quick and accurate
                  decision-making!
                </p>
                <br />
              </div>
              <div className="image">
                <div className="content-image">
                  <img
                    src={globalRound3}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                </div>
                <br />
                <div className="rount-start">
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                </div>
                <br />
                <br />
                <br />
                <p className="icons-test-full-p">
                  {" "}
                  the only one that allows optimization with different assets
                  and cryptocurrencies
                </p>
                <br />
              </div>
              <div className="image">
                <div className="content-image">
                  <img
                    src={globalRound2}
                    alt="Astronauta y barras"
                    className="responsive-img"
                  />
                </div>
                <br />
                <div className="rount-start">
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                  <span data-index="0" data-forhalf="★">
                    ★
                  </span>
                </div>
                <br />
                <br />
                <br />
                <p>
                  With Eugene, I save time and get an instant, comprehensive
                  view of the market. Essential for quick and accurate
                  decision-making!
                </p>
                <br />
              </div>
            </section>

            <section className="icons-graphics-full">
              <div className="image">
                <img
                  src={globalGraphicCharts}
                  alt="Graphics imgs"
                  className="responsive-img"
                />
                <div>
                  <h2>Portafolio Optimization</h2>
                  <a href="">Click</a>
                </div>
              </div>
              <div className="image">
                <img
                  src={globalGraphicVector}
                  alt="Graphics imgs"
                  className="responsive-img"
                />
                <div>
                  <h2>Correlation</h2>
                  <a href="">Click</a>
                </div>
              </div>
              <div className="image">
                <img
                  src={globalGraphicCharts}
                  alt="Graphics imgs"
                  className="responsive-img"
                />
                <div>
                  <h2>Halving</h2>
                  <a href="">Click</a>
                </div>
              </div>
            </section>
            <section className="icons-graphics-full">
              <div className="image">
                <img
                  src={globalGraphicCharts}
                  alt="Graphics imgs"
                  className="responsive-img"
                />
                <div>
                  <h2>Portafolio Optimization</h2>
                  <a href="">Click</a>
                </div>
              </div>
              <div className="image">
                <img
                  src={globalGraphicVector}
                  alt="Graphics imgs"
                  className="responsive-img"
                />
                <div>
                  <h2>Correlation</h2>
                  <a href="">Click</a>
                </div>
              </div>
              <div className="image">
                <img
                  src={globalGraphicCharts}
                  alt="Graphics imgs"
                  className="responsive-img"
                />
                <div>
                  <h2>Halving</h2>
                  <a href="">Click</a>
                </div>
              </div>
            </section>

            <section className="block-astronaut-full">
              <div className="row-a">
                <img
                  src={astronautImg}
                  alt="Graphics imgs"
                  className="responsive-img"
                  // style={{ width: "40%" }}
                />
              </div>
              <div className="row-b">
                <h2>
                  <b>Rocket</b> your <b>finances</b> up to <b>space</b>
                </h2>
                <button
                  className="get-startedButton"
                  style={{ width: "initial" }}
                  onClick={showGetStarted}
                >
                  Get Started Now
                </button>
              </div>
            </section>

            <section className="block-astronaut-small">
              <div>
                <img
                  src={astronautImg}
                  className="responsive-img"
                  style={{ width: "60%" }}
                />
              </div>
              <div>
                <h2>
                  <b>Rocket</b> your <b>finances</b> up to <b>space</b>
                </h2>
                <button
                  className="get-startedButton"
                  style={{
                    width: "initial",
                    borderRadius: "5px",
                    padding: "8px",
                  }}
                  onClick={showGetStarted}
                >
                  Get Started Now
                </button>
              </div>
            </section>

            <br />
            {/*<ImageShowcaseGif images={imagesGif} />*/}
            <header className="hero-section-footer-new">
              <div>
                <div>
                  <h3 className="branding-title-1 fade-in">SPACE FINANCE.AI</h3>
                  <p>Specialized chat in</p>
                  <p>financial markets </p>
                  <p>empowered by AI.</p>
                </div>
                <div className="hsf-section-2">
                  <MenuItem {...companyInfo} onClick={handleMenuItemClick} />
                  <SocialLinks links={socialLinksData} />
                </div>
              </div>
            </header>
            <header className="hero-section-footer-new-small">
              <div>
                <div>
                  <h3 className="branding-title-1 fade-in">SPACE FINANCE.AI</h3>
                  <p>Specialized chat in</p>
                  <p>financial markets </p>
                  <p>empowered by AI.</p>
                </div>
                <div className="hsf-section-2">
                  <MenuItem {...companyInfo} onClick={handleMenuItemClick} />
                  <SocialLinks links={socialLinksData} />
                </div>
              </div>
            </header>

            {/*<header className="hero-section-footer">*/}
            {/*  <div className="hero-wrap">*/}
            {/*    <div className="content-wrap">*/}
            {/*      <div className="cta-banner">*/}
            {/*        <h3 className="branding-title-1 fade-in">SPACE FINANCE.AI</h3>*/}
            {/*        <p>*/}
            {/*          Specialized chat in*/}
            {/*        </p>*/}
            {/*        <p>financial markets </p>*/}
            {/*        <p>empowered by AI.</p>*/}
            {/*      </div>*/}
            {/*      <MenuItem {...companyInfo} onClick={handleMenuItemClick} />*/}
            {/*      <SocialLinks links={socialLinksData} />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</header>*/}
            <footer>
              <div className="footer-content">
                Copyright © {new Date().getFullYear()} Space Finance.AI.
              </div>
            </footer>
          </>
        )}
      </Router>
    </Provider>
  );
};

export default App;
library.add(fab, fas, far);
