
export const colors = [
    "#D97A2D", 
    "#007ACC", 
    "#20C9C0", 
    "#00907C", 
    "#9A29C9", 
    "#08088A",
    "#D73688", 
    "#D4D404", 
    "#8B2222", 
    "#696969", 
    "#00CCCC", 
    "#731EBF",
    "#005200", 
    "#556B2F", 
    "#8B0000", 
    "#4682B4", 
    "#2F4F4F", 
    "#4B0082",
  ];