// SubscriptionPopup.js
import React from "react";
import "./SubscriptionsPopup.css"; // Usa el mismo estilo que LimitPopup

const SubscriptionsPopup = ({ message, onClose }) => {
  return (
    <div className="subscriptions-popup-overlay">
      <div className="subscriptions-popup">
        <div className="subscriptions-popup-header">
          <button className="subscriptions-popup-close-btn" onClick={onClose}>
            &times;
          </button>
          <h3>Subscription Required</h3>
        </div>
        <div className="subscriptions-popup-content">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsPopup;
