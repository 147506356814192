import React, { useEffect, useState } from "react";
import Select from "react-select";
import TrendsBarChartWithSelect from "./TrendsBarChartWithSelect";
import RingLoader from "react-spinners/RingLoader";
import "./Trends.css";

const TrendsChart = ({
  data1,
  data2,
  data3,
  data4,
  data5,
  data6,
  data7,
  data8,
}) => {
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isWeekSelectionDisabled, setIsWeekSelectionDisabled] = useState(true);
  const [isDaySelectionDisabled, setIsDaySelectionDisabled] = useState(true);
  const [isMonthSelectionDisabled, setIsMonthSelectionDisabled] =
    useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const colorDictionary = {
    2000: "#1f77b4",
    2001: "#ff7f0e",
    2002: "#2ca02c",
    2003: "#d62728",
    2004: "#9467bd",
    2005: "#8c564b",
    2006: "#e377c2",
    2007: "#7f7f7f",
    2008: "#bcbd22",
    2009: "#17becf",
    2010: "#1f77b4",
    2011: "#ff7f0e",
    2012: "#2ca02c",
    2013: "#d62728",
    2014: "#9467bd",
    2015: "#8c564b",
    2016: "#e377c2",
    2017: "#7f7f7f",
    2018: "#bcbd22",
    2019: "#17becf",
    2020: "#1f77b4",
    2021: "#ff7f0e",
    2022: "#2ca02c",
    2023: "#d62728",
    2024: "#9467bd",
  };

  const handleWeekDaySelection = (selectionType) => {
    if (selectionType === "weeks") {
      setIsDaySelectionDisabled(true);
      setSelectedDays([]);
      setIsWeekSelectionDisabled(false);
    } else if (selectionType === "days") {
      setIsWeekSelectionDisabled(true);
      setSelectedWeeks([]);
      setIsDaySelectionDisabled(false);
    }
  };

  const handleSelectChange = (setter, selectedOptions) => {
    setter(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const groupByYear =
    selectedYears.length > 0 &&
    selectedMonths.length === 0 &&
    selectedWeeks.length === 0 &&
    selectedDays.length === 0;

  useEffect(() => {
    setIsLoading(true);

    if (selectedYears.length > 0) {
      setIsMonthSelectionDisabled(false);
      setIsWeekSelectionDisabled(selectedMonths.length === 0);
      setIsDaySelectionDisabled(selectedMonths.length === 0);
    } else {
      // Limpia los filtros si no hay años seleccionados
      setIsMonthSelectionDisabled(true);
      setIsWeekSelectionDisabled(true);
      setIsDaySelectionDisabled(true);
      setSelectedMonths([]);
      setSelectedWeeks([]);
      setSelectedDays([]);
    }

    if (selectedMonths.length === 0) {
      // Limpia los filtros de semanas y días si no hay meses seleccionados
      setSelectedWeeks([]);
      setSelectedDays([]);
      setIsWeekSelectionDisabled(true);
      setIsDaySelectionDisabled(true);
    }

    // Si hay semanas o días seleccionados, forzamos la selección única de mes y lo establecemos en "January" si no hay un mes seleccionado
    if (selectedWeeks.length > 0 || selectedDays.length > 0) {
      if (selectedMonths.length === 0) {
        setSelectedMonths(["January"]); // Por defecto, selecciona "January"
      } else if (selectedMonths.length > 1) {
        setSelectedMonths([selectedMonths[0]]); // Solo permite un mes seleccionado
      }
    }

    const extractData = (dataObj) => {
      if (!dataObj) return [];

      return Object.entries(dataObj).flatMap(([ticker, tickerData]) => {
        if (tickerData && Array.isArray(tickerData.complete_data)) {
          return tickerData.complete_data
            .map((dataPoint) => {
              if (!dataPoint.Date || !dataPoint["% Change"]) {
                return null;
              }
              return {
                ...dataPoint,
                Ticker: ticker,
              };
            })
            .filter(Boolean);
        }
        return [];
      });
    };

    let data = [
      ...(data1 ? extractData(data1) : []),
      ...(data2 ? extractData(data2) : []),
      ...(data3 ? extractData(data3) : []),
      ...(data4 ? extractData(data4) : []),
      ...(data5 ? extractData(data5) : []),
      ...(data6 ? extractData(data6) : []),
      ...(data7 ? extractData(data7) : []),
      ...(data8 ? extractData(data8) : []),
    ];

    console.log("Datos completos antes de filtrar:", data);

    if (groupByYear) {
      data = data.filter((d) =>
        selectedYears.some(
          (year) => d.Date.includes(year) && d.Date.includes("Year")
        )
      );
    } else {
      if (selectedYears.length > 0) {
        data = data.filter((d) =>
          selectedYears.some((year) => d.Date.includes(year))
        );
      }

      if (selectedMonths.length > 0) {
        data = data.filter((d) => {
          const monthYearRegex = /\(([A-Za-z]+)\s(\d{4})\)$/;
          const match = d.Date.match(monthYearRegex);

          if (match) {
            const [, month, year] = match;
            return (
              selectedMonths.includes(month) && selectedYears.includes(year)
            );
          }
          return false;
        });
      }
    }

    if (selectedWeeks.length > 0) {
      const dataForWeeks = [
        ...(data1 ? extractData(data1) : []),
        ...(data2 ? extractData(data2) : []),
        ...(data3 ? extractData(data3) : []),
        ...(data4 ? extractData(data4) : []),
        ...(data5 ? extractData(data5) : []),
        ...(data6 ? extractData(data6) : []),
        ...(data7 ? extractData(data7) : []),
        ...(data8 ? extractData(data8) : []),
      ];

      data = dataForWeeks.filter((d) => {
        const weekRegex = /\((Week \d+)\s([A-Za-z]+)\s(\d{4})\)$/;
        const match = d.Date.match(weekRegex);

        if (match) {
          const [, week, month, year] = match;
          return (
            selectedWeeks.includes(week) &&
            selectedMonths.includes(month) &&
            selectedYears.includes(year)
          );
        }
        return false;
      });
    }

    // Función para convertir nombres de meses a números
    const monthNameToNumber = (monthName) => {
      const months = {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
      };
      return months[monthName] || "";
    };

    // Función para agregar ceros a días y meses
    const padZero = (value) => {
      return value.toString().padStart(2, "0");
    };

    if (selectedDays.length > 0) {
      const dataForDays = [
        ...(data1 ? extractData(data1) : []),
        ...(data2 ? extractData(data2) : []),
        ...(data3 ? extractData(data3) : []),
        ...(data4 ? extractData(data4) : []),
        ...(data5 ? extractData(data5) : []),
        ...(data6 ? extractData(data6) : []),
        ...(data7 ? extractData(data7) : []),
        ...(data8 ? extractData(data8) : []),
      ];
    
      //console.log("Datos completos antes de filtrar:", dataForDays);
      /*console.log("Años seleccionados:", selectedYears);
      console.log("Meses seleccionados:", selectedMonths);
      console.log("Días seleccionados:", selectedDays);*/
    
      data = dataForDays.filter((d) => {
        // Excluir los datos con rangos de fechas (contienen "to")
        if (d.Date.includes("to")) {
          return false;
        }
    
        // Regex para encontrar fechas específicas en formato YYYY-MM-DD
        const dateRegex = /(\d{4})-(\d{2})-(\d{2})/;
        const match = d.Date.match(dateRegex);
    
        if (match) {
          const [fullDate, year, month, day] = match;
    
          // Convertir el mes seleccionado de nombre a número
          const selectedMonth = monthNameToNumber(selectedMonths[0]);
    
          // Comparar la fecha completa con cada día seleccionado
          const isMatched = selectedDays.some((selectedDay) => {
            // Iteramos sobre todos los años seleccionados
            return selectedYears.some((year) => {
              const targetDate = `${year}-${selectedMonth}-${padZero(selectedDay)}`;
              return fullDate === targetDate;
            });
          });
    
          //if (isMatched) {
            //console.log("Data emparejada correctamente:", fullDate);
          //}
    
          return isMatched;
        }
        return false;
      });
    
      /*console.log("Datos después de aplicar el filtro de días:", data);*/
    }
    

    // Log para ver los datos después del filtro
    //console.log("Datos después de aplicar el filtro de días:", data);

    data = data.filter((d) => d.Ticker && d["% Change"] !== undefined);

    data.sort((a, b) => {
      const getDateValue = (dateString) => {
        const datePart = dateString.split(" ")[0];
        return new Date(datePart).getTime();
      };

      return getDateValue(b.Date) - getDateValue(a.Date);
    });

    setFilteredData(data);
    setIsLoading(false);
  }, [
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    selectedYears,
    selectedMonths,
    selectedWeeks,
    selectedDays,
  ]);

  const sortedSelectedYears = [...selectedYears].sort(
    (a, b) => parseInt(b) - parseInt(a)
  );

  const yearOptions = Array.from({ length: 25 }, (_, i) => 2000 + i)
    .map((year) => ({
      value: year.toString(),
      label: year.toString(),
      color: colorDictionary[year],
    }))
    .sort((a, b) => parseInt(b.value) - parseInt(a.value));

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((month) => ({ value: month, label: month }));

  const weekOptions = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"].map(
    (week) => ({ value: week, label: week })
  );

  const dayOptions = Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
    value: day.toString(),
    label: day.toString(),
  }));

  return (
    <>
      <div className="filters-container">
        <div className="filter-row">
          <div className="filter-group">
            <b>
              <label>Year: </label>
            </b>
            <Select
              options={yearOptions}
              isMulti
              onChange={(selectedOptions) =>
                handleSelectChange(setSelectedYears, selectedOptions)
              }
              value={yearOptions.filter((option) =>
                selectedYears.includes(option.value)
              )}
              className="custom-input basic-multi-select"
              classNamePrefix="select"
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  color: colorDictionary[state.data.value],
                }),
                multiValue: (provided, state) => ({
                  ...provided,
                  backgroundColor: colorDictionary[state.data.value],
                }),
                multiValueLabel: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
                multiValueRemove: (provided, state) => ({
                  ...provided,
                  color: "white",
                  ":hover": {
                    backgroundColor: colorDictionary[state.data.value],
                    color: "black",
                  },
                }),
              }}
            />
          </div>
          <div className="filter-group">
            <b>
              <label>Month: </label>
            </b>
            <Select
              options={monthOptions}
              isMulti={!(selectedWeeks.length > 0 || selectedDays.length > 0)} // Cambia a selección simple si hay semanas o días seleccionados
              onChange={(selectedOptions) => {
                if (selectedWeeks.length > 0 || selectedDays.length > 0) {
                  // Si es selección simple, solo selecciona un mes
                  handleSelectChange(
                    setSelectedMonths,
                    selectedOptions ? [selectedOptions] : []
                  );
                } else {
                  handleSelectChange(setSelectedMonths, selectedOptions);
                }
                setIsWeekSelectionDisabled(false);
                setIsDaySelectionDisabled(false);
              }}
              value={monthOptions.filter((option) =>
                selectedMonths.includes(option.value)
              )}
              isDisabled={isMonthSelectionDisabled}
              className="custom-input basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="filter-row">
          <div className="filter-group">
            <b>
              <label>Week: </label>
            </b>
            <Select
              options={weekOptions}
              isMulti
              onChange={(selectedOptions) => {
                handleWeekDaySelection("weeks");
                handleSelectChange(setSelectedWeeks, selectedOptions);
              }}
              value={weekOptions.filter((option) =>
                selectedWeeks.includes(option.value)
              )}
              isDisabled={isWeekSelectionDisabled}
              className="custom-input basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="filter-group">
            <b>
              <label>Day: </label>
            </b>
            <Select
              options={dayOptions}
              isMulti
              onChange={(selectedOptions) => {
                handleWeekDaySelection("days");
                handleSelectChange(setSelectedDays, selectedOptions);
              }}
              value={dayOptions.filter((option) =>
                selectedDays.includes(option.value)
              )}
              isDisabled={isDaySelectionDisabled}
              className="custom-input basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="loader-container">
          <RingLoader color={"#133588"} loading={isLoading} size={150} />
        </div>
      ) : (
        selectedYears.length > 0 &&
        filteredData.length > 0 && (
          <>
            <TrendsBarChartWithSelect
              data={filteredData}
              selectedYears={sortedSelectedYears}
              selectedMonths={selectedMonths}
              selectedWeeks={selectedWeeks}
              selectedDays={selectedDays}
              groupByYear={groupByYear}
              colorDictionary={colorDictionary}
            />
            <div className="trends-container1">
              <div className="trends-container">
                <table className="trends-table">
                  <thead>
                    <tr>
                      <th>Ticker</th>
                      <th>Date</th>
                      <th>Start Price</th>
                      <th>End Price</th>
                      <th>% Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((data, index) => (
                      <tr key={index}>
                        <td>{data.Ticker}</td>
                        <td>{data.Date}</td>
                        <td>
                          {selectedDays.length > 0
                            ? "N/A"
                            : data["Start Price"] !== undefined &&
                              !isNaN(data["Start Price"])
                            ? data["Start Price"].toFixed(2)
                            : "N/A"}
                        </td>
                        <td>
                          {selectedDays.length > 0 &&
                          data["Price"] !== undefined &&
                          !isNaN(data["Price"])
                            ? data["Price"].toFixed(2) // Mostrar Price si estamos filtrando por días
                            : data["End Price"] !== undefined &&
                              !isNaN(data["End Price"])
                            ? data["End Price"].toFixed(2)
                            : "N/A"}
                        </td>
                        <td>
                          {data["% Change"] !== undefined &&
                          !isNaN(parseFloat(data["% Change"]))
                            ? (
                                parseFloat(
                                  typeof data["% Change"] === "string"
                                    ? data["% Change"].replace("%", "")
                                    : data["% Change"]
                                ) * 100
                              ).toFixed(2)
                            : "N/A"}
                          %
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default TrendsChart;
