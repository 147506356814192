import React, { useState, useEffect, useRef } from "react";
//import profileUser from "../assets/profile-user.png"; 
//import upgradeIcon from "../assets/upgrade-icon.png"; 
//import settingsIcon from "../assets/settings-icon.png"; 
//import closeSessionIcon from "../assets/close-session-icon.png"; 
import profileUser from "../assets/profile-user.svg"; 
import upgradeIcon from "../assets/upgrade-icon.svg"; 
import myPlanIcon from "../assets/myplan-icon.svg"; 
import settingsIcon from "../assets/settings-icon.svg"; 
import closeSessionIcon from "../assets/close-session-icon.svg"; 
import "./ChatbotUserProfile.css";
import SettingsPopup from "../settings/SettingsPopup";
import SubscriptionPopupStripe  from "../stripe/SubscriptionPopupStripe";
import MyPlanPopup from "../settings/MyPlanPopup";

const ChatBotUserProfile = ({ userSpaceFinance }) => {  
  const [showMenu, setShowMenu] = useState(false);
  const [imageSrc, setImageSrc] = useState(profileUser);
  const menuRef = useRef(); // Ref para el contenedor del menú

  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);

  const [showMyPlanPopup, setShowMyPlanPopup] = useState(false);

  // Intenta precargar la imagen de Google y luego actualiza el estado
  useEffect(() => {
    const image = new Image();    
    image.onload = () => setImageSrc(userSpaceFinance.picture); // Cambia la imagen cuando se haya cargado
    image.onerror = () => setImageSrc(profileUser); // Usa la imagen predeterminada en caso de error
    image.src = userSpaceFinance.picture;
  }, [userSpaceFinance.picture]);

  useEffect(() => {
    // Función para manejar clic fuera del menú
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false); // Ocultar menú si el clic fue fuera
      }
    };

    // Agregar escuchador de eventos al documento
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remover escuchador de eventos al limpiar el componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]); // Dependencias del efecto

  const toggleMenu = (event) => {
    event.stopPropagation(); // Detiene la propagación del evento
    setShowMenu(!showMenu);
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleLogout = () => {   
    localStorage.removeItem("sessionTokenSpaceFinance");   
    localStorage.removeItem("privacyAccepted");  
    localStorage.removeItem("usersSpaceFinance");
    localStorage.clear();
    window.location.href = "/"; // Redirige al usuario a la página inicial
  };

  const handleOpenMyPlanPopup = () => {
    setShowMyPlanPopup(true);
    setShowMenu(false);
  };

  const handleOpenUpgradePopup = () => {
    setShowUpgradePopup(true);
    setShowMenu(false);
  };
  
  const handleUpgradeFromSettings = () => {
    setShowSettingsPopup(false);
    setShowUpgradePopup(true);
  };  

  const handleOpenSettingsPopup = () => {
    setShowSettingsPopup(true);
    setShowMenu(false);
  };

  const handleClosePopup = () => {
    setShowUpgradePopup(false);
    setShowSettingsPopup(false);
    setShowMyPlanPopup(false);
  };

  const getDisplayName = () => {
    if (userSpaceFinance.name) {
      return capitalizeFirstLetter(
        userSpaceFinance.name.split("@")[0].replace(".", " ")
      );
    } else if (userSpaceFinance.email) {
      return capitalizeFirstLetter(
        userSpaceFinance.email.split("@")[0].replace(".", " ")
      );
    }
    return userSpaceFinance.name;
  };

  return (
    <div className="user-info" onClick={toggleMenu}>
      <div className="user-info-content">
        <img src={imageSrc} alt="Profile" className="profile-image" />
        <p className="user-name">
          {getDisplayName()}
        </p>
      </div>

      {showMenu && (
        <div className="user-menu" ref={menuRef}>
          <ul>
            <li onClick={handleOpenMyPlanPopup}>
              <img src={myPlanIcon} alt="My Plan" />
              <span>My Plan</span>
            </li>
            {/*<li onClick={handleOpenUpgradePopup} className="disabled-menu-item">*/}
            <li onClick={handleOpenUpgradePopup}>
              <img src={upgradeIcon} alt="Upgrade" />
              <span>Upgrade</span>
            </li>
            <li onClick={handleOpenSettingsPopup}>
              <img src={settingsIcon} alt="Settings" />
              <span>Settings</span>
            </li>
            <li onClick={handleLogout}>
              <img src={closeSessionIcon} alt="Log out" />
              <span>Log out</span>
            </li>
          </ul>
        </div>
      )}
      {showMyPlanPopup && <MyPlanPopup onClose={handleClosePopup} userSpaceFinance={userSpaceFinance} />}
      {showUpgradePopup && <SubscriptionPopupStripe onClose={handleClosePopup} userSpaceFinance={userSpaceFinance}/>}
      {showSettingsPopup && (<SettingsPopup onClose={handleClosePopup} userSpaceFinance={userSpaceFinance} onUpgrade={handleUpgradeFromSettings} 
  />
)}      
    </div>
  );
};

export default ChatBotUserProfile;
