import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from "chart.js";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend);

const DrawdownChart = ({prices, dates}) => {
    console.log("PRICES: ", prices);
    console.log("DATES: ", dates);
  const [chartData, setChartData] = useState(null); // Inicialmente null

  useEffect(() => {
    const calculateDrawdown = (prices) => {
      let peak = prices[0];
      return prices.map((price) => {
        if (price > peak) peak = price;
        return ((price - peak) / peak) * 100;
      });
    };

    // // Ejemplo de datos (cámbialo según tus necesidades)
    // const prices = [100, 110, 105, 120, 115, 130, 125, 140, 135, 150];
    // const dates = [
    //   "2023-01-01", "2023-02-01", "2023-03-01", "2023-04-01",
    //   "2023-05-01", "2023-06-01", "2023-07-01", "2023-08-01",
    //   "2023-09-01", "2023-10-01"
    // ];

    const drawdowns = calculateDrawdown(prices);

    // Solo actualizar si hay datos válidos
    if (prices.length && dates.length && drawdowns.length) {
      setChartData({
        labels: dates,
        datasets: [
          {
            label: "Drawdown (%)",
            data: drawdowns,
            borderColor: "#FF5733",
            backgroundColor: "rgba(255, 87, 51, 0.2)",
            fill: true,
            tension: 0.3,
          },
        ],
      });
    }
  }, []);

  // Renderizar solo si chartData no es null
  return (
    <div>
      {chartData ? (
        <Line
          data={chartData}
          options={{
            responsive: true,
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Date",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Drawdown (%)",
                },
                ticks: {
                  callback: function (value) {
                    return value + "%"; // Agrega el símbolo de porcentaje
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "top",
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return context.parsed.y.toFixed(2) + "%";
                  },
                },
              },
            },
          }}
        />
      ) : (
        <p>Loading chart...</p> // Muestra un mensaje de carga mientras se prepara la data
      )}
    </div>
  );
};

export default DrawdownChart;
