import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Check } from "lucide-react";
import { ApiUrlStripe } from "../services/restAPI"; // Asegúrate de que este sea el camino correcto a tu ApiUrl
import "./SubscriptionPopup.css";
import RingLoader from "react-spinners/RingLoader";

const PricingTier = ({
  name,
  description,
  price,
  features,
  isHighlighted,
  moneyType,
  billingPeriod,
  onGetStarted,
}) => (
  <div className={`plan-card ${isHighlighted ? "highlighted" : ""}`}>
    <h2 className="plan-title">{name}</h2>
    <p className="plan-description">{description}</p>
    <p className="plan-price">
      {moneyType} {price}
      <span className="plan-duration">/{billingPeriod}</span>
    </p>
    <button
      className={`plan-button ${isHighlighted ? "highlighted" : ""}`}
      onClick={onGetStarted}
    >
      Get Started
    </button>
    <ul className="plan-features">
      {features.map((feature, index) => (
        <li key={index} className="plan-feature">
          <Check className="feature-icon" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

const SubscriptionPopup = ({ onClose, onGetStarted }) => {
  const [pricingData, setPricingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState("");
  const popupRef = useRef();

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get(
          `${ApiUrlStripe}/BillingCustomer/SuscriptionProductsBilling`
        );
        const { item1: plans } = response.data;

        const data = plans.map((plan) => ({
          name: plan.name,
          description: plan.description,
          price: plan.price,
          moneyType: plan.moneyType,
          billingPeriod: plan.billingPeriod,
          features: plan.features,
          isHighlighted: plan.iD_Suscription === 3, // Resalta el plan PREMIUM como ejemplo
          url: plan.url,
        }));

        setPricingData(data);
      } catch (error) {
        setLoadError("Failed to load pricing data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPricingData();
  }, []);

  const handleCloseAndReload = () => {
    onClose();
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className="subscription-popup-overlay"
      onClick={(e) => e.stopPropagation()}
    >
      {isLoading ? (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isLoading} size={150} />
        </div>
      ) : loadError ? (
        <p>{loadError}</p>
      ) : (
        <>
          <div className="subscription-popup" ref={popupRef}>
            <div className="popup-content">
              <div className="popup-title">
                <h3>Subscriptions tailored to your needs.</h3>
                <button
                  onClick={handleCloseAndReload}
                  className="popup-close-btn"
                >
                  X
                </button>
              </div>
              <div className="plans-container">
                {pricingData.map((tier, index) => (
                  <PricingTier
                    key={index}
                    {...tier}
                    onGetStarted={onGetStarted}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionPopup;
