// import React from "react";
// import { Line } from "react-chartjs-2";
// import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend, Filler } from "chart.js";
// // import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

// ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend, Filler);

// const AreaChartLinear = () => {
//   // Datos de ejemplo
//   const data = {
//     labels: ["January", "February", "March", "April", "May", "June", "July"],
//     datasets: [
//       {
//         label: "Dataset 1",
//         data: [50, 60, 70, 80, 60, 90, 100],
//         borderColor: "rgba(75, 192, 192, 1)",
//         backgroundColor: "rgba(75, 192, 192, 0.2)",
//         fill: true, // Rellenar el área debajo de la línea
//         tension: 0.4, // Curvatura de la línea
//       },
//       {
//         label: "Dataset 2",
//         data: [30, 50, 40, 60, 40, 70, 90],
//         borderColor: "rgba(255, 99, 132, 1)",
//         backgroundColor: "rgba(255, 99, 132, 0.2)",
//         fill: true,
//         tension: 0.4,
//       },
//     ],
//   };

//   // Opciones de configuración del gráfico
//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
//         },
//       },
//     },
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: "Months",
//         },
//       },
//       y: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: "Values",
//         },
//       },
//     },
//   };

//   return (
//     <Line data={data} options={options} />
//     // <Card>
//     //   <CardHeader>
//     //     <CardTitle>Area Chart - Linear</CardTitle>
//     //   </CardHeader>
//     //   <CardContent>
//     //     <Line data={data} options={options} />
//     //   </CardContent>
//     // </Card>
//   );
// };

// export default AreaChartLinear;




import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend, Filler } from "chart.js";
// import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend, Filler);

const AreaChartLinear = ({ prices, dates }) => {
  // Datos de ejemplo
  const data = {
    labels: dates,//["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        data: prices, //[50, 60, 70, 80, 60, 90, 100],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true, // Rellenar el área debajo de la línea
        tension: 0.4, // Curvatura de la línea
        pointRadius: 1, // Ocultar los puntos
        pointHoverRadius: 5, // Mostrar los puntos al hacer hover
        pointBackgroundColor: 'rgba(75, 192, 192, 1)', // Color de fondo del punto
        pointBorderColor: 'rgba(75, 192, 192, 1)', // Color del borde del punto
      },
      // {
      //   label: "Dataset 2",
      //   data: [30, 50, 40, 60, 40, 70, 90],
      //   borderColor: "rgba(255, 99, 132, 1)",
      //   backgroundColor: "rgba(255, 99, 132, 0.2)",
      //   fill: true,
      //   tension: 0.4,
      // },
    ],
  };

  // Opciones de configuración del gráfico
  const options = {
    // responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      // legend: {
      //   position: "top",
      // },
      // tooltip: {
      //   callbacks: {
      //     label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
      //   },
      // },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            return `${tooltipItems[0].raw}`;
          },
          label: function(tooltipItem) {
            return `${tooltipItem.label}`;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo del tooltip
        titleFont: {
          family: "'Arial', sans-serif",
          size: 14,
          weight: 'bold',
          color: '#fff',
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 12,
          color: '#fff',
        },
        displayColors: false, // Oculta la barra de color del dataset en el tooltip
      },
      legend: {
        display: false, // Muestra la leyenda
        labels: {
          font: {
            family: "'Arial', sans-serif",
            size: 20,
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          // text: "Months",
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
      y: {
        // beginAtZero: true,
        title: {
          display: true,
          // text: "Values",
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
          callback: function(value) {
            return `${value}`; // Añade el símbolo de dólar a los valores del eje Y
          },
        },
      },
    },
    interaction: {
      mode: 'nearest', // Detección del punto más cercano
      axis: 'x', // Sólo activa la interacción en el eje X
      intersect: false, // Permite que el tooltip se active cuando está cerca del punto
    },
    hover: {
      mode: 'nearest', // Detección del punto más cercano al mouse
      intersect: false, // Permite que el punto se muestre cuando el mouse está cerca
    },
  };

  return (
    <Line data={data} options={options} />
  );
};

export default AreaChartLinear;
