import React from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const HorizontalBarChart = ({barData}) => {
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'], // Etiquetas para las barras
    labels: barData.map((obj) => (obj.name)), // Etiquetas para las barras
    datasets: [
      {
        label: 'Votes',
        // data: [12, 19, 3, 5, 2, 3], // Datos para las barras
        data: barData.map((obj) => (obj.value)), // Datos para las barras
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y', // Cambia a 'y' para barras horizontales
    scales: {
      x: {
        beginAtZero: true,
        // title: {
        //   display: true,
        //   text: 'Number of Votes',
        //   font: {
        //     family: "'Arial', sans-serif",
        //     size: 14,
        //     weight: 'bold',
        //   },
        // },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
      y: {
        // title: {
        //   display: true,
        //   text: 'Colors',
        //   font: {
        //     family: "'Arial', sans-serif",
        //     size: 14,
        //     weight: 'bold',
        //   },
        // },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            // return `Dataset: ${tooltipItems[0].dataset.label}`;
            return `${data.labels[tooltipItems[0].dataIndex]}`;
          },
          label: function(tooltipItem) {
            return `Value: ${tooltipItem.raw}`;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo del tooltip
        titleFont: {
          family: "'Arial', sans-serif",
          size: 14,
          weight: 'bold',
          color: '#fff',
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 12,
          color: '#fff',
        },
        displayColors: false, // Oculta la barra de color del dataset en el tooltip
      },
      legend: {
        display: true, // Muestra la leyenda
        labels: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
      legend: {
        display: false, // Oculta la leyenda de los labels de los datasets
      },
    },
  };

  return (
    <Bar data={data} options={options} />
  );
};

export default HorizontalBarChart;
