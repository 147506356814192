import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { colors } from "../utils/ColorPalette";
import { electionsData } from "../utils/PresidentialElections";
import "./PresidentialTable.css";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend, annotationPlugin);

// const colors = [
//   "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
//   "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
//   "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
// ];

// const electionsData = [
//   { Anio: 2020, Partido: "Demócrata", Presidente: "Joe Biden", FedChair: "Jerome Powell" },
//   { Anio: 2016, Partido: "Republicano", Presidente: "Donald Trump", FedChair: "Janet Yellen" },
//   { Anio: 2012, Partido: "Demócrata", Presidente: "Barack Obama", FedChair: "Ben Bernanke" },
//   { Anio: 2008, Partido: "Demócrata", Presidente: "George W. Bush", FedChair: "Ben Bernanke" },
//   { Anio: 2004, Partido: "Republicano", Presidente: "George W. Bush", FedChair: "Alan Greenspan" },
//   { Anio: 2000, Partido: "Republicano", Presidente: "Bill Clinton", FedChair: "Alan Greenspan" },
// ];

const generarFechas = () => {
  const fechas = [];

  // Fechas inicial y final: del 1 de noviembre del primer año al 30 de noviembre del tercer año
  const startDate = new Date(2000, 10, 1); // 1 de noviembre del primer año
  const endDate = new Date(2002, 10, 30);  // 30 de noviembre del tercer año

  let currentDate = startDate;

  while (currentDate <= endDate) {
    // Calcular el numeral del año (1, 2 o 3)
    const year = currentDate.getFullYear() - 1999; // 1 para 2000, 2 para 2001, 3 para 2002

    // Formatear la fecha como "year-MM-dd"
    const formattedDate = `${year}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
    fechas.push(formattedDate);

    // Incrementar la fecha en un día
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return fechas;
}

// const formatDateWithoutYear = (dateString) => {
//   const date = new Date(dateString);
//   return `${date.getMonth() + 1}-${date.getDate()}`;
// };
const formatDateWithoutYear = (dateString, yearNumberConsecutive) => {
  const date = new Date(dateString);
  return `${yearNumberConsecutive}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  // return `${yearNumberConsecutive}-${date.getMonth() + 1}-${date.getDate()}`;
};

const getUniqueYears = (data) => {
  const years = new Set();
  Object.values(data).forEach((company) => {
    Object.keys(company.election_interval_to_plot).forEach((date) => {
      years.add(date.split("-")[0]);
    });
  });
  return [...years];
};

const getCompanies = (data) => Object.keys(data);

const LineChartMultiple = ({ dataExample }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [chartData, setChartData] = useState({});
  let commonDates = [];//new Set(); // Para alinear las fechas

  const selectedYearsOrParty = selectedYears.length > 0 
    ? selectedYears 
    : electionsData.filter(e => e.Partido === selectedParty).map(e => e.Anio.toString());

  useEffect(() => {
    const generateChartData = () => {
      const datasets = []; 
      let colorIndex = 0;


      commonDates = generarFechas();
      // selectedCompanies.forEach((company) => {
      //   selectedYearsOrParty.forEach((year) => {
      //     const intervals = dataExample[company].election_interval_to_plot;
      //     console.log("intervals: ", intervals)
          
      //     let currentYear = null; // Variable para rastrear el año actual
      //     let consecutiveYear = 0; // Variable para el número consecutivo de año

      //     Object.keys(intervals).forEach((intervalDate) => {
      //       if (intervalDate.startsWith(year)) {
      //         console.log("YEARRRR222: ", year)
      //         intervals[intervalDate].forEach((item) => {
      //           console.log("item: ", item)
                
      //           const itemYear = new Date(item.Date).getFullYear(); // Obtener el año de la fecha

      //           // Si el año cambia, incrementamos el consecutivo
      //           if (itemYear !== currentYear) {
      //             consecutiveYear += 1; // Incrementar el número consecutivo
      //             currentYear = itemYear; // Actualizar el año actual
      //           }

      //           commonDates.add(formatDateWithoutYear(item.Date, consecutiveYear)); // Agregar fechas sin años
      //         });
      //       }
      //     });
      //   });
      // });

      selectedCompanies.forEach((company) => {
        selectedYearsOrParty.forEach((year) => {
          const intervals = dataExample[company].election_interval_to_plot;
          
          // let currentYear = null; // Variable para rastrear el año actual
          // let consecutiveYear = 0; // Variable para el número consecutivo de año

          Object.keys(intervals).forEach((intervalDate) => {
            if (intervalDate.startsWith(year)) {
              const dataPoints = Array.from(commonDates).map((commonDate) => {


                // const itemYear = new Date(commonDate.Date).getFullYear(); // Obtener el año de la fecha

                // // Si el año cambia, incrementamos el consecutivo
                // if (itemYear !== currentYear) {
                //   consecutiveYear += 1; // Incrementar el número consecutivo
                //   currentYear = itemYear; // Actualizar el año actual
                // }

                let currentYear = null; // Variable para rastrear el año actual
                let consecutiveYear = 0; // Variable para el número consecutivo de año
      
                // const match = intervals[intervalDate].find((item) => formatDateWithoutYear(item.Date, consecutiveYear) == commonDate);
                const match = intervals[intervalDate].find((item) => {
                  
                  const itemYear = new Date(item.Date).getFullYear(); // Obtener el año de la fecha

                  // Si el año cambia, incrementamos el consecutivo
                  if (itemYear !== currentYear) { 
                    consecutiveYear += 1; // Incrementar el número consecutivo
                    currentYear = itemYear; // Actualizar el año actual
                  }

                  return formatDateWithoutYear(item.Date, consecutiveYear) == commonDate
                });

                return {
                  x: commonDate,
                  y: match ? match.Cumulative_Return : null,
                };
              });

              // console.log("DATA ANIOdataPoints: ", dataPoints);
              // console.log("DATA ANIO year: ", year);
              // console.log("DATA ANIO commonDates: ", commonDates);
              
              const color = colors[colorIndex % colors.length];
              colorIndex++;

              datasets.push({
                label: `${company} (${intervalDate})`,
                data: dataPoints,
                borderColor: color,
                fill: false,
                spanGaps: true,
                pointRadius: 0,
                pointBorderWidth: 0,
                pointBackgroundColor: "transparent",
              });
            }
          });
        });
      });
// console.log("DATASETS ", datasets);
      setChartData({
        datasets: datasets,
      });
    };

    generateChartData();
  }, [selectedCompanies, selectedYears, selectedParty]);

  const companiesOptions = getCompanies(dataExample).map((company) => ({
    label: company,
    value: company,
  }));

  const yearsOptions = getUniqueYears(dataExample).map((year) => ({
    label: year,
    value: year,
  }));

  const partyOptions = [
    { label: "Demócrata", value: "Demócrata" },
    { label: "Republicano", value: "Republicano" },
  ];

  const handleYearsChange = (selected) => {
    setSelectedYears(selected ? selected.map((option) => option.value) : []);
    setSelectedParty(null); // Limpiar la selección de partido si se selecciona un año
  };

  const handlePartyChange = (selected) => {
    setSelectedParty(selected ? selected.value : null);
    setSelectedYears([]); // Limpiar la selección de años si se selecciona un partido
  };

  // Modificar las anotaciones para que solo se pinten en los años electorales
  const electionAnnotations = selectedYearsOrParty
    .filter((year) => electionsData.some((eleccion) => eleccion.Anio.toString() === year))
    .map((year) => ({
      type: 'box',
      xMin: `2-11-01`,  
      xMax: `2-11-30`,
      backgroundColor: "rgba(128, 128, 128, 0.2)",
      borderColor: "rgba(128, 128, 128, 0.5)",
      label: {
        content: `Election ${year}`,
        enabled: true,
        position: 'center',
      },
    }));

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Select
          isMulti
          options={companiesOptions}
          placeholder="Select Companies"
          onChange={(selected) => setSelectedCompanies(selected.map((option) => option.value))}
          className="custom-input mb-8 w-full"
        />
        <Select
          isMulti
          options={yearsOptions}
          placeholder="Select Years"
          onChange={handleYearsChange}
          value={selectedYears.map((year) => ({ label: year, value: year }))}
          className="custom-input mb-8 w-full"
        />
        <Select
          options={partyOptions}
          placeholder="Select Party"
          onChange={handlePartyChange}
          value={selectedParty ? { label: selectedParty, value: selectedParty } : null}
          className="custom-input mb-8 w-full"
        />
      </div>

      <Line
        data={{
          datasets: chartData.datasets || [],
        }}
        options={{
          scales: {
            x: {
              type: "category",
              labels: commonDates,//Array.from(new Set([...commonDates])).sort((a, b) => new Date(`2022-${a}`) - new Date(`2022-${b}`)), // Alinear por fechas comunes
              title: {
                display: true,
                text: "Date (MM-DD)",
              },
            },
            y: {
              title: {
                display: true,
                text: "Cumulative_Return",
              },
            },
          },
          plugins: {
            annotation: {
              annotations: electionAnnotations,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const date = tooltipItem.label;
                  const value = tooltipItem.raw ? tooltipItem.raw.y : 'N/A';
                  return `Date: ${date}, Price: ${value}`;
                }
              }
            },
            datalabels: { display: false },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default LineChartMultiple;
