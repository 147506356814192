import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import Select from "react-select";
import "./IncomeStatement.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const marketCapConvert = (mount) => {
  const arrayMount = mount.split(" ");

  if (!arrayMount[0] || !arrayMount[1]) {
    return 0;
  }

  let newMount = 0;
  switch (arrayMount[1]) {
    case "T":
      newMount = parseFloat(arrayMount[0]) * 1_000_000_000_000;
      break;
    case "B":
      newMount = parseFloat(arrayMount[0]) * 1_000_000_000;
      break; 
    case "M":
      newMount = parseFloat(arrayMount[0]) * 1_000_000;
      break; 
    default:
      newMount = parseFloat(arrayMount[0]) * 1_000_000;
  }

  return newMount;
};

const getDataSets = (ticker, values) => {
  return values.map((value, index) => ({
    label: value[ticker],
    data: Object.entries(value)
      .filter(([k, v]) => k !== ticker)
      .map(([k, v]) => marketCapConvert(v)),
    dataOrigin: Object.entries(value)
      .filter(([k, v]) => k !== ticker)
      .map(([k, v]) => v),
    backgroundColor: colors[index % colors.length],
    borderColor: colors[index % colors.length].replace('0.5', '1'),
    borderWidth: 1,
  }));
};

const objectOfObjects = (array) => {
  return array.reduce((acc, current, index) => {
    acc[index] = current;
    return acc;
  }, {});
};

const colors = [
  "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
  "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
  "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
];

const MultipleBarChartWithSelect = ({ ticker, texts, values, isQuarterly }) => {
  const arrayAllDatasets = getDataSets(ticker, values);
  const allDatasets = objectOfObjects(arrayAllDatasets); 
  // const [selectedDatasets, setSelectedDatasets] = useState([{ value: 0, label: allDatasets[0].label, color: colors[0] }]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  let newWidthChart = isQuarterly ? 2500 : 0; //Ancho inicial
  newWidthChart = selectedDatasets.length > 1 ? 
    newWidthChart === 0 ? 
    (((selectedDatasets.length - 1) * (arrayAllDatasets.length * 25)) + 400) 
    : (((selectedDatasets.length - 1) * (arrayAllDatasets.length * 50)) + newWidthChart) 
    : newWidthChart;

  const handleChange = (selectedOptions) => {
    setSelectedDatasets(selectedOptions);
  };

  const data = {
    labels: texts,
    datasets: selectedDatasets.map((selected) => allDatasets[selected.value]),
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
        },
        // Ajusta la separación entre las barras para que crezca el eje x conforme aumentan las barras
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      y: {
        title: {
          display: true,
          font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      datalabels: { display: false },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            return tooltipItems[0].dataset.label;
          },
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            const dataOrigin = tooltipItem.dataset.dataOrigin[index];
            return [
              `Date: ${tooltipItem.label}`,
              `Value: ${dataOrigin}`,
            ];
          },
        },
        displayColors: true,
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          font: { family: "'Arial', sans-serif", size: 12 },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const selectOptions = Object.keys(allDatasets).map((key, index) => ({
    value: key,
    label: allDatasets[key].label,
    color: colors[index % colors.length], // Asignamos un color de acuerdo al índice
  }));

  const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? data.color + "20" // Adding transparency for focus
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? 'white'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : data.color + "30" // Adding more transparency for active state
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color + "20", // Adding transparency
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
    input: (styles) => ({
      ...styles,
    }),
  };

  return (
    <>
      <div className="w-full max-w-lg mx-auto">
        <Select
          isMulti
          value={selectedDatasets}
          onChange={handleChange}
          options={selectOptions}
          classNamePrefix="react-select"
          placeholder="Select datasets..."
          styles={customStyles} // Aplicamos los estilos personalizados
          className="custom-input mb-8 w-full"
        />
      </div>
      <div style={{ overflowX: 'auto' } }>
        <div style={newWidthChart === 0 ? { height: '500px' } : { height: '500px', width: newWidthChart + 'px' } }>
          <Bar data={data} options={options} />
        </div>
      </div>
    </>
  );
};

export default MultipleBarChartWithSelect;
