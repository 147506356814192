import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import "./Overview.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// Colores para las opciones del select y las barras del gráfico
const allOptions = [
  { value: "red", label: "Red", color: "#FF6384" },
  { value: "blue", label: "Blue", color: "#36A2EB" },
  { value: "green", label: "Green", color: "#4BC0C0" },
  { value: "orange", label: "Orange", color: "#FF9F40" },
];

const colors = [
    "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
    "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
    "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
  ];
  
const numberConvert = (mount) => {
  let newMount = 0;

  let arrayMount = mount.split(" ");
  if (arrayMount[0] && arrayMount[1]) {
    switch (arrayMount[1]) {
      case "T":
        newMount = parseFloat(arrayMount[0]) * 1_000_000_000_000;
        break;
      case "B":
        newMount = parseFloat(arrayMount[0]) * 1_000_000_000;
        break; 
      case "M":
        newMount = parseFloat(arrayMount[0]) * 1_000_000;
        break; 
      default:
        newMount = parseFloat(arrayMount[0]) * 1_000_000;
    }
  
    return newMount;
  }

  arrayMount = mount.split("%");
  if (arrayMount[0] && arrayMount[1]) {
    return parseFloat(arrayMount[0]);
  }

  if (parseFloat(mount)) {
    return parseFloat(mount);
  }
  
  return 0;
};

// const getDataSets = (ticker, values) => {
//     return values.map((value, index) => ({
//       label: value[ticker],
//       data: Object.entries(value)
//         .filter(([k, v]) => k !== ticker)
//         .map(([k, v]) => marketCapConvert(v)),
//       backgroundColor: colors[index % colors.length],
//       borderColor: colors[index % colors.length].replace('0.5', '1'),
//       borderWidth: 1,
//     }));
//   };
  
const BarChartWithSelect = ({ allData }) => {
  // const [selectedOption, setSelectedOption] = useState(allOptions[0]);
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "",
    color: "",
    data: [],
    dataNumber: []
  });

  
console.log("ALL-DATTAAAAAA: ", allData);
const keysToExclude = ["Ticker", "Description", "Sector", "Industry"];
let colorIndex = 0; 
let transformedData = [];
  transformedData = Object.keys(allData[0])
  .filter(key => !keysToExclude.includes(key))
  .map((key) => {
    const values = allData.map((item) => item[key]);

    const dataObj = {
      value: key,
      label: key,
      color: colors[colorIndex],
      data: values,
      dataNumber: values.map(v => numberConvert(v))
    };
    // Increment color index and reset if it exceeds the length of the colors array
    colorIndex = (colorIndex + 1) % colors.length;

    return dataObj;
  });
  console.log("DATTAAAAAA: ", transformedData);
  // Datos del gráfico, cambiando según la opción seleccionada
  const data = {
    labels: allData.map(dt => dt.Ticker),//["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: `${selectedOption.label}`,
        data: selectedOption.dataNumber,//[65, 59, 80, 81, 56, 55, 40],
        dataOrigin: selectedOption.data,
        backgroundColor: selectedOption.color,
        borderColor: selectedOption.color,
        borderWidth: 1,
      },
    ],
  };
  console.log("COSAASAS 123333 123111: ", data);

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "",
        },
      },
      y: {
        ticks: {
          callback: function (value) {
            // return value >= 0 ? `%${value}` : value;
            if (selectedOption?.data[0]?.includes('%'))
            {
              return `${value}%`;
            }
            console.log("VALUE, ", value);
            console.log("selectedOption, ", selectedOption.data[0]);
            return `${value}`;
          },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      datalabels: { display: false },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            return '';
          },
          label: function (context) {
            let label = context.label || '';
            const index = context.dataIndex;
            const dataOrigin = context.dataset.dataOrigin[index];

            // return dataOrigin;

            return [
              `Ticker: ${label}`,
              `Value: ${dataOrigin}`,
            ];

            // let label = context.dataset.label || '';
            // if (label) {
            //   label += ': ';
            // }
            // label += context.raw !== undefined ? context.raw : '';
            // if (selectedOption?.data[0]?.includes('%')) {
            //   label += '%';  // Añade el símbolo de porcentaje si es relevante
            // }
            // return label;
          },
        },
        displayColors: false,
      },
      legend: {
        display: false,
        position: "top",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  // Personalización de estilos para react-select
  const selectStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "white",
      borderColor: "#ced4da",
      // minHeight: "40px",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? state.data.color
        : state.isFocused
        ? `${state.data.color}33` // 20% opacity on hover
        : "white",
      color: state.isSelected ? "white" : state.data.color,
    }),
    singleValue: (base) => ({
      ...base,
      color: selectedOption.color,
    }),
  };

  console.log("*********************************: ");
  return (
    <div className="p-4">
      <div className="w-full max-w-lg mx-auto mb-4">
        <Select
          // options={allOptions}
          options={transformedData}
          value={selectedOption}
          onChange={handleChange}
          styles={selectStyles}
          className="custom-input"
          placeholder="Select datasets..."
        />
      </div>
      <div style={{ width: "100%", height: "400px" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChartWithSelect;
