// // // // import React, { useState } from "react";
// // // // import { Bar } from "react-chartjs-2";
// // // // import {
// // // //   Chart as ChartJS,
// // // //   BarElement,
// // // //   CategoryScale,
// // // //   LinearScale,
// // // //   Tooltip,
// // // //   Legend,
// // // // } from "chart.js";

// // // // ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// // // // const MultipleBarChartWithSelect = () => {
// // // //   const allDatasets = {
// // // //     Dataset1: {
// // // //       label: "Dataset 1",
// // // //       data: [65, 59, 80, 81, 56, 55, 40],
// // // //       backgroundColor: "rgba(255, 99, 132, 0.5)",
// // // //       borderColor: "rgba(255, 99, 132, 1)",
// // // //       borderWidth: 1,
// // // //     },
// // // //     Dataset2: {
// // // //       label: "Dataset 2",
// // // //       data: [28, 48, 40, 19, 86, 27, 90],
// // // //       backgroundColor: "rgba(54, 162, 235, 0.5)",
// // // //       borderColor: "rgba(54, 162, 235, 1)",
// // // //       borderWidth: 1,
// // // //     },
// // // //     Dataset3: {
// // // //       label: "Dataset 3",
// // // //       data: [18, 72, 75, 54, 66, 37, 72],
// // // //       backgroundColor: "rgba(75, 192, 192, 0.5)",
// // // //       borderColor: "rgba(75, 192, 192, 1)",
// // // //       borderWidth: 1,
// // // //     },
// // // //   };

// // // //   const [selectedDatasets, setSelectedDatasets] = useState(["Dataset1", "Dataset2"]);

// // // //   const handleChange = (event) => {
// // // //     const { options } = event.target;
// // // //     const selected = [];
// // // //     for (let i = 0, l = options.length; i < l; i++) {
// // // //       if (options[i].selected) {
// // // //         selected.push(options[i].value);
// // // //       }
// // // //     }
// // // //     setSelectedDatasets(selected);
// // // //   };

// // // //   const data = {
// // // //     labels: ["January", "February", "March", "April", "May", "June", "July"],
// // // //     datasets: selectedDatasets.map((datasetKey) => allDatasets[datasetKey]),
// // // //   };

// // // //   const options = {
// // // //     scales: {
// // // //       x: {
// // // //         title: {
// // // //           display: true,
// // // //           text: "Months",
// // // //           font: {
// // // //             family: "'Arial', sans-serif",
// // // //             size: 14,
// // // //             weight: "bold",
// // // //           },
// // // //         },
// // // //       },
// // // //       y: {
// // // //         title: {
// // // //           display: true,
// // // //           text: "Values",
// // // //           font: {
// // // //             family: "'Arial', sans-serif",
// // // //             size: 14,
// // // //             weight: "bold",
// // // //           },
// // // //         },
// // // //         beginAtZero: true,
// // // //       },
// // // //     },
// // // //     plugins: {
// // // //       tooltip: {
// // // //         callbacks: {
// // // //           label: function (tooltipItem) {
// // // //             return `Value: ${tooltipItem.raw}`;
// // // //           },
// // // //         },
// // // //       },
// // // //       legend: {
// // // //         display: true,
// // // //         position: "top",
// // // //         labels: {
// // // //           font: {
// // // //             family: "'Arial', sans-serif",
// // // //             size: 12,
// // // //           },
// // // //         },
// // // //       },
// // // //     },
// // // //     responsive: true,
// // // //     maintainAspectRatio: false,
// // // //   };

// // // //   return (
// // // //     <div className="w-full max-w-lg mx-auto">
// // // //       <select
// // // //         multiple
// // // //         value={selectedDatasets}
// // // //         onChange={handleChange}
// // // //         className="mb-4 p-2 border rounded"
// // // //       >
// // // //         {Object.keys(allDatasets).map((key) => (
// // // //           <option key={key} value={key}>
// // // //             {allDatasets[key].label}
// // // //           </option>
// // // //         ))}
// // // //       </select>

// // // //       <Bar data={data} options={options} />
// // // //     </div>
// // // //   );
// // // // };

// // // // export default MultipleBarChartWithSelect;




// // // import React, { useState } from "react";
// // // import { Bar } from "react-chartjs-2";
// // // import {
// // //   Chart as ChartJS,
// // //   BarElement,
// // //   CategoryScale,
// // //   LinearScale,
// // //   Tooltip,
// // //   Legend,
// // // } from "chart.js";

// // // ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// // // const getDataSets = (ticker, values) => {
// // //   const dataSets = values.map((value) => {
// // //     return {
// // //       label: value[ticker],
// // //       data: Object.entries(value).filter(([k, v]) => k !== ticker).map(([k, v]) => v)
// // //     };
// // //   });
// // //   return dataSets;
// // // };

// // // const MultipleBarChartWithSelect = ({ ticker, optionsSelect, datasetsList, texts, values }) => {
// // //   console.log("TICKERRRR: ", ticker);
// // //   console.log("OPTIONSSS: ", optionsSelect);
// // //   console.log("OPTIONSSS123: ", values);
// // //   console.log("TEXTTTTTTTT: ", texts);
// // //   console.log("OBJECTOOO: ", getDataSets(ticker, values));
// // //   const allDatasets = {
// // //     "Dataset1": {
// // //       label: "Dataset 1",
// // //       data: [65, 59, 80, 81, 56, 55, 40],
// // //       backgroundColor: "rgba(255, 99, 132, 0.5)",
// // //       borderColor: "rgba(255, 99, 132, 1)",
// // //       borderWidth: 1,
// // //     },
// // //     Dataset2: {
// // //       label: "Dataset 2",
// // //       data: [28, 48, 40, 19, 86, 27, 90],
// // //       backgroundColor: "rgba(54, 162, 235, 0.5)",
// // //       borderColor: "rgba(54, 162, 235, 1)",
// // //       borderWidth: 1,
// // //     },
// // //     Dataset3: {
// // //       label: "Dataset 3",
// // //       data: [18, 72, 75, 54, 66, 37, 72],
// // //       backgroundColor: "rgba(75, 192, 192, 0.5)",
// // //       borderColor: "rgba(75, 192, 192, 1)",
// // //       borderWidth: 1,
// // //     },
// // //   };

// // //   const [selectedDatasets, setSelectedDatasets] = useState(["Dataset1", "Dataset2"]);

// // //   const handleChange = (event) => {
// // //     const { options } = event.target;
// // //     const selected = [];
// // //     for (let i = 0, l = options.length; i < l; i++) {
// // //       if (options[i].selected) {
// // //         selected.push(options[i].value);
// // //       }
// // //     }
// // //     setSelectedDatasets(selected);
// // //   };

// // //   const data = {
// // //     labels: ["January", "February", "March", "April", "May", "June", "July"], //texts
// // //     datasets: selectedDatasets.map((datasetKey) => allDatasets[datasetKey]),
// // //   };

// // //   const options = {
// // //     scales: {
// // //       x: {
// // //         title: {
// // //           display: true,
// // //           text: "Months",
// // //           font: {
// // //             family: "'Arial', sans-serif",
// // //             size: 14,
// // //             weight: "bold",
// // //           },
// // //         },
// // //       },
// // //       y: {
// // //         title: {
// // //           display: true,
// // //           text: "Values",
// // //           font: {
// // //             family: "'Arial', sans-serif",
// // //             size: 14,
// // //             weight: "bold",
// // //           },
// // //         },
// // //         beginAtZero: true,
// // //       },
// // //     },
// // //     plugins: {
// // //       tooltip: {
// // //         callbacks: {
// // //           label: function (tooltipItem) {
// // //             return `Value: ${tooltipItem.raw}`;
// // //           },
// // //         },
// // //       },
// // //       legend: {
// // //         display: true,
// // //         position: "top",
// // //         labels: {
// // //           font: {
// // //             family: "'Arial', sans-serif",
// // //             size: 12,
// // //           },
// // //         },
// // //       },
// // //     },
// // //     responsive: true,
// // //     maintainAspectRatio: true, // Mantiene la relación de aspecto
// // //   };

// // //   return (
// // //     <div className="w-full max-w-lg mx-auto">
// // //       <select
// // //         multiple
// // //         value={selectedDatasets}
// // //         onChange={handleChange}
// // //         className="mb-4 p-2 border rounded"
// // //       >
// // //         {Object.keys(allDatasets).map((key) => (
// // //           <option key={key} value={key}>
// // //             {allDatasets[key].label}
// // //           </option>
// // //         ))}
// // //       </select>

// // //       <div style={{ height: "400px", width: "100%" }}>
// // //         <Bar data={data} options={options} />
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default MultipleBarChartWithSelect;



// // // import React, { useState } from "react";
// // // import { Bar } from "react-chartjs-2";
// // // import {
// // //   Chart as ChartJS,
// // //   BarElement,
// // //   CategoryScale,
// // //   LinearScale,
// // //   Tooltip,
// // //   Legend,
// // // } from "chart.js";

// // // ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// // // const MultipleBarChartWithSelect = () => {
// // //   const allDatasets = {
// // //     Dataset1: {
// // //       label: "Dataset 1",
// // //       data: [65, 59, 80, 81, 56, 55, 40],
// // //       backgroundColor: "rgba(255, 99, 132, 0.5)",
// // //       borderColor: "rgba(255, 99, 132, 1)",
// // //       borderWidth: 1,
// // //     },
// // //     Dataset2: {
// // //       label: "Dataset 2",
// // //       data: [28, 48, 40, 19, 86, 27, 90],
// // //       backgroundColor: "rgba(54, 162, 235, 0.5)",
// // //       borderColor: "rgba(54, 162, 235, 1)",
// // //       borderWidth: 1,
// // //     },
// // //     Dataset3: {
// // //       label: "Dataset 3",
// // //       data: [18, 72, 75, 54, 66, 37, 72],
// // //       backgroundColor: "rgba(75, 192, 192, 0.5)",
// // //       borderColor: "rgba(75, 192, 192, 1)",
// // //       borderWidth: 1,
// // //     },
// // //   };

// // //   const [selectedDatasets, setSelectedDatasets] = useState(["Dataset1", "Dataset2"]);

// // //   const handleChange = (event) => {
// // //     const { options } = event.target;
// // //     const selected = [];
// // //     for (let i = 0, l = options.length; i < l; i++) {
// // //       if (options[i].selected) {
// // //         selected.push(options[i].value);
// // //       }
// // //     }
// // //     setSelectedDatasets(selected);
// // //   };

// // //   const data = {
// // //     labels: ["January", "February", "March", "April", "May", "June", "July"],
// // //     datasets: selectedDatasets.map((datasetKey) => allDatasets[datasetKey]),
// // //   };

// // //   const options = {
// // //     scales: {
// // //       x: {
// // //         title: {
// // //           display: true,
// // //           text: "Months",
// // //           font: {
// // //             family: "'Arial', sans-serif",
// // //             size: 14,
// // //             weight: "bold",
// // //           },
// // //         },
// // //       },
// // //       y: {
// // //         title: {
// // //           display: true,
// // //           text: "Values",
// // //           font: {
// // //             family: "'Arial', sans-serif",
// // //             size: 14,
// // //             weight: "bold",
// // //           },
// // //         },
// // //         beginAtZero: true,
// // //       },
// // //     },
// // //     plugins: {
// // //       tooltip: {
// // //         callbacks: {
// // //           label: function (tooltipItem) {
// // //             return `Value: ${tooltipItem.raw}`;
// // //           },
// // //         },
// // //       },
// // //       legend: {
// // //         display: true,
// // //         position: "top",
// // //         labels: {
// // //           font: {
// // //             family: "'Arial', sans-serif",
// // //             size: 12,
// // //           },
// // //         },
// // //       },
// // //     },
// // //     responsive: true,
// // //     maintainAspectRatio: false,
// // //   };

// // //   return (
// // //     <div className="w-full max-w-lg mx-auto">
// // //       <select
// // //         multiple
// // //         value={selectedDatasets}
// // //         onChange={handleChange}
// // //         className="mb-4 p-2 border rounded"
// // //       >
// // //         {Object.keys(allDatasets).map((key) => (
// // //           <option key={key} value={key}>
// // //             {allDatasets[key].label}
// // //           </option>
// // //         ))}
// // //       </select>

// // //       <Bar data={data} options={options} />
// // //     </div>
// // //   );
// // // };

// // // export default MultipleBarChartWithSelect;




// // import React, { useState } from "react";
// // import { Bar } from "react-chartjs-2";
// // import {
// //   Chart as ChartJS,
// //   BarElement,
// //   CategoryScale,
// //   LinearScale,
// //   Tooltip,
// //   Legend,
// // } from "chart.js";

// // ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// // const marketCapConvert = (mount) => {
// //   const arrayMount = mount.split(" ");

// //   if (!arrayMount[0] || !arrayMount[1]) {
// //     return 0;
// //   }

// //   let newMount = 0;
// //   switch (arrayMount[1]) {

// //     case "T":
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000_000_000;
// //       break;

// //     case "B":
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000_000;
// //       break; 

// //     case "M":
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000;
// //       break; 
      
// //     default:
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000;
// //   }

// //   return newMount;
// // };
// // const getDataSets = (ticker, values) => {
// //   const dataSets = values.map((value, index) => {
// //     return {
// //       label: value[ticker],
// //       data: Object.entries(value).filter(([k, v]) => k !== ticker).map(([k, v]) => marketCapConvert(v)),
// //       backgroundColor: colors[index % colors.length], // Usa el índice para asignar el color
// //       borderColor: colors[index % colors.length].replace('0.5', '1'), // Ajusta la opacidad para el borde si es necesario
// //       borderWidth: 1,
// //       // backgroundColor: "rgba(255, 99, 132, 0.5)",
// //       // borderColor: "rgba(255, 99, 132, 1)",
// //       // borderWidth: 1,
// //     };
// //   });
// //   return dataSets;
// // };
// // const objectOfObjects = (array) => {
// //   return array.reduce((acc, current, index) => {
// //     acc[index] = current;
// //     return acc;
// //   }, {});
// // }
// // const colors = [
// //   "#D97A2D",
// //   "#007ACC",
// //   "#20C9C0",
// //   "#00907C",
// //   "#9A29C9",
// //   "#08088A",
// //   "#D73688",
// //   "#D4D404",
// //   "#8B2222",
// //   "#696969",
// //   "#00CCCC",
// //   "#731EBF",
// //   "#005200",
// //   "#556B2F",
// //   "#8B0000",
// //   "#4682B4",
// //   "#2F4F4F",
// //   "#4B0082",
// // ];
// // const MultipleBarChartWithSelect = ({ ticker, optionsSelect, datasetsList, texts, values }) => {
// //   console.log("TICKERRRR: ", ticker);
// //   console.log("OPTIONSSS: ", optionsSelect);
// //   console.log("OPTIONSSS123: ", values);
// //   console.log("TEXTTTTTTTT: ", texts);
// //   const allDatasets = objectOfObjects(getDataSets(ticker, values));
// //   console.log("OBJECTOOO: ", allDatasets);
// //   // const allDatasets = {
// //   //   "Dataset1": {
// //   //     label: "Dataset 1",
// //   //     data: [65, 59, 80, 81, 56, 55, 40],
// //   //     backgroundColor: "rgba(255, 99, 132, 0.5)",
// //   //     borderColor: "rgba(255, 99, 132, 1)",
// //   //     borderWidth: 1,
// //   //   },
// //   //   Dataset2: {
// //   //     label: "Dataset 2",
// //   //     data: [28, 48, 40, 19, 86, 27, 90],
// //   //     backgroundColor: "rgba(54, 162, 235, 0.5)",
// //   //     borderColor: "rgba(54, 162, 235, 1)",
// //   //     borderWidth: 1,
// //   //   },
// //   //   Dataset3: {
// //   //     label: "Dataset 3",
// //   //     data: [18, 72, 75, 54, 66, 37, 72],
// //   //     backgroundColor: "rgba(75, 192, 192, 0.5)",
// //   //     borderColor: "rgba(75, 192, 192, 1)",
// //   //     borderWidth: 1,
// //   //   },
// //   // };

// //   const [selectedDatasets, setSelectedDatasets] = useState([0]);

// //   const handleChange = (event) => {
// //     const { options } = event.target;
// //     const selected = [];
// //     for (let i = 0, l = options.length; i < l; i++) {
// //       if (options[i].selected) {
// //         selected.push(options[i].value);
// //       }
// //     }
// //     setSelectedDatasets(selected);
// //   };

// //   const data = {
// //     labels: texts,//["January", "February", "March", "April", "May", "June", "July"], //texts
// //     datasets: selectedDatasets.map((datasetKey) => allDatasets[datasetKey]),
// //   };

// //   const options = {
// //     scales: {
// //       x: {
// //         title: {
// //           display: true,
// //           // text: "Months",
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 14,
// //             weight: "bold",
// //           },
// //         },
// //       },
// //       y: {
// //         title: {
// //           display: true,
// //           // text: "Values",
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 14,
// //             weight: "bold",
// //           },
// //         },
// //         beginAtZero: true,
// //       },
// //     },
// //     plugins: {
// //       datalabels: {
// //         display: false,
// //       },
// //       tooltip: {
// //         callbacks: {
// //           label: function (tooltipItem) {
// //             return `Value: ${tooltipItem.raw}`;
// //           },
// //         },
// //       },
// //       legend: {
// //         display: true,
// //         position: "top",
// //         labels: {
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 12,
// //           },
// //         },
// //       },
// //     },
// //     responsive: true,
// //     maintainAspectRatio: false, // Mantiene la relación de aspecto
// //   };

// //   return (
// //     <>
// //     <div className="w-full max-w-lg mx-auto">
// //       <select
// //         multiple
// //         value={selectedDatasets}
// //         onChange={handleChange}
// //         className="mb-8 p-4 border rounded w-full"
// //         style={{ height: '200px' }}
// //       >
// //         {Object.keys(allDatasets).map((key) => (
// //           <option key={key} value={key}>
// //             {allDatasets[key].label}
// //           </option>
// //         ))}
// //       </select>

// //       {/* <div style={{ height: "400px", width: "100%" }}>
// //         <Bar data={data} options={options} />
// //       </div> */}
// //     </div>
// //     <div style={{ width: '100%' }}>
// //       <div style={{ height: '500px' }}>
// //       <Bar data={data} options={options} />
// //       </div>
// //     </div>
// //     </>
// //   );
// // };

// // export default MultipleBarChartWithSelect;





// // import React, { useState } from "react";
// // import { Bar } from "react-chartjs-2";
// // import {
// //   Chart as ChartJS,
// //   BarElement,
// //   CategoryScale,
// //   LinearScale,
// //   Tooltip,
// //   Legend,
// // } from "chart.js";
// // import Select from "react-select";  // Importa react-select

// // ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// // const marketCapConvert = (mount) => {
// //   const arrayMount = mount.split(" ");

// //   if (!arrayMount[0] || !arrayMount[1]) {
// //     return 0;
// //   }

// //   let newMount = 0;
// //   switch (arrayMount[1]) {
// //     case "T":
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000_000_000;
// //       break;
// //     case "B":
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000_000;
// //       break; 
// //     case "M":
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000;
// //       break; 
// //     default:
// //       newMount = parseFloat(arrayMount[0]) * 1_000_000;
// //   }

// //   return newMount;
// // };

// // const getDataSets = (ticker, values) => {
// //   return values.map((value, index) => ({
// //     label: value[ticker],
// //     data: Object.entries(value)
// //       .filter(([k, v]) => k !== ticker)
// //       .map(([k, v]) => marketCapConvert(v)),
// //     backgroundColor: colors[index % colors.length],
// //     borderColor: colors[index % colors.length].replace('0.5', '1'),
// //     borderWidth: 1,
// //   }));
// // };

// // const objectOfObjects = (array) => {
// //   return array.reduce((acc, current, index) => {
// //     acc[index] = current;
// //     return acc;
// //   }, {});
// // };

// // const colors = [
// //   "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
// //   "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
// //   "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
// // ];

// // const MultipleBarChartWithSelect = ({ ticker, texts, values }) => {
// //   const allDatasets = objectOfObjects(getDataSets(ticker, values));
// //   const [selectedDatasets, setSelectedDatasets] = useState([{ value: 0, label: allDatasets[0].label }]);

// //   const handleChange = (selectedOptions) => {
// //     setSelectedDatasets(selectedOptions);
// //   };

// //   const data = {
// //     labels: texts,
// //     datasets: selectedDatasets.map((selected) => allDatasets[selected.value]),
// //   };

// //   const options = {
// //     scales: {
// //       x: {
// //         title: {
// //           display: true,
// //           font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
// //         },
// //       },
// //       y: {
// //         title: {
// //           display: true,
// //           font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
// //         },
// //         beginAtZero: true,
// //       },
// //     },
// //     plugins: {
// //       datalabels: { display: false },
// //       tooltip: {
// //         callbacks: {
// //           label: function (tooltipItem) {
// //             return `Value: ${tooltipItem.raw}`;
// //           },
// //         },
// //       },
// //       legend: {
// //         display: true,
// //         position: "top",
// //         labels: {
// //           font: { family: "'Arial', sans-serif", size: 12 },
// //         },
// //       },
// //     },
// //     responsive: true,
// //     maintainAspectRatio: false,
// //   };

// //   const selectOptions = Object.keys(allDatasets).map((key) => ({
// //     value: key,
// //     label: allDatasets[key].label,
// //   }));

// //   return (
// //     <>
// //       <div className="w-full max-w-lg mx-auto">
// //         <Select
// //           isMulti
// //           value={selectedDatasets}
// //           onChange={handleChange}
// //           options={selectOptions}
// //           className="mb-8"
// //           classNamePrefix="react-select"
// //           placeholder="Select datasets..."
// //         />
// //       </div>
// //       <div style={{ width: '100%' }}>
// //         <div style={{ height: '500px' }}>
// //           <Bar data={data} options={options} />
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default MultipleBarChartWithSelect;







// import React, { useState } from "react";
// import { Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import Select from "react-select";  // Importa react-select
// import "./MultipleBarChartWithSelect.css";

// ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// const marketCapConvert = (mount) => {
//   const arrayMount = mount.split(" ");

//   if (!arrayMount[0] || !arrayMount[1]) {
//     return 0;
//   }

//   let newMount = 0;
//   switch (arrayMount[1]) {
//     case "T":
//       newMount = parseFloat(arrayMount[0]) * 1_000_000_000_000;
//       break;
//     case "B":
//       newMount = parseFloat(arrayMount[0]) * 1_000_000_000;
//       break; 
//     case "M":
//       newMount = parseFloat(arrayMount[0]) * 1_000_000;
//       break; 
//     default:
//       newMount = parseFloat(arrayMount[0]) * 1_000_000;
//   }

//   return newMount;
// };

// const getDataSets = (ticker, values) => {
//   return values.map((value, index) => ({
//     label: value[ticker],
//     data: Object.entries(value)
//       .filter(([k, v]) => k !== ticker)
//       .map(([k, v]) => marketCapConvert(v)),
//     backgroundColor: colors[index % colors.length],
//     borderColor: colors[index % colors.length].replace('0.5', '1'),
//     borderWidth: 1,
//   }));
// };

// const objectOfObjects = (array) => {
//   return array.reduce((acc, current, index) => {
//     acc[index] = current;
//     return acc;
//   }, {});
// };

// const colors = [
//   "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
//   "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
//   "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
// ];

// const MultipleBarChartWithSelect = ({ ticker, texts, values }) => {
//   const allDatasets = objectOfObjects(getDataSets(ticker, values));
//   const [selectedDatasets, setSelectedDatasets] = useState([{ value: 0, label: allDatasets[0].label }]);

//   const handleChange = (selectedOptions) => {
//     setSelectedDatasets(selectedOptions);
//   };

//   const data = {
//     labels: texts,
//     datasets: selectedDatasets.map((selected) => allDatasets[selected.value]),
//   };

//   const options = {
//     scales: {
//       x: {
//         title: {
//           display: true,
//           font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
//         },
//         beginAtZero: true,
//       },
//     },
//     plugins: {
//       datalabels: { display: false },
//       tooltip: {
//         callbacks: {
//           label: function (tooltipItem) {
//             return `Value: ${tooltipItem.raw}`;
//           },
//         },
//       },
//       legend: {
//         display: true,
//         position: "top",
//         labels: {
//           font: { family: "'Arial', sans-serif", size: 12 },
//         },
//       },
//     },
//     responsive: true,
//     maintainAspectRatio: false,
//   };

//   const selectOptions = Object.keys(allDatasets).map((key) => ({
//     value: key,
//     label: allDatasets[key].label
//   }));

//   return (
//     <>
//       <div className="w-full max-w-lg mx-auto">
//         <Select
//           isMulti
//           value={selectedDatasets}
//           onChange={handleChange}
//           options={selectOptions}
//           className="custom-input mb-8"
//           classNamePrefix="react-select"
//           placeholder="Select datasets..."
//         />
//       </div>
//       <div style={{ width: '100%' }}>
//         <div style={{ height: '500px' }}>
//           <Bar data={data} options={options} />
//         </div>
//       </div>
//     </>
//   );
// };

// export default MultipleBarChartWithSelect;





import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import Select from "react-select";
import "./MultipleBarChartWithSelect.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const marketCapConvert = (mount) => {
  const arrayMount = mount.split(" ");

  if (!arrayMount[0] || !arrayMount[1]) {
    return 0;
  }

  let newMount = 0;
  switch (arrayMount[1]) {
    case "T":
      newMount = parseFloat(arrayMount[0]) * 1_000_000_000_000;
      break;
    case "B":
      newMount = parseFloat(arrayMount[0]) * 1_000_000_000;
      break; 
    case "M":
      newMount = parseFloat(arrayMount[0]) * 1_000_000;
      break; 
    default:
      newMount = parseFloat(arrayMount[0]) * 1_000_000;
  }

  return newMount;
};

const getDataSets = (ticker, values) => {
  return values.map((value, index) => ({
    label: value[ticker],
    data: Object.entries(value)
      .filter(([k, v]) => k !== ticker)
      .map(([k, v]) => marketCapConvert(v)),
    dataOrigin: Object.entries(value)
      .filter(([k, v]) => k !== ticker)
      .map(([k, v]) => v),
    backgroundColor: colors[index % colors.length],
    borderColor: colors[index % colors.length].replace('0.5', '1'),
    borderWidth: 1,
  }));
};

const objectOfObjects = (array) => {
  return array.reduce((acc, current, index) => {
    acc[index] = current;
    return acc;
  }, {});
};

const colors = [
  "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
  "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
  "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
];

const MultipleBarChartWithSelect = ({ ticker, texts, values, isQuarterly }) => {
  const arrayAllDatasets = getDataSets(ticker, values);
  const allDatasets = objectOfObjects(arrayAllDatasets); 
  // const [selectedDatasets, setSelectedDatasets] = useState([{ value: 0, label: allDatasets[0].label, color: colors[0] }]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  let newWidthChart = isQuarterly ? 2500 : 0; //Ancho inicial
  newWidthChart = selectedDatasets.length > 1 ? 
    newWidthChart === 0 ? 
    (((selectedDatasets.length - 1) * (arrayAllDatasets.length * 25)) + 400) 
    : (((selectedDatasets.length - 1) * (arrayAllDatasets.length * 50)) + newWidthChart) 
    : newWidthChart;

  const handleChange = (selectedOptions) => {
    setSelectedDatasets(selectedOptions);
  };

  const data = {
    labels: texts,
    datasets: selectedDatasets.map((selected) => allDatasets[selected.value]),
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
        },
      },
      y: {
        title: {
          display: true,
          font: { family: "'Arial', sans-serif", size: 14, weight: "bold" },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      datalabels: { display: false },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            return tooltipItems[0].dataset.label;
          },
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            const dataOrigin = tooltipItem.dataset.dataOrigin[index];
            return [
              `Date: ${tooltipItem.label}`,
              `Value: ${dataOrigin}`,
            ];
          },
        },
        displayColors: true,
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          font: { family: "'Arial', sans-serif", size: 12 },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const selectOptions = Object.keys(allDatasets).map((key, index) => ({
    value: key,
    label: allDatasets[key].label,
    color: colors[index % colors.length], // Asignamos un color de acuerdo al índice
  }));

  // const customStyles = {
  //   multiValue: (styles, { data }) => ({
  //     ...styles,
  //     backgroundColor: data.color, // Color de fondo para los tabs
  //   }),
  //   multiValueLabel: (styles, { data }) => ({
  //     ...styles,
  //     color: "white", // Color del texto en los tabs
  //   }),
  //   multiValueRemove: (styles, { data }) => ({
  //     ...styles,
  //     color: "white", // Color de la "x" de eliminar
  //     ':hover': {
  //       backgroundColor: data.color,
  //       color: 'white',
  //     },
  //   }),
  // };

  const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? data.color + "20" // Adding transparency for focus
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? 'white'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : data.color + "30" // Adding more transparency for active state
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color + "20", // Adding transparency
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
    input: (styles) => ({
      ...styles,
      // color: 'black', // Set color of the input text
      // background: '0px center !important',
      // border: '0px !important',
      // padding: '0px !important',
    }),
  };

  return (
    <>
      <div className="w-full max-w-lg mx-auto">
        <Select
          isMulti
          value={selectedDatasets}
          onChange={handleChange}
          options={selectOptions}
          classNamePrefix="react-select"
          placeholder="Select datasets..."
          styles={customStyles} // Aplicamos los estilos personalizados
          className="custom-input mb-8 w-full"
        />
      </div>
      <div style={{ overflowX: 'auto' } }>
        <div style={newWidthChart === 0 ? { height: '500px' } : { height: '500px', width: newWidthChart + 'px' } }>
          <Bar data={data} options={options} />
        </div>
      </div>
    </>
  );
};

export default MultipleBarChartWithSelect;
