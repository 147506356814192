import React, { useEffect, useRef } from "react";
import "./CanvasAnimation.css";

const CanvasAnimation = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const displayWidth = canvas.width;
    const displayHeight = canvas.height;

    // Lógica de la animación aquí
    const TWO_PI = 2 * Math.PI;
    let drawCount = 0;
    let timer;
    let circles = [];

    const init = () => {
      const numCircles = 1;
      const maxMaxRad = 200;
      const minMaxRad = 200;
      const minRadFactor = 0;
      const iterations = 10;
      const drawsPerFrame = 8;
      const lineWidth = 1.01;

      setCircles();
      startGenerate();

      function startGenerate() {
        drawCount = 0;
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, displayWidth, displayHeight);

        setCircles();

        if (timer) {
          clearInterval(timer);
        }
        timer = setInterval(onTimer, 1000 / 50);
      }

      function setCircles() {
        circles = [];
        for (let i = 0; i < numCircles; i++) {
          const maxR = minMaxRad + Math.random() * (maxMaxRad - minMaxRad);
          const minR = minRadFactor * maxR;
          const grad = context.createRadialGradient(0, 0, minR, 0, 0, maxR);
          grad.addColorStop(1, "rgba(0,170,200,0.2)");
          grad.addColorStop(0, "rgba(0,20,170,0.2)");

          const newCircle = {
            centerX: -maxR,
            centerY: displayHeight / 2 - 50,
            maxRad: maxR,
            minRad: minR,
            color: grad,
            param: 0,
            changeSpeed: 1 / 250,
            phase: Math.random() * TWO_PI,
            globalPhase: Math.random() * TWO_PI,
          };
          circles.push(newCircle);
          newCircle.pointList1 = setLinePoints(iterations);
          newCircle.pointList2 = setLinePoints(iterations);
        }
      }

      function onTimer() {
        const xSqueeze = 0.75;
        const yOffset = 40 * Math.sin(drawCount / 1000 * TWO_PI);

        for (let j = 0; j < drawsPerFrame; j++) {
          drawCount++;

          for (let i = 0; i < circles.length; i++) {
            const c = circles[i];
            c.param += c.changeSpeed;
            if (c.param >= 1) {
              c.param = 0;
              c.pointList1 = c.pointList2;
              c.pointList2 = setLinePoints(iterations);
            }

            const cosParam = 0.5 - 0.5 * Math.cos(Math.PI * c.param);
            context.strokeStyle = c.color;
            context.lineWidth = lineWidth;
            context.beginPath();

            let point1 = c.pointList1.first;
            let point2 = c.pointList2.first;
            let theta = c.phase;
            let rad =
              c.minRad +
              (point1.y + cosParam * (point2.y - point1.y)) *
                (c.maxRad - c.minRad);

            c.centerX += 0.5;
            c.centerY += 0.04;

            if (c.centerX > displayWidth + maxMaxRad) {
              clearInterval(timer);
              timer = null;
            }

            context.setTransform(
              xSqueeze,
              0,
              0,
              1,
              c.centerX,
              c.centerY + yOffset
            );

            let x0 = xSqueeze * rad * Math.cos(theta);
            let y0 = rad * Math.sin(theta);
            context.lineTo(x0, y0);

            while (point1.next != null) {
              point1 = point1.next;
              point2 = point2.next;
              theta =
                TWO_PI * (point1.x + cosParam * (point2.x - point1.x)) +
                c.phase;
              rad =
                c.minRad +
                (point1.y + cosParam * (point2.y - point1.y)) *
                  (c.maxRad - c.minRad);
              x0 = xSqueeze * rad * Math.cos(theta);
              y0 = rad * Math.sin(theta);
              context.lineTo(x0, y0);
            }
            context.closePath();
            context.stroke();
          }
        }
      }

      function setLinePoints(iterations) {
        let pointList = {};
        pointList.first = { x: 0, y: 1 };
        let lastPoint = { x: 1, y: 1 };
        let minY = 1;
        let maxY = 1;
        pointList.first.next = lastPoint;

        for (let i = 0; i < iterations; i++) {
          let point = pointList.first;
          while (point.next != null) {
            let nextPoint = point.next;
            let dx = nextPoint.x - point.x;
            let newX = 0.5 * (point.x + nextPoint.x);
            let newY = 0.5 * (point.y + nextPoint.y);
            newY += dx * (Math.random() * 2 - 1);

            let newPoint = { x: newX, y: newY };
            if (newY < minY) {
              minY = newY;
            } else if (newY > maxY) {
              maxY = newY;
            }

            newPoint.next = nextPoint;
            point.next = newPoint;
            point = nextPoint;
          }
        }

        if (maxY != minY) {
          let normalizeRate = 1 / (maxY - minY);
          let point = pointList.first;
          while (point != null) {
            point.y = normalizeRate * (point.y - minY);
            point = point.next;
          }
        } else {
          let point = pointList.first;
          while (point != null) {
            point.y = 1;
            point = point.next;
          }
        }

        return pointList;
      }
    };

    init();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  return <canvas className="animated-background" id="displayCanvas" ref={canvasRef} width="1920" height="1000"></canvas>;
};

export default CanvasAnimation;
