import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Check } from "lucide-react";
import { ApiUrlStripe } from "../services/restAPI"; // Asegúrate de que este sea el camino correcto a tu ApiUrl
import RingLoader from "react-spinners/RingLoader";
import { loadStripe } from "@stripe/stripe-js";  // Importa el SDK de Stripe
import "./SubscriptionPopupStripe.css";

const PricingTier = ({
  name,
  description,
  price,
  features,
  isHighlighted,
  priceId, // Cambié 'url' por 'priceId' para identificar el producto
  moneyType,
  billingPeriod,
  email,
  stripe_Key,  
  productId, 
}) => {
  const stripePromise = loadStripe(stripe_Key); // Coloca aquí tu clave pública de Stripe

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    //console.log("price: " + price + " priceId: "+ priceId + " stripe_Key: " +stripe_Key,)

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],  // Utiliza el ID del precio/producto en Stripe
      mode: 'subscription',
      successUrl: `${window.location.origin}/?success=true`,
      cancelUrl: `${window.location.origin}`,
      customerEmail: email, // Asegura que el correo electrónico esté bloqueado
    });

    if (error) {
      console.error("Stripe checkout error:", error);
    }
  };

  return (
    <div className={`plan-stripe-card ${isHighlighted ? "highlighted" : ""}`}>
      <h2 className="plan-stripe-title">{name}</h2>
      <p className="plan-stripe-description">{description}</p>
      <p className="plan-stripe-price">
        {moneyType} {price}
        <span className="plan-stripe-duration">/{billingPeriod}</span>
      </p>
      {price > 0 && name !== "FREE" && (
        <button
          className={`plan-stripe-button ${isHighlighted ? "highlighted" : ""}`}
          onClick={handleCheckout}
        >
          Subscribe
        </button>
      )}
      <ul className="plan-stripe-features">
        {features.map((feature, index) => (
          <li key={index} className="plan-stripe-feature">
            <Check className="feature-stripe-icon" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <p className="plan-stripe-note">Individual Use</p>
    </div>
  );
};

const SubscriptionPopupStripe = ({ onClose, userSpaceFinance }) => {
  const [pricingData, setPricingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState("");
  const popupRef = useRef();
  const userEmail = userSpaceFinance.email || userSpaceFinance.name; //"rodrigo.vega@spacefinance.ai"; // Aquí deberías obtener el correo del usuario logueado

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get(
          `${ApiUrlStripe}/BillingCustomer/SuscriptionProductsBilling`
        );
        const { item1: plans } = response.data;

        const data = plans.map((plan) => ({
          name: plan.name,
          description: plan.description,
          price: plan.price,
          moneyType: plan.moneyType,
          billingPeriod: plan.billingPeriod,
          features: plan.features,
          isHighlighted: plan.iD_Suscription === 3, // Resalta el plan PREMIUM como ejemplo
          priceId: plan.price_Id, // Este es el ID del producto/precio en Stripe
          productId: plan.product_Id, // Este es el ID del producto/precio en Stripe
          email: userEmail,
          stripe_Key: plan.stripe_Key // Pasar el correo electrónico al componente
        }));
        //console.error("data rodrigo: ", data);
        setPricingData(data);
      } catch (error) {
        setLoadError("Failed to load pricing data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPricingData();
  }, []);

  const handleCloseAndReload = () => {
    onClose();
    // window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className="subscription-stripe-popup-overlay"
      onClick={(e) => e.stopPropagation()}
    >
      {isLoading ? (
        <div className="chatbot-spinner">
          <RingLoader color={"#133588"} loading={isLoading} size={150} />
        </div>
      ) : loadError ? (
        <p>{loadError}</p>
      ) : (
        <>
          <div className="subscription-stripe-popup" ref={popupRef}>
            <div className="popup-stripe-content">
              <div className="popup-stripe-title">
                <h2>Subscription Plans</h2>
                <button
                  onClick={handleCloseAndReload}
                  className="popup-stripe-close-btn"
                >
                  X
                </button>
              </div>
              <div className="plans-stripe-container">
                {pricingData.map((tier, index) => (
                  <PricingTier key={index} {...tier} />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionPopupStripe;
