// // import React from "react";
// // import { Line } from "react-chartjs-2";
// // import {
// //   Chart as ChartJS,
// //   LineElement,
// //   PointElement,
// //   CategoryScale,
// //   LinearScale,
// //   Tooltip,
// //   Legend,
// // } from "chart.js";

// // ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

// // const LineChartLinear = () => {
// //   const data = {
// //     labels: ["January", "February", "March", "April", "May", "June", "July"],
// //     datasets: [
// //       {
// //         label: "Dataset 1",
// //         data: [65, 59, 80, 81, 56, 55, 40],
// //         borderColor: function(context) {
// //           const index = context.dataIndex;
// //           const value = context.dataset.data[index];
// //           // Cambia el color según el valor
// //           return value > 75 ? "rgba(255, 99, 132, 1)" : "rgba(54, 162, 235, 1)";
// //         },
// //         borderWidth: 2,
// //         fill: false,
// //         pointRadius: 5,
// //         pointBackgroundColor: function(context) {
// //           const index = context.dataIndex;
// //           const value = context.dataset.data[index];
// //           // Cambia el color del punto según el valor
// //           return value > 75 ? "rgba(255, 99, 132, 1)" : "rgba(54, 162, 235, 1)";
// //         },
// //         pointBorderColor: "#fff",
// //         pointBorderWidth: 2,
// //       },
// //     ],
// //   };

// //   const options = {
// //     scales: {
// //       x: {
// //         title: {
// //           display: true,
// //           text: "Months",
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 14,
// //             weight: "bold",
// //           },
// //         },
// //         ticks: {
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 12,
// //           },
// //         },
// //       },
// //       y: {
// //         title: {
// //           display: true,
// //           text: "Values",
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 14,
// //             weight: "bold",
// //           },
// //         },
// //         ticks: {
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 12,
// //           },
// //           beginAtZero: true,
// //         },
// //       },
// //     },
// //     plugins: {
// //       legend: {
// //         display: true,
// //         position: "top",
// //         labels: {
// //           font: {
// //             family: "'Arial', sans-serif",
// //             size: 12,
// //           },
// //         },
// //       },
// //       tooltip: {
// //         callbacks: {
// //           label: (tooltipItem) => {
// //             const value = tooltipItem.raw;
// //             return `Value: ${value}`;
// //           },
// //         },
// //       },
// //     },
// //     responsive: true,
// //     maintainAspectRatio: true,
// //   };

// //   return (
// //     <div className="w-full max-w-lg mx-auto">
// //       <Line data={data} options={options} />
// //     </div>
// //   );
// // };

// // export default LineChartLinear;



// import React from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   CategoryScale,
//   LinearScale,
//   Tooltip,
//   Legend,
// } from "chart.js";

// ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

// const LineChartLinear = () => {
//   const data = {
//     labels: ["January", "February", "March", "April", "May", "June", "July"],
//     datasets: [
//       {
//         label: "Dataset 1",
//         data: [65, 59, 80, 81, 56, 55, 40],
//         borderColor: (context) => {
//           const index = context.p0DataIndex; // Índice del punto de inicio
//           const value = context.dataset.data[index];
//           // Cambia el color según el valor
//           return value > 75 ? "rgba(255, 99, 132, 1)" : "rgba(54, 162, 235, 1)";
//         },
//         borderWidth: 2,
//         segment: {
//           borderColor: (context) => {
//             const index = context.p0DataIndex; // Índice del punto de inicio del segmento
//             const value = context.dataset.data[index];
//             // Cambia el color del segmento según el valor del punto inicial
//             return value > 75 ? "rgba(255, 99, 132, 1)" : "rgba(54, 162, 235, 1)";
//           },
//         },
//         fill: false,
//         pointRadius: 5,
//         pointBackgroundColor: "#fff",
//         pointBorderColor: "#000",
//         pointBorderWidth: 2,
//       },
//     ],
//   };

//   const options = {
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: "Months",
//           font: {
//             family: "'Arial', sans-serif",
//             size: 14,
//             weight: "bold",
//           },
//         },
//         ticks: {
//           font: {
//             family: "'Arial', sans-serif",
//             size: 12,
//           },
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: "Values",
//           font: {
//             family: "'Arial', sans-serif",
//             size: 14,
//             weight: "bold",
//           },
//         },
//         ticks: {
//           font: {
//             family: "'Arial', sans-serif",
//             size: 12,
//           },
//           beginAtZero: true,
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         display: true,
//         position: "top",
//         labels: {
//           font: {
//             family: "'Arial', sans-serif",
//             size: 12,
//           },
//         },
//       },
//       tooltip: {
//         callbacks: {
//           label: (tooltipItem) => {
//             const value = tooltipItem.raw;
//             return `Value: ${value}`;
//           },
//         },
//       },
//     },
//     responsive: true,
//     maintainAspectRatio: false,
//   };

//   return (
//     <div className="w-full max-w-lg mx-auto">
//       <Line data={data} options={options} />
//     </div>
//   );
// };

// export default LineChartLinear;


import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

const esFechaEnRango = (fechaX, rangos) => {
  
  return rangos.some(rango => {
    return fechaX >= rango.ini && fechaX <= rango.end;
  });
};

const LineChartLinear = ({ticker, texts, values, above, abovePercents, below, belowPercents}) => {
  console.log("-----------------LineChartLinear------------------");
  
  const obtenerPercent = (targetDate, dateRanges, percents) => {
    const index = dateRanges.findIndex(range => 
      targetDate >= range.ini && targetDate <= range.end
    );

    return (percents[index] * 100).toFixed(2) + " %";
  }
  // console.log("above: ", above)
  // console.log("below: ", below)
  console.log("values: "+ticker, values)
  const data = {
    labels: texts,
    // labels: [
    //     "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "Dicember",
    //     "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "Dicember",
    //     "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "Dicember",
    //     "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "Dicember",
    //     "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "Dicember"
    // ],
    datasets: [
      {
        label: ticker,//"Dataset 1",
        data: values,
        // data: [
        //     65, 59, 80, 81, 56, 55, 40, 12, 45, 67, 10, 55,
        //     65, 59, 80, 81, 56, 55, 40, 12, 45, 67, 10, 55,
        //     65, 59, 80, 81, 56, 55, 40, 12, 45, 67, 10, 55,
        //     65, 59, 80, 81, 56, 55, 40, 12, 45, 67, 10, 55,
        //     65, 59, 80, 81, 56, 55, 40, 12, 45, 67, 10, 55
        // ],
        borderWidth: 2,
        fill: false,
        segment: {
          borderColor: (context) => {
            const index = context.p0DataIndex; // Índice del punto de inicio del segmento
            // // const value = context.chart.data.datasets[0].data[index]; // Valor del punto inicial del segmento
            // // const label = context.chart.data.labels[0].data[index];
            // const value = context.p0.datalabels[0].context.dataset.data[index]; // Valor del punto inicial del segmento
            // const label = context.chart.data.labels[0].data[index];
            const value = context.chart.data.datasets[0].data[index]; // Valor del punto inicial del segmento
            const label = context.chart.data.labels[index]; // Etiqueta correspondiente al valor

            const dateSplit = label.split("-");
            // const labelDate = new Date(label);
            const labelDate = new Date(Date.UTC(dateSplit[0], (parseInt(dateSplit[1]) - 1), dateSplit[2], 12, 0, 0));
            // console.log("VALUEEE: ", value);
            // console.log("LABELL DATE: ", labelDate);

            // // Cambia el color del segmento según el valor del punto inicial
            // if (value > 75) {
            //   return "rgba(255, 99, 132, 1)"; // Rojo
            // } else if (value > 50) {
            //   return "rgba(54, 162, 235, 1)"; // Azul
            // } else {
            //   return "rgba(75, 192, 192, 1)"; // Verde
            // }
            // // return value > 75 ? "rgba(255, 99, 132, 1)" : "rgba(54, 162, 235, 1)";
            
            // Cambia el color del segmento según el valor del punto inicial
            if (esFechaEnRango(labelDate, above)) {
              return "rgba(75, 192, 192, 1)"; // Verde
            } else if (esFechaEnRango(labelDate, below)) {
              return "rgba(255, 99, 132, 1)"; // Rojo
            } else {
              return "rgba(54, 162, 235, 1)"; // Azul
            }
          },
        },
        pointRadius: 0, // Desactiva los puntos
        pointBorderWidth: 0, // Desactiva el borde de los puntos
        pointBackgroundColor: "transparent", // Hace transparente el fondo de los puntos
      
        // pointRadius: 5,
        // pointBackgroundColor: "#fff",
        // pointBorderColor: "#000",
        // pointBorderWidth: 2,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: "bold",
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Values",
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: "bold",
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
          beginAtZero: true,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            return `Date: ${tooltipItems[0].label}`;
          },
          label: (tooltipItem) => {
            const label = tooltipItem.label;
            /*const value = tooltipItem.raw;
            return `Value: ${value}`;*/
            
            // const label = context.chart.data.labels[index]; // Etiqueta correspondiente al valor

            const dateSplit = label.split("-");
            // const labelDate = new Date(label);
            const labelDate = new Date(Date.UTC(dateSplit[0], (parseInt(dateSplit[1]) - 1), dateSplit[2], 12, 0, 0));
//obtenerPercent = (targetDate, dateRanges, percents)
            if (esFechaEnRango(labelDate, above)) {
              return "% Change: " + obtenerPercent(labelDate, above, abovePercents);
            } else if (esFechaEnRango(labelDate, below)) {
              return "% Change: " + obtenerPercent(labelDate, below, belowPercents);
            } else {
              return ""; // Azul
            }
          },
          // backgroundColor: (tooltipItem) => {
          //   console.log("LABELLL12345: ", tooltipItem);
          //   // const index = tooltipItem[0].dataIndex;
          //   // const value = data.datasets[0].data[index];
            
          //   const label = tooltipItem[0].label; // Obtiene el label
          //   const value = tooltipItem[0].raw;
          //   console.log("LABELLL: ", label);

          //   const dateSplit = label.split("-");
          //   const labelDate = new Date(Date.UTC(dateSplit[0], (parseInt(dateSplit[1]) - 1), dateSplit[2], 12, 0, 0));
          //   console.log("LABELLL DATEEE: ", labelDate);
            
          //   // Cambia el color del segmento según el valor del punto inicial
          //   if (esFechaEnRango(labelDate, above)) {
          //     return "rgba(75, 192, 192, 1)"; // Verde
          //   } else if (esFechaEnRango(labelDate, below)) {
          //     return "rgba(255, 99, 132, 1)"; // Rojo
          //   } else {
          //     return "rgba(54, 162, 235, 1)"; // Azul
          //   }
          //   // if (value > 75) {
          //   //   return "rgba(255, 99, 132, 1)"; // Rojo
          //   // } else if (value > 50) {
          //   //   return "rgba(54, 162, 235, 1)"; // Azul
          //   // } else {
          //   //   return "rgba(75, 192, 192, 1)"; // Verde
          //   // }
          // },
          beforeBody: (tooltipItems) => {
            // Aquí puedes aplicar la lógica para cambiar el color del tooltip según el label y value
            const label = tooltipItems[0].label; // Obtiene el label
            const value = tooltipItems[0].raw;

            const dateSplit = label.split("-");
            const labelDate = new Date(Date.UTC(dateSplit[0], (parseInt(dateSplit[1]) - 1), dateSplit[2], 12, 0, 0));

            let backgroundColor;
            if (esFechaEnRango(labelDate, above)) {
              backgroundColor = "rgba(75, 192, 192, 1)"; // Verde
            } else if (esFechaEnRango(labelDate, below)) {
              backgroundColor = "rgba(255, 99, 132, 1)"; // Rojo
            } else {
              backgroundColor = "rgba(54, 162, 235, 1)"; // Azul
            }

            // Cambia el color del tooltip aquí
            tooltipItems[0].chart.tooltip.options.backgroundColor = backgroundColor;
          },
        },
        titleFont: {
          family: "'Arial', sans-serif",
          size: 14,
          weight: "bold",
          color: "#fff",
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 12,
          color: "#fff",
        },
        displayColors: false, // Oculta la barra de color del dataset en el tooltip
     
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  return (
    // <div className="w-full max-w-lg mx-auto">
    //   <Line data={data} options={options} />
    // </div>
    <div style={{ width: '100%' }}>
      <div style={{ height: '600px' }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default LineChartLinear;
