// import React from "react";
// import { Scatter } from "react-chartjs-2";

// import {
//   Chart as ChartJS,
//   LinearScale,
//   PointElement,
//   Tooltip,
//   Legend,
// } from "chart.js";

// ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

// const ScatterChart = ({pointsData, xLabel, yLabel}) => {
//   console.log("XXX: ", pointsData);
//   const data = {
//     labels: pointsData.map(data => data.x),//['Category A', 'Category B', 'Category C', 'Category D'], // Etiquetas para el eje X
//     datasets: [
//       {
//         label: "Dataset 1",
//         data: pointsData,
//         backgroundColor: "rgba(54, 162, 235, 1)",
//         pointRadius: 7,
//       },
//     ],
//   };

//   const options = {
//     scales: {
//       x: {
//         type: "linear",
//         position: "bottom",
//         title: {
//           display: true,
//           text: xLabel,
//           font: {
//             family: "'Arial', sans-serif",
//             size: 14,
//             weight: 'bold',
//           },
//         },
//         ticks: {
//           font: {
//             family: "'Arial', sans-serif",
//             size: 12,
//           },
//         },
//       },
//       y: {
//         type: "linear",
//         title: {
//           display: true,
//           text: yLabel,
//           font: {
//             family: "'Arial', sans-serif",
//             size: 14,
//             weight: 'bold',
//           },
//         },
//         ticks: {
//           font: {
//             family: "'Arial', sans-serif",
//             size: 12,
//           },
//           callback: function(value) {
//             return `${value}%`; // Añade el símbolo de porcentaje a los valores del eje Y
//           },
//         },
//       },
//     },
//     plugins: {
//       datalabels: {
//         display: false,
//       },
//       tooltip: {
//         callbacks: {
//           title: function(tooltipItems) {
//             // return `Custom Title: Point ${tooltipItems[0].dataIndex + 1}`;
//             return `${tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].ticker}`;
//           },
//           label: function(tooltipItem) {
//             return `${xLabel}: ${tooltipItem.raw.x} \n ${yLabel}: ${tooltipItem.raw.y}%`;
//           },
//           afterLabel: function(tooltipItem) {
//             return '';//'Additional Info: e.g., Some details';
//           },
//         },
//         backgroundColor: "rgba(0, 0, 0, 0.8)", // Fondo del tooltip
//         titleFont: {
//           family: "'Arial', sans-serif",
//           size: 14,
//           weight: 'bold',
//           color: "#fff",
//         },
//         bodyFont: {
//           family: "'Arial', sans-serif",
//           size: 12,
//           color: "#fff",
//         },
//         displayColors: false, // Oculta el color del dataset en el tooltip
//       },
//       legend: {
//         display: false, // Oculta la leyenda de los labels de los datasets
//       },
//     },
//     responsive: true,
//     maintainAspectRatio: false,
//   };

//   return (
//     <div style={{ height: '450px' }}>
//       <Scatter data={data} options={options} />
//     </div>
//   );
// };

// export default ScatterChart;






import React from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, ScatterController, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Title } from "chart.js";
// import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

// Registramos los componentes necesarios para Scatter Chart
ChartJS.register(ScatterController, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Title);

const ScatterChart = ({pointsData, xLabel, yLabel}) => {
  // pointsData.forEach(element => {
  //   element.y = element.y * 10;
  // });
  console.log("pointsData44: ", pointsData);
  // Definimos los datos para el scatter plot
  const data = {
    labels: pointsData.map(data => data.x),//['Category A', 'Category B', 'Category C', 'Category D'], // Etiquetas para el eje X
    datasets: [
      {
        label: 'Dataset 1',
        data: pointsData,/*[
          { x: 'Category A', y: 10 },
          { x: 'Category B', y: 20 },
          { x: 'Category C', y: 15 },
          { x: 'Category D', y: 25 },
        ],*/
        // backgroundColor: 'rgba(75, 192, 192, 1)',
        // pointRadius: 6,
        // backgroundColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "#BD31F2",
        // backgroundColor: "#E0A7F5",
        // borderColor: "#BD31F2",
        // borderWidth: 1,
        pointRadius: 9,
      },
      // {
      //   label: 'Dataset 2',
      //   data: [
      //     { x: 'Category A', y: 30 },
      //     { x: 'Category B', y: 10 },
      //     { x: 'Category C', y: 40 },
      //     { x: 'Category D', y: 35 },
      //   ],
      //   backgroundColor: 'rgba(255, 99, 132, 1)',
      //   pointRadius: 6,
      // },
    ],
  };

  // Configuración del gráfico
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        // callbacks: {
        //   label: (context) => `Y: ${context.parsed.y}`,
        // },
        callbacks: {
          title: function(tooltipItems) {
            // return `Custom Title: Point ${tooltipItems[0].dataIndex + 1}`;
            return `${tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].ticker}`;
          },
          label: function(tooltipItem) {
            // return `${xLabel}: ${tooltipItem.raw.x} \n ${yLabel}: ${tooltipItem.raw.y}%`;
            return `${tooltipItem.raw.y}%`;
          },
          afterLabel: function(tooltipItem) {
            return '';//'Additional Info: e.g., Some details';
          },
        },
      },
    },
    scales: {
      x: {
        type: 'category', // Tipo 'category' para el eje X (cadenas)
        title: {
          display: false,
          text: 'Categories',
        },
      },
      y: {
        type: 'linear', // Eje Y numérico
        title: {
          display: false,
          text: 'Numerical Value',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Scatter data={data} options={options} />
    // <Card>
    //   <CardHeader>
    //     <CardTitle>Scatter Chart (X: Categories, Y: Numbers)</CardTitle>
    //   </CardHeader>
    //   <CardContent>
    //     <Scatter data={data} options={options} />
    //   </CardContent>
    // </Card>
  );
};

export default ScatterChart;
