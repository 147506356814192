import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const HistogramChart = ({ histogramData, ticker }) => {
  // Calcula el valor mínimo y máximo de histogramData
  const minValue = Math.min(...histogramData);
  const maxValue = Math.max(...histogramData);

  // Calcula los grupos (bins) y las frecuencias
  const { labels, frequencies, ranges } = useMemo(() => {
    // Define el tamaño de los intervalos
    const binSize = 1;
    const bins = [];
    const binsRange = [];
    const frequencies = [];

    // Calcula el número de bins necesarios
    const binStart = Math.floor(minValue / binSize) * binSize; // Inicio del bin más bajo
    const binEnd = Math.ceil(maxValue / binSize) * binSize; // Final del bin más alto

    for (let i = binStart; i <= binEnd; i += binSize) {
      // bins.push(`${i} to ${i + binSize}`);
      bins.push(`${i + binSize}`);
      binsRange.push(`${i} to ${i + binSize}`);
      frequencies.push(0); // Inicialmente todos los bins tienen frecuencia 0
    }

    // Cuenta cuántos valores caen en cada bin
    histogramData.forEach(value => {
      const binIndex = Math.floor((value - binStart) / binSize);
      frequencies[binIndex] += 1;
    });

    return {
      labels: bins,
      frequencies,
      ranges: binsRange,
    };
  }, [histogramData, minValue, maxValue]);

  // Datos del histograma
  const data = {
    labels, // Etiquetas de los bins
    datasets: [
      {
        label: 'Frequency',
        data: frequencies, // Frecuencia de valores por bin
        // backgroundColor: 'rgba(75, 192, 192, 0.6)',
        // borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: '#FF92C9',
        borderColor: 'rgb(249, 64, 158)',
        borderWidth: 1,
      },
    ],
  };

  // Opciones para el gráfico
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        // callbacks: {
        //   label: (context) => `Frequency: ${context.parsed.y}`,
        // },
        callbacks: {
          title: function(tooltipItems) {
            // return `${tooltipItems[0].raw}`;
            return "";
          },
          label: function(tooltipItem) {
            // console.log("TESSSS: ", tooltipItem);
            // return `${tooltipItem.label}`;
            return [
              `Frecuency: ${tooltipItem.raw}`,
              `Range: ${ranges[tooltipItem.dataIndex]}`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Value Range (bins)',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Frequency',
        },
      },
    },
  };

  return (
    <div>
      <h3>{ticker}</h3>
      <Bar data={data} options={options} />
    </div>
  );
};

export default HistogramChart;
