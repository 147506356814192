// import React, { useEffect, useRef } from "react";
// // import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
// import { Chart } from "chart.js";
// import "chartjs-chart-treemap"; // Importar el plugin de treemap

// // Registrar el controlador y elementos de Treemap
// import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
// import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin de DataLabels

// Chart.register(TreemapController, TreemapElement, ChartDataLabels);

// const TreemapChart = ({treeData}) => {
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null); // Referencia para el gráfico

//   useEffect(() => {
//     const ctx = chartRef.current.getContext("2d");

//     // Destruir el gráfico anterior si existe
//     if (chartInstanceRef.current) {
//       chartInstanceRef.current.destroy();
//     }

//     // Crear el nuevo gráfico Treemap
//     chartInstanceRef.current = new Chart(ctx, {
//       type: "treemap",
//       data: {
//         datasets: [
//           {
//             label: "MarketCap",
//             // label: "Treemap Example",
//             tree: treeData,
//             // tree: [
//             //   { category: "Category 1111", value: 100 },
//             //   { category: "Category 2", value: 200 },
//             //   { category: "Category 3", value: 300 },
//             //   { category: "Category 4", value: 150 },
//             //   { category: "Category 5", value: 250 },
//             // ],
//             key: "value",
//             groups: ["category"],
//             spacing: 0.01, // Espacio entre cuadros (default es 0.5)
//             // padding: 1, // Espacio interno entre el borde del cuadro y el contenido (default es 0)
//             backgroundColor: function (context) {
//               const colors = [
//                 "#D97A2D",
//                 "#007ACC",
//                 "#20C9C0",
//                 "#00907C",
//                 "#9A29C9",
//                 "#08088A",
//                 "#D73688",
//                 "#D4D404",
//                 "#8B2222",
//                 "#696969",
//                 "#00CCCC",
//                 "#731EBF",
//                 "#005200",
//                 "#556B2F",
//                 "#8B0000",
//                 "#4682B4",
//                 "#2F4F4F",
//                 "#4B0082",
//                 // "rgba(255, 99, 132, 0.8)",
//                 // "rgba(54, 162, 235, 0.8)", 
//                 // "rgba(255, 206, 86, 0.8)", 
//                 // "rgba(75, 192, 192, 0.8)",
//                 // "rgba(153, 102, 255, 0.8)",
//               ];
//               return colors[context.dataIndex % colors.length];
//             },
//             borderColor: "rgba(255, 255, 255, 1)",
//             borderWidth: 2,
//             hoverBorderColor: "rgba(255, 255, 255, 1)",
//             hoverBorderWidth: 4, // Aumenta el grosor del borde al pasar el mouse
//             datalabels: {
//               display: function (context) {
//                 const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
                
//                 // Definir tamaño mínimo para el ancho y alto
//                 const minWidth = 50;
//                 const minHeight = 120;
    
//                 return element.width >= minWidth && element.height >= minHeight;
//               },
//               // align: "end", // Alinea el texto al final (parte inferior)
//               // anchor: "end", // Ancla el texto al final (esquina inferior izquierda)
//               color: "white",
//               formatter: function (value, context) {
//                 const dataItem = context.dataset.data[context.dataIndex]._data.children[0];//context.chart.data.datasets[0].tree[context.dataIndex];
//                 return dataItem.category; // Muestra el nombre de la categoría
//                 // return context.raw.category; // Muestra el nombre de la categoría
//               },
//               font: {
//                 size: 12,
//                 weight: "bold",
//               },
//               padding: {
//                 // bottom: 4, // Ajustar el padding inferior
//                 top: 2,
//                 right: 40, // Ajustar el padding izquierdo
//               },
//               display: function (context) {
//                 const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];

//                 // Calcular si el cuadro es suficientemente grande
//                 const minWidth = 50; // Ancho mínimo en píxeles para mostrar el label
//                 const minHeight = 20; // Alto mínimo en píxeles para mostrar el label

//                 return element.width >= minWidth && element.height >= minHeight;
//               },
//               clip: false, // Evitar que el label se recorte en cuadros pequeños
//               // display: function (context) {
//               //   const width = context.chart.width;
//               //   const height = context.chart.height;
//               //   const rect = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex].getBoundingClientRect();
                
//               //   // Ocultar el label si el área del rectángulo es demasiado pequeña
//               //   return rect.width > width * 0.05 && rect.height > height * 0.05;
//               // },
//             },
//           },
//         ],
//       },
//       options: {
//         plugins: {
//           datalabels: {
//             display: true,
//             color: "white",
//             clip: false, // Asegura que los labels no se corten
//           },
//           tooltip: {
//             callbacks: {
//               title: function(tooltipItems) {
//                 // return tooltipItems[0].dataset.label;
//                 return "";
//               },
//               label: function (context) {
//                 // const dataItem = context.dataset.tree[context.dataIndex];//context.raw._data;
//                 // return `${dataItem.category}: ${dataItem.value}`;
//                 const dataItem = context.raw._data.children[0];//context.dataset.tree[context.dataIndex];

//                 const color = context.dataset.backgroundColor[context.dataIndex];
//                 // return `${dataItem.category}: ${dataItem.value}\nColor: ${color}`;
//                 return [
//                   `Ticker: ${dataItem.category}`,
//                   `Market Capitalization: ${dataItem.valueOriginal}`
//                 ];
//               },
//             },
//           },
//           legend: {
//             display: false,
//             labels: {
//               font: {
//                 family: "'Arial', sans-serif",
//                 size: 12,
//               },
//             },
//           },
//         },
//         // hover: {
//         //   mode: 'nearest', // Resalta el cuadro más cercano al mouse
//         //   onHover: function(event, elements) {
//         //     const chart = chartInstanceRef.current;
//         //     if (elements.length) {
//         //       const element = elements[0];
//         //       chart.canvas.style.cursor = 'pointer';
//         //       element.custom = element.custom || {};
//         //       element.custom.backgroundColor = 'rgba(255, 255, 255, 0.3)'; // Cambia el color al pasar el mouse
//         //     } else {
//         //       chart.canvas.style.cursor = 'default';
//         //     }
//         //   },
//         // },
//         animation: {
//           onComplete: () => {
//             const chart = chartInstanceRef.current;
//             chart.options.plugins.datalabels.display = function (context) {
//               const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];

//               const minWidth = 50;
//               const minHeight = 20;

//               return element.width >= minWidth && element.height >= minHeight;
//             };
//             chart.update();
//           },
//         },
//       },
//     });

//     // Cleanup: Destruir el gráfico al desmontar el componente
//     return () => {
//       if (chartInstanceRef.current) {
//         chartInstanceRef.current.destroy();
//       }
//     };
//   }, []);

//   // useEffect(() => {
//   //   if (chartRef.current && treeData && treeData.length > 0) {
//   //     const ctx = chartRef.current.getContext("2d");
  
//   //     // Destruir el gráfico anterior si existe
//   //     if (chartInstanceRef.current) {
//   //       chartInstanceRef.current.destroy();
//   //     }
  
//   //     // Crear el nuevo gráfico Treemap
//   //     chartInstanceRef.current = new Chart(ctx, {
//   //       type: "treemap",
//   //       data: {
//   //         datasets: [
//   //           {
//   //             label: "MarketCap",
//   //             // label: "Treemap Example",
//   //             tree: treeData,
//   //             // tree: [
//   //             //   { category: "Category 1111", value: 100 },
//   //             //   { category: "Category 2", value: 200 },
//   //             //   { category: "Category 3", value: 300 },
//   //             //   { category: "Category 4", value: 150 },
//   //             //   { category: "Category 5", value: 250 },
//   //             // ],
//   //             key: "value",
//   //             groups: ["category"],
//   //             spacing: 0.01, // Espacio entre cuadros (default es 0.5)
//   //             // padding: 1, // Espacio interno entre el borde del cuadro y el contenido (default es 0)
//   //             backgroundColor: function (context) {
//   //               const colors = [
//   //                 "#D97A2D",
//   //                 "#007ACC",
//   //                 "#20C9C0",
//   //                 "#00907C",
//   //                 "#9A29C9",
//   //                 "#08088A",
//   //                 "#D73688",
//   //                 "#D4D404",
//   //                 "#8B2222",
//   //                 "#696969",
//   //                 "#00CCCC",
//   //                 "#731EBF",
//   //                 "#005200",
//   //                 "#556B2F",
//   //                 "#8B0000",
//   //                 "#4682B4",
//   //                 "#2F4F4F",
//   //                 "#4B0082",
//   //                 // "rgba(255, 99, 132, 0.8)",
//   //                 // "rgba(54, 162, 235, 0.8)", 
//   //                 // "rgba(255, 206, 86, 0.8)", 
//   //                 // "rgba(75, 192, 192, 0.8)",
//   //                 // "rgba(153, 102, 255, 0.8)",
//   //               ];
//   //               return colors[context.dataIndex % colors.length];
//   //             },
//   //             borderColor: "rgba(255, 255, 255, 1)",
//   //             borderWidth: 2,
//   //             hoverBorderColor: "rgba(255, 255, 255, 1)",
//   //             hoverBorderWidth: 4, // Aumenta el grosor del borde al pasar el mouse
//   //             datalabels: {
//   //               display: function (context) {
//   //                 const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
                  
//   //                 // Definir tamaño mínimo para el ancho y alto
//   //                 const minWidth = 50;
//   //                 const minHeight = 120;
      
//   //                 return element.width >= minWidth && element.height >= minHeight;
//   //               },
//   //               color: "white",
//   //               formatter: function (value, context) {
//   //                 const dataItem = context.dataset.data[context.dataIndex]._data.children[0];//context.chart.data.datasets[0].tree[context.dataIndex];
//   //                 return dataItem.category; // Muestra el nombre de la categoría
//   //                 // return context.raw.category; // Muestra el nombre de la categoría
//   //               },
//   //               font: {
//   //                 size: 12,
//   //                 weight: "bold",
//   //               },
//   //               padding: {
//   //                 // bottom: 4, // Ajustar el padding inferior
//   //                 top: 2,
//   //                 right: 40, // Ajustar el padding izquierdo
//   //               },
//   //               display: function (context) {
//   //                 const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
  
//   //                 const minWidth = 50; // Ancho mínimo en píxeles para mostrar el label
//   //                 const minHeight = 20; // Alto mínimo en píxeles para mostrar el label
  
//   //                 return element.width >= minWidth && element.height >= minHeight;
//   //               },
//   //               clip: false, // Evitar que el label se recorte en cuadros pequeños
//   //             },
//   //           },
//   //         ],
//   //       },
//   //       options: {
//   //         plugins: {
//   //           datalabels: {
//   //             display: true,
//   //             color: "white",
//   //             clip: false, // Asegura que los labels no se corten
//   //           },
//   //           tooltip: {
//   //             callbacks: {
//   //               title: function(tooltipItems) {
//   //                 return "";
//   //               },
//   //               label: function (context) {
//   //                 const dataItem = context.raw._data.children[0];
  
//   //                 const color = context.dataset.backgroundColor[context.dataIndex];
//   //                 return [
//   //                   `Ticker: ${dataItem.category}`,
//   //                   `Market Capitalization: ${dataItem.valueOriginal}`
//   //                 ];
//   //               },
//   //             },
//   //           },
//   //           legend: {
//   //             display: false,
//   //             labels: {
//   //               font: {
//   //                 family: "'Arial', sans-serif",
//   //                 size: 12,
//   //               },
//   //             },
//   //           },
//   //         },
//   //         animation: {
//   //           onComplete: () => {
//   //             const chart = chartInstanceRef.current;
//   //             chart.options.plugins.datalabels.display = function (context) {
//   //               const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
  
//   //               const minWidth = 50;
//   //               const minHeight = 20;
  
//   //               return element.width >= minWidth && element.height >= minHeight;
//   //             };
//   //             chart.update();
//   //           },
//   //         },
//   //       },
//   //     });
  
//   //     // Cleanup: Destruir el gráfico al desmontar el componente
//   //     return () => {
//   //       if (chartInstanceRef.current) {
//   //         chartInstanceRef.current.destroy();
//   //       }
//   //     };
//   //   }
//   // }, [treeData]);

//   return (
//     // <Card className="max-w-lg mx-auto">
//     //   <CardHeader>
//     //     <CardTitle>Treemap Example</CardTitle>
//     //   </CardHeader>
//     //   <CardContent>
//         <canvas ref={chartRef} />
//     //   </CardContent>
//     // </Card>
//   );
// };

// export default TreemapChart;












// import React, { useEffect, useRef } from "react";
// import { Chart } from "chart.js";
// import "chartjs-chart-treemap"; // Importar el plugin de treemap

// import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
// import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin de DataLabels

// Chart.register(TreemapController, TreemapElement, ChartDataLabels);

// const TreemapChart = ({ treeData }) => {
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null);

//   useEffect(() => {
//     const ctx = chartRef.current.getContext("2d");

//     if (chartInstanceRef.current) {
//       chartInstanceRef.current.destroy();
//     }

//     const MIN_AREA_PERCENTAGE = 0.005;//0.01; // Porcentaje mínimo de área para los cuadros pequeños

//     // Calcular el total de los valores para ajustar las áreas
//     const totalValue = treeData.reduce((acc, item) => acc + item.value, 0);

//     // Ajustar los valores de treeData para asegurar un mínimo tamaño visual
//     const adjustedTreeData = treeData.map(item => {
//       const valuePercentage = item.value / totalValue;
//       const adjustedValue = Math.max(valuePercentage, MIN_AREA_PERCENTAGE) * totalValue;
//       return {
//         ...item,
//         value: adjustedValue,
//       };
//     });

//     chartInstanceRef.current = new Chart(ctx, {
//       type: "treemap",
//       data: {
//         datasets: [
//           {
//             label: "MarketCap",
//             tree: adjustedTreeData,
//             key: "value",
//             groups: ["category"],
//             spacing: 0.01, // Espacio entre cuadros
//             backgroundColor: function (context) {
//               const colors = [
//                 "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9",
//                 "#08088A", "#D73688", "#D4D404", "#8B2222", "#696969",
//                 "#00CCCC", "#731EBF", "#005200", "#556B2F", "#8B0000",
//                 "#4682B4", "#2F4F4F", "#4B0082",
//               ];
//               return colors[context.dataIndex % colors.length];
//             },
//             borderColor: "rgba(255, 255, 255, 1)",
//             borderWidth: 2,
//             hoverBorderColor: "rgba(255, 255, 255, 1)",
//             hoverBorderWidth: 4,
//             datalabels: {
//               display: function (context) {
//                 const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
//                 const minWidth = 10;
//                 const minHeight = 20;
//                 return element.width >= minWidth && element.height >= minHeight;
//               },
//               color: "white",
//               formatter: function (value, context) {
//                 const dataItem = context.dataset.data[context.dataIndex]._data.children[0];
//                 return dataItem.category;
//               },
//               font: {
//                 size: 12,
//                 weight: "bold",
//               },
//             },
//           },
//         ],
//       },
//       options: {
//         plugins: {
//           datalabels: {
//             display: true,
//             color: "white",
//             clip: false,
//           },
//           tooltip: {
//             callbacks: {
//               label: function (context) {
//                 const dataItem = context.raw._data.children[0];
//                 return [
//                   `Ticker: ${dataItem.category}`,
//                   `Market Capitalization: ${dataItem.valueOriginal}`,
//                 ];
//               },
//             },
//           },
//           legend: {
//             display: false,
//           },
//         },
//       },
//     });

//     return () => {
//       if (chartInstanceRef.current) {
//         chartInstanceRef.current.destroy();
//       }
//     };
//   }, [treeData]);

//   return <canvas ref={chartRef} />;
// };

// export default TreemapChart;











// // import React, { useEffect, useRef } from "react";
// // import { Chart } from "chart.js";
// // import "chartjs-chart-treemap"; // Importar el plugin de treemap

// // import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
// // import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin de DataLabels

// // Chart.register(TreemapController, TreemapElement, ChartDataLabels);

// // const TreemapChart = ({treeData}) => {
// //   const chartRef = useRef(null);
// //   const chartInstanceRef = useRef(null); // Referencia para el gráfico

// //   useEffect(() => {
// //     const ctx = chartRef.current.getContext("2d");

// //     // Destruir el gráfico anterior si existe
// //     if (chartInstanceRef.current) {
// //       chartInstanceRef.current.destroy();
// //     }

// //     // Crear el nuevo gráfico Treemap
// //     chartInstanceRef.current = new Chart(ctx, {
// //       type: "treemap",
// //       data: {
// //         datasets: [
// //           {
// //             label: "MarketCap",
// //             tree: treeData,
// //             key: "value",
// //             groups: ["category"],
// //             spacing: 0.01, // Espacio entre cuadros
// //             backgroundColor: function (context) {
// //               const colors = [
// //                 "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9",
// //                 "#08088A", "#D73688", "#D4D404", "#8B2222", "#696969",
// //                 "#00CCCC", "#731EBF", "#005200", "#556B2F", "#8B0000",
// //                 "#4682B4", "#2F4F4F", "#4B0082",
// //               ];
// //               return colors[context.dataIndex % colors.length];
// //             },
// //             borderColor: "rgba(255, 255, 255, 1)",
// //             borderWidth: 2,
// //             hoverBorderColor: "rgba(255, 255, 255, 1)",
// //             hoverBorderWidth: 4, // Aumenta el grosor del borde al pasar el mouse
// //             datalabels: {
// //               display: function (context) {
// //                 const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
                
// //                 // Definir tamaño mínimo para el ancho y alto
// //                 const minWidth = 50;
// //                 const minHeight = 20;
    
// //                 return element.width >= minWidth && element.height >= minHeight;
// //               },
// //               color: "white",
// //               formatter: function (value, context) {
// //                 const dataItem = context.dataset.data[context.dataIndex]._data.children[0];
// //                 return dataItem.category; // Muestra el nombre de la categoría
// //               },
// //               font: {
// //                 size: 12,
// //                 weight: "bold",
// //               },
// //             },
// //           },
// //         ],
// //       },
// //       options: {
// //         plugins: {
// //           datalabels: {
// //             display: true,
// //             color: "white",
// //             clip: false,
// //           },
// //           tooltip: {
// //             callbacks: {
// //               label: function (context) {
// //                 const dataItem = context.raw._data.children[0];
// //                 return [
// //                   `Ticker: ${dataItem.category}`,
// //                   `Market Capitalization: ${dataItem.valueOriginal}`
// //                 ];
// //               },
// //             },
// //           },
// //           legend: {
// //             display: false,
// //           },
// //         },
// //         animation: {
// //           onComplete: () => {
// //             const chart = chartInstanceRef.current;
// //             chart.options.plugins.datalabels.display = function (context) {
// //               const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
// //               const minWidth = 50;
// //               const minHeight = 20;

// //               return element.width >= minWidth && element.height >= minHeight;
// //             };
// //             chart.update();
// //           },
// //         },
// //       },
// //     });

// //     // Cleanup: Destruir el gráfico al desmontar el componente
// //     return () => {
// //       if (chartInstanceRef.current) {
// //         chartInstanceRef.current.destroy();
// //       }
// //     };
// //   }, [treeData]);

// //   return <canvas ref={chartRef} />;
// // };

// // export default TreemapChart;











import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import "chartjs-chart-treemap"; // Importar el plugin de treemap

import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin de DataLabels

Chart.register(TreemapController, TreemapElement, ChartDataLabels);

const TreemapChart = ({treeData}) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null); // Referencia para el gráfico

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Destruir el gráfico anterior si existe
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const MIN_AREA_PERCENTAGE = 0.001;//0.01; // Porcentaje mínimo de área para los cuadros pequeños

    // Calcular el total de los valores para ajustar las áreas
    const totalValue = treeData.reduce((acc, item) => acc + item.value, 0);

    // Ajustar los valores de treeData para asegurar un mínimo tamaño visual
    const adjustedTreeData = treeData.map(item => {
      const valuePercentage = item.value / totalValue;
      const adjustedValue = Math.max(valuePercentage, MIN_AREA_PERCENTAGE) * totalValue;
      return {
        ...item,
        value: adjustedValue,
      };
    });
    
    // Crear el nuevo gráfico Treemap
    chartInstanceRef.current = new Chart(ctx, {
      type: "treemap",
      data: {
        datasets: [
          {
            label: "MarketCap",
            tree: adjustedTreeData,
            key: "value",
            groups: ["category"],
            spacing: 0.01, // Espacio entre cuadros
            backgroundColor: function (context) {
              const colors = [
                "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9",
                "#08088A", "#D73688", "#D4D404", "#8B2222", "#696969",
                "#00CCCC", "#731EBF", "#005200", "#556B2F", "#8B0000",
                "#4682B4", "#2F4F4F", "#4B0082",
              ];
              return colors[context.dataIndex % colors.length];
            },
            borderColor: "rgba(255, 255, 255, 1)",
            borderWidth: 2,
            hoverBorderColor: "rgba(255, 255, 255, 1)",
            hoverBorderWidth: 4, // Aumenta el grosor del borde al pasar el mouse
            datalabels: {
              display: function (context) {
                const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
                
                // Definir tamaño mínimo para el ancho y alto
                const minWidth = 50;
                const minHeight = 20;
    
                return element.width >= minWidth && element.height >= minHeight;
              },
              color: "white",
              formatter: function (value, context) {
                const dataItem = context.dataset.data[context.dataIndex]._data.children[0];
                return dataItem.category; // Muestra el nombre de la categoría
              },
              font: {
                size: 12,
                weight: "bold",
              },
            },
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: true,
            color: "white",
            clip: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const dataItem = context.raw._data.children[0];
                return [
                  `Ticker: ${dataItem.category}`,
                  `Market Capitalization: ${dataItem.valueOriginal}`
                ];
              },
            },
          },
          legend: {
            display: false,
          },
        },
        animation: {
          onComplete: () => {
            const chart = chartInstanceRef.current;
            chart.options.plugins.datalabels.display = function (context) {
              const element = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
              const minWidth = 50;
              const minHeight = 20;

              return element.width >= minWidth && element.height >= minHeight;
            };
            chart.update();
          },
        },
      },
    });

    // Cleanup: Destruir el gráfico al desmontar el componente
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [treeData]);

  return <canvas ref={chartRef} />;
};

export default TreemapChart;
