import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChartNegativeToNow = ({barData}) => {
  console.log("123 DAT", barData);
  // Datos para el gráfico
  const data = {
    labels: barData.map(bd => bd.label),
    datasets: [
      {
        label: "",
        data: barData.map(b => b.value),
        backgroundColor: data => {
          const value = data.raw;
          return value >= 0 ? "rgba(75, 192, 192, 1)" : "rgba(255, 99, 132, 1)";
        },
        borderColor: data => {
          const value = data.raw;
          return value >= 0 ? "rgba(75, 192, 192, 1)" : "rgba(255, 99, 132, 1)";
        },
        borderWidth: 1,
      },
    ],
  };

  // Opciones de configuración para el gráfico
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        // position: 'left',
        // align: 'start',
        labels: {
          font: {
            family: "'Arial', sans-serif",
            size: 18,
            weight: 'bold'
          },
          generateLabels: function (chart) {
            const labels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
            labels.forEach(label => {
              // Quitar el color y el cuadro de la leyenda
              label.fillStyle = 'transparent'; // Evita que se pinte el color de la leyenda
              label.strokeStyle = 'transparent'; // Evita que se pinte el borde de la leyenda
            });
            return labels;
          },
        },
        // Añadir padding para simular el margen
        padding: {
          top: 10,    // Margen superior
          bottom: 310, // Margen inferior
          left: 20,   // Margen izquierdo
          right: 20,  // Margen derecho
        },
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            // // return `${tooltipItems[0].label}`;
            // // return `% Change: ${tooltipItems[0].raw.toFixed(2)}%`;
            // return `Val: ${barData[tooltipItems[0].dataIndex].value}`;
            // // return `${tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].ticker}`;
          },
          label: function (tooltipItem) {
            // const text = `${barData[tooltipItem.dataIndex].date} ${tooltipItem.raw.toFixed(2)}%`;
            // const text = `Date: ${barData[tooltipItem.dataIndex].date}`;
            const text = `% Change: ${tooltipItem.raw.toFixed(2)}%`;
            return text;//` ${tooltipItem.raw >= 0 ? '+' : ''}${tooltipItem.raw.toFixed(2)}`;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleFont: {
          family: "'Arial', sans-serif",
          size: 14,
          weight: "bold",
          color: "#fff",
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 12,
          color: "#fff",
        },
        displayColors: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "",
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: "bold",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "",
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: "bold",
          },
        },
        ticks: {
          callback: function (value) {
            // return value >= 0 ? `%${value}` : value;
            return `${value}%`;
          },
        },
        beginAtZero: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div style={{ overflowX: 'auto', width: '100%', marginTop: '30px' }}>
      <div style={{ minWidth: `${barData.length * 20}px`, height: '500px' }}>
        <Bar data={data} options={options} /> 
      </div>
    </div>
  );
};

export default BarChartNegativeToNow;
