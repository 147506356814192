import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import BotResponse from "./BotResponse";
import ChatMessage from "./ChatMessage"; // If you still need it elsewhere
import SearchTicker from "./searchTicker/SearchTicker"; // Import the SearchTicker component
import axios from "axios";
import RingLoader from "react-spinners/RingLoader";
import PulseLoader from "react-spinners/PulseLoader";
import AudioRecorder from "./AudioRecord/AudioRecorder";
import AudioPlayer from "react-audio-player";
import convertTextToAudio from "./AudioRecord/ConvertTextToAudio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ChatMessage.css";
import "./Chatbot.css";
import processHTML from "./processHTML";
import SuccessStripe from "./stripe/SuccessStripe";
import CancelStripe from "./stripe/SuccessStripe";

/*import arrowDownImage from "./assets/arrowDownImage.png";*/
import arrowDownImage from "./assets/arrowDownImage.svg";
import Traffic from "./assets/newImages/Traffic.svg";
import send from "./assets/newImages/Send.svg";

import Sidebar from "./sideBar/Sidebar";

import ChatBotUserProfile from "./loginUserProfile/ChatbotUserProfile";

import SurveyPopup from "./survey/SurveyPopup";
import SuggestionItem from "./suggestion/SuggestionItem";
import LimitPopup from "./extraComponentTemporal/LimitPopup";
import WaitlistPopup from "./extraComponentTemporal/WaitlistPopup";
import SubscriptionPopup from "./extraComponentTemporal/SubscriptionsPopup";

import { ApiUrl, ApiUrlStripe } from "./services/restAPI";

import htmlReactParser from "html-react-parser";

const messagePropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps.message.id === nextProps.message.id &&
    prevProps.isAudioConversionEnabled === nextProps.isAudioConversionEnabled &&
    prevProps.isLastMessage === nextProps.isLastMessage
  );
};
const Message = memo(
  ({
    message,
    dataType,
    handleDataChange,
    isAudioConversionEnabled,
    isLastMessage,
    typingEffectComplete,
    setIsTypingMessage, // Add this prop
    scrollToBottom, // Add this prop
  }) => {
    const [typedText, setTypedText] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const typingMessageId = useRef(null);

    useEffect(() => {
      if (message.isSentByUser || message.text === "") return;

      if (isLastMessage) {
        typingMessageId.current = message.id; // Establece el mensaje actual como el mensaje que se está tipeando

        setIsTyping(true);
        setIsTypingMessage(true); // Notify parent component that typing has started

        if (isAudioConversionEnabled && isLastMessage) {
          // If audio conversion is enabled and it's the last message, don't use typing effect
          setTypedText(message.text);
          setIsTyping(false);
          setIsTypingMessage(false); // Notify parent component that typing has completed
          typingEffectComplete(); // Notify the parent component that typing is complete
        } else {
          let currentIndex = 0;

          const fragments = message.text.split(/(<[^>]+>)/).filter(Boolean); // Split HTML into fragments
          
          const interval = setInterval(() => {
            if (currentIndex < fragments.length) {
              setTypedText(
                (prevText) => prevText + " " + fragments[currentIndex]
              );
              currentIndex++;
              scrollToBottom(); // Add scroll to bottom here
            } else {
              clearInterval(interval);
              setIsTyping(false);
              setIsTypingMessage(false); // Notify parent component that typing has completed
              typingEffectComplete(); // Notify the parent component that typing is complete
            }
          }, 100); // Adjust the interval for typing speed
          return () => {
            clearInterval(interval);
          };
        }
      } else {
        setTypedText(message.text);
      }
    }, [
      message,
      isAudioConversionEnabled,
      isLastMessage,
      typingEffectComplete,
      setIsTypingMessage, // Include this in dependency array
      scrollToBottom, // Include this in dependency array
    ]);

    const isObjectMessage = typeof message.data === "object";

    if (message.isSentByUser) {
      return (
        <div className="sent-message" key={message.id}>
          <div className="sent-message-content">{message.text}</div>
        </div>
      );
    } else if (message.text !== "") {      
      return (
        <div className="received-message" key={message.id}>
          <div className="received-message-content">
          {isObjectMessage ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: isTyping ? typedText : message.text,
                }}
              />
            ) : isTyping ? (
              htmlReactParser(typedText)
            ) : (
              message.text
            )}
          </div>
        </div>
      );
    } else {
      return (
        <BotResponse
          key={message.id}
          response={message.data}
          dataType={dataType}
          handleDataChange={handleDataChange}
        />
      );
    }
  },
  messagePropsAreEqual
);

const ChatBot = ({ userSpaceFinance }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedTool, setSelectedTool] = useState("");
  const [messages, setMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataType, setDataType] = useState("5. adjusted close");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMaxWordLimitExceeded, setIsMaxWordLimitExceeded] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [isAudioConversionEnabled, setIsAudioConversionEnabled] =
    useState(false); // Set it to false by default

  const [selectedTickers, setSelectedTickers] = useState([]);

  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isBotResponding, setIsBotResponding] = useState(false);
  const [isBotBusy, setIsBotBusy] = useState(false);
  const [isLastMessageTypingComplete, setIsLastMessageTypingComplete] =
    useState(true); // Initial value should be true
  const [isRecording, setIsRecording] = useState(false); // Ne
  const [isRecordingCanceled, setIsRecordingCanceled] = useState(false);
  const [isMessageProcessing, setIsMessageProcessing] = useState(false);

  const textareaRef = useRef(null);
  const maxTextAreaHeight = 180;

  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isStartRecordingButtonDisabled, setIsStartRecordingButtonDisabled] =
    useState(false);
  const [isAudioConversionButtonDisabled, setIsAudioConversionButtonDisabled] =
    useState(false);
  const [isChatBusy, setIsChatBusy] = useState(false);
  const [isTypingMessage, setIsTypingMessage] = useState(false);
  const [isAudioResponsePlaying, setIsAudioResponsePlaying] = useState(false);
  const isRecordingRef = useRef(false);

  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);

  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState(false);

  const handleTickerSelected = (ticker) => {
    setSelectedTickers((prevTickers) => [...prevTickers, ticker]);
  };

  const [showSurvey, setShowSurvey] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(true);

  const [footerHeight, setFooterHeight] = useState(120); // Inicializa con la altura mínima.
  const footerRef = useRef(null);

  const [buttonBottomPosition, setButtonBottomPosition] = useState("30px");

  const [showLimitPopup, setShowLimitPopup] = useState(false);
  const [showLimitWaitlist, setShowLimitWaitlist] = useState(false);
  
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");

  //Stripe
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentCanceled, setIsPaymentCanceled] = useState(false);

  const messagesEndRef = useRef(null);

  const [suggestions] = useState([
    {
      title: "PORTFOLIO OPTIMIZATION",
      text: "Optimize portfolio of BTC.c, ETH.c , XRP.c, NVDA, AAPL, TSLA, GLD, SLV, over the last year.",
      command:
        "Optimize portfolio of BTC.c, ETH.c , XRP.c, NVDA, AAPL, TSLA, GLD, SLV, over the last year.", // Esto es un ejemplo, ajusta según necesites
    },
    {
      title: "TIMES",
      text: "How many times C, GS, SAN, JPM, PG, KO, NVDA, AAPL, TSLA, AMZN, SPY above 5% from 2019 to 2024.",
      command:
        "How many times C, GS, SAN, JPM, PG, KO, NVDA, AAPL, TSLA, AMZN, SPY above 5% from 2019 to 2024.", // Ejemplo, ajusta según corresponda
    },
    // Añade tus otras sugerencias aquí
    {
      title: "PERCENTAGE CHANGE",
      text: "Percentage change of BTC.c, MSTR from january 2024 to now.",
      command: "Percentage change of BTC.c, MSTR from january 2024 to now", // Este es el comando específico que mencionaste
    },
    {
      title: "HALVING",
      text: "Halving of BTC.c, ETH.c, SOL.c, XRP.c, MSTR, SPY, GLD.",
      command: "Halving of BTC.c, ETH.c, SOL.c, XRP.c, MSTR, SPY, GLD", // Este es el comando específico que mencionaste
    },
  ]);

  const getDisplayName = () => {
    if (userSpaceFinance.email) {
      return userSpaceFinance.email;
    } else if (userSpaceFinance.name) {
      return userSpaceFinance.name;
    } 
    return userSpaceFinance.name;
  };

  const checkQueryLimit = async () => {
    setIsSubmitting(true);
    const userName = getDisplayName();
    try {
      const queryCountResponse = await axios.get(
        `${ApiUrl}BusinessUserDetail/NumberUserQueries/${userName}`
      );
      const queryCount = queryCountResponse.data;      
      return queryCount > 50;
    } catch (error) {
      console.error("Error checking query count:", error);
      return false; // Return false in case of an error, or handle it appropriately
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkQueryLimitWaitlist = async () => {
    setIsSubmitting(true);
    const userName = getDisplayName();
    //console.log("userName RODRIGO VEGA" +userName);
    try {
      const queryCountResponse = await axios.get(
        `${ApiUrl}BusinessUserDetail/UserWaitlist/${userName}`
      );      
      //console.log("userName RODRIGO" + queryCountResponse.data);
      return queryCountResponse.data;
    } catch (error) {
      console.error("Error checking query count:", error);
      return false; // Return false in case of an error, or handle it appropriately
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkUserSubscription = async () => {
    setIsSubmitting(true);
    const userName = getDisplayName();
    try {
      const response = await axios.get(
        `${ApiUrlStripe}BillingCustomer/CustomerUserQuerySuscription/${userName}`
      );
      const { item1: isSubscribed, item2 } = response.data;
      const message = item2 ? item2.message : "";
      return { isSubscribed, message };
    } catch (error) {
      console.error("Error checking user subscription:", error);
      return { isSubscribed: false, message: "Error checking subscription." };
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelectSuggestion = async (command) => {
    /**********************************************************************************/
    //QUITAR ESTO
    /*const isLimitReached = await checkQueryLimit();
    if (isLimitReached) {
      setShowLimitPopup(true);
      return;
    }

    const isLimitReachedWaitList = await checkQueryLimitWaitlist();
    if (isLimitReachedWaitList) {
      setShowLimitWaitlist(true);
      return;
    }*/

    const { isSubscribed, message } = await checkUserSubscription();
    if (!isSubscribed) {
      setSubscriptionMessage(message); // Guardar el mensaje
      setShowSubscriptionPopup(true); // Mostrar el popup
      return;
    }

    /**********************************************************************************/
    setInputValue(command); // Asume que command es una cadena
    setShowSuggestions(false); // Esconder las sugerencias después de seleccionar una
    processText(command); // Pasar directamente el comando a processText
  };  

  // Detección de una redirección desde Stripe después de un pago exitoso
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);    
    if (urlParams.get('success') === 'true') {      
      setIsPaymentSuccess(true);
    }
    else{
      setIsPaymentSuccess(false);
    }
  }, []);


  useEffect(() => {
    // Función para reemplazar el estado en el historial y agregar un nuevo estado
    const handleNavigation = () => {
      window.history.replaceState(null, "", window.location.href);
    };

    // Interceptar el evento de navegación hacia atrás
    const handlePopState = (event) => {
      event.preventDefault();
      handleNavigation();
      //alert("rodri.");
    };

    // Añadir el evento al cargar el componente
    window.addEventListener("popstate", handlePopState);

    // Añadir el estado inicial al historial
    handleNavigation();

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const checkSurveyCompletion = async () => {
      try {
        // Function to determine if a string is an email
        const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

        // Choose the correct userName value
        const userName = isEmail(userSpaceFinance.email)
          ? userSpaceFinance.email
          : isEmail(userSpaceFinance.name)
          ? userSpaceFinance.name
          : "";

        const checkSurveyURL =
          ApiUrl + "StartUserLogin/UserCompletedSurveryQuery/" + userName;
        const response = await axios.get(checkSurveyURL);
        /*console.log(
          "chat bot - response : " +
            response.data +
            "; userSpaceFinance.email: " +
            userName
        );*/
        setShowSurvey(!response.data); // Asignar directamente el resultado booleano
      } catch (error) {
        console.error("Error checking survey completion:", error);
      }
    };

    checkSurveyCompletion();
  }, [userSpaceFinance.email]);

  const handleSurveySubmit = () => {
    // Lógica después de enviar la encuesta, por ejemplo, ocultar el popup
    setShowSurvey(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolledDownEnough = window.scrollY > 100;
      const bottomOfWindow =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;

      setShowScrollToBottomButton(scrolledDownEnough && !bottomOfWindow);
    };

    // Agrega el listener para el evento de scroll
    window.addEventListener("scroll", handleScroll);

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const addMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setTimeout(() => {
      scrollToBottom();
      scrollToBottomPage(); // Asegúrate de que esta función lleva el scroll al final de la página
    }, 0); // El timeout puede ser necesario para esperar a que el DOM se actualice
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // Espera a que termine el desplazamiento suave para ocultar el botón
    setTimeout(() => {
      setShowScrollToBottomButton(false);
    }, 500); // Ajusta este tiempo si es necesario
  };

  useEffect(() => {
    adjustTextarea();
  }, [inputValue]);

  const adjustTextarea = () => {
    const textarea = textareaRef.current;
    const footer = footerRef.current;

    if (textarea) {
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 180); // Tu maxTextAreaHeight es 180px
      textarea.style.height = `${newHeight}px`;

      // Ajusta la altura del footer basado en si el textarea está completamente expandido.
      if (newHeight >= 90) {
        setFooterHeight(230); // Ajusta según necesites, por ejemplo a 200px
        setButtonBottomPosition("250px");
        if (footer) {
          footer.style.height = "230px"; // Opcional, manipula directamente si es necesario
        }
      } else {
        setFooterHeight(120); // Altura estándar del footer
        setButtonBottomPosition("140px");
        if (footer) {
          footer.style.height = "120px"; // Opcional, manipula directamente si es necesario
        }
      }
      scrollToBottomPage();
    }
  };

  const scrollToBottomPage = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const ensureTextareaVisible = () => {
    const textarea = textareaRef.current;
    const scrollContainer = document.querySelector(".chatbot-content");
    if (textarea && scrollContainer) {
      const offsetTop = textarea.offsetTop;
      const textareaHeight = textarea.offsetHeight;
      const scrollTop = scrollContainer.scrollTop;
      const containerHeight = scrollContainer.offsetHeight;

      if (offsetTop + textareaHeight > scrollTop + containerHeight - 120) {
        // Considerar la altura del footer
        scrollContainer.scrollTop =
          offsetTop + textareaHeight - containerHeight + 120;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!isBotTyping && !isBotResponding) {
        handleSubmit(e);
      }
    }
  };

  const handleRecordingStart = () => {
    if (!isBotTyping && !isBotResponding) {
      setIsRecording(true); // This will set recording to true
      // Rest of your recording logic...
    }
  };

  const toggleAudioConversion = () => {
    setIsAudioConversionEnabled(!isAudioConversionEnabled);
  };

  const processText = async (text) => {
    setIsSendButtonDisabled(true);
    setIsStartRecordingButtonDisabled(true);
    setIsAudioConversionButtonDisabled(true);
    setIsChatBusy(true);
    setIsTypingMessage(true);
    setIsMessageProcessing(true);
    setShowSuggestions(false);

    // Iniciar el temporizador de timeout
    const timeoutId = setTimeout(() => {
      setShowTimeoutMessage(true);
      // Opcional: recargar la página después de mostrar el mensaje de timeout por un breve período
      setTimeout(() => window.location.reload(), 5000); // Recarga la página después de 5 segundos
    }, 50000); // 50 segundos

    if (text.trim().split(/\s+/).length > 100) {
      setIsMaxWordLimitExceeded(true);
      setIsTypingMessage(false);
      clearTimeout(timeoutId); // Limpiar el temporizador si la consulta no es válida
      return;
    }

    if (!isSubmitting && text.trim().length > 0) {
      try {
        setIsSubmitting(true);

        // Determinar el identificador del usuario
        const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        const userName = isEmail(userSpaceFinance.email)
          ? userSpaceFinance.email
          : isEmail(userSpaceFinance.name)
          ? userSpaceFinance.name
          : "";

        const query = {
          query: text,
          data_type: dataType,
          user_info: userName,
        };

        setIsBotTyping(true);
        setIsBotBusy(true);

        const response = await axios.post(
          //"http://127.0.0.1:8000",
          //PRODUCTION
          "https://spacefinanceserver.azurewebsites.net/",

          //TEST
          //"https://spacefinanceservertest.azurewebsites.net/",
          query
        );

        const userMessage = {
          id: messages.length + 1,
          text: text,
          isSentByUser: true,
        };

        let botMessageText = "";
        if (typeof response.data.agentResponse === "string") {
          botMessageText = response.data.agentResponse;
        } else if (typeof response.data.agentResponse === "object") {
          botMessageText = JSON.stringify(response.data.agentResponse, null, 2);
        }

        const botPlaceholderMessage = {
          id: messages.length + 2,
          data: response.data,
          text: botMessageText,
          isSentByUser: false,
        };

        setMessages((prevMessages) => [...prevMessages, userMessage]);

        setInputValue("");
        setIsMaxWordLimitExceeded(false);

        setIsBotTyping(false); // Reset bot typing state
        setIsBotBusy(false); // Reset bot busy state
        setIsBotResponding(false); // Reset bot responding state
        setIsBotTyping(false); // Reset bot typing state
        setIsBotBusy(false); // Reset bot busy state
        setIsBotResponding(false); // Reset bot responding state
        setIsLastMessageTypingComplete(true); // Reset typing complete state

        if (
          isAudioConversionEnabled &&
          typeof botMessageText === "string" &&
          botMessageText.trim() !== ""
        ) {
          const audioUrl = await convertTextToAudio(botMessageText);
          const audioElement = new Audio(audioUrl);

          audioElement.play().then(() => {
            audioElement.addEventListener("ended", () => {
              setIsAudioResponsePlaying(false);
              setMessages((prevMessages) => [
                ...prevMessages,
                botPlaceholderMessage,
              ]);
              setIsSendButtonDisabled(false);
              setIsStartRecordingButtonDisabled(false);
              setIsAudioConversionButtonDisabled(false);
              setIsChatBusy(false);
            });
          });

          setIsAudioResponsePlaying(true);
        } else {
          setIsAudioResponsePlaying(false);
          setMessages((prevMessages) => [
            ...prevMessages,
            botPlaceholderMessage,
          ]);
          setIsSendButtonDisabled(false);
          setIsStartRecordingButtonDisabled(false);
          setIsAudioConversionButtonDisabled(false);
          setIsChatBusy(false);
        }

        // Asegúrate de limpiar el temporizador si la consulta se completa exitosamente
        clearTimeout(timeoutId);
        setShowTimeoutMessage(false); // Ocultar el mensaje de timeout
      } catch (error) {
        console.error(error);

        // Handle the error gracefully
        const errorMessage = "An error occurred while processing your request.";

        // Check if the previous message is an error message
        const isPreviousMessageError =
          messages.length > 0 && !messages[messages.length - 1].isSentByUser;

        if (isPreviousMessageError) {
          // Update the content of the previous error message
          const updatedErrorMessage = {
            ...messages[messages.length - 1],
            text: errorMessage,
          };

          setMessages((prevMessages) => [
            ...prevMessages.slice(0, prevMessages.length - 1),
            updatedErrorMessage,
          ]);
        } else {
          // Add a new error message
          const botErrorMessage = {
            id: messages.length + 1,
            text: errorMessage,
            isSentByUser: false,
          };

          setMessages((prevMessages) => [...prevMessages, botErrorMessage]);
        }

        setInputValue("");
        setIsBotTyping(false);
        setIsBotResponding(false);
        setIsBotBusy(false);
        setIsLastMessageTypingComplete(true);
        setIsAudioResponsePlaying(false);

        // Reset other states as needed
        setIsSendButtonDisabled(false);
        setIsStartRecordingButtonDisabled(false);
        setIsAudioConversionButtonDisabled(false);
        setIsChatBusy(false);
        setIsSubmitting(false);
        setIsTypingMessage(false);

        // Asegúrate de limpiar el temporizador si la consulta se completa exitosamente
        clearTimeout(timeoutId);
        setShowTimeoutMessage(false); // Ocultar el mensaje de timeout
      } finally {
        setIsSubmitting(false);
        setIsTypingMessage(false);
        setIsChatBusy(false);
        setIsSendButtonDisabled(false);
        setIsStartRecordingButtonDisabled(false);
        setIsAudioConversionButtonDisabled(false);
      }
    }
  };

  const handleDataChange = useCallback((newDataType) => {
    setDataType(newDataType);
  }, []);

  const handleRecordingComplete = useCallback(
    async (transcribedText) => {
      /************************************************************************************/
      //QUITAR ESTO
      /*const isLimitReached = await checkQueryLimit();
      if (isLimitReached) {
        setShowLimitPopup(true);
        return;
      }

      const isLimitReachedWaitList = await checkQueryLimitWaitlist();
      if (isLimitReachedWaitList) {
        setShowLimitWaitlist(true);
        return;
      }*/

      // Alrededor de la línea 870 (justo antes de llamar a processText)
      const { isSubscribed, message } = await checkUserSubscription();
      if (!isSubscribed) {
        alert(message); // Puedes reemplazar esto con un popup o cualquier otro método de notificación
        return;
      }

      /************************************************************************************/
      processText(transcribedText);
      setIsSendButtonDisabled(false);
      setIsRecording(false);
    },
    [processText]
  );

  const handleRecordingCancel = () => {
    // Enable the "Send" button when recording is canceled
    setIsSendButtonDisabled(false);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        !isSubmitting &&
        !isBotTyping &&
        !isBotResponding &&
        isLastMessageTypingComplete &&
        !isRecording
      ) {
        /************************************************************************************/
        //QUITAR ESTO
        /*const isLimitReached = await checkQueryLimit();
        if (isLimitReached) {
          setShowLimitPopup(true);
          return;
        }

        const isLimitReachedWaitList = await checkQueryLimitWaitlist();
        if (isLimitReachedWaitList) {
          setShowLimitWaitlist(true);
          return;
        }*/

        const { isSubscribed, message } = await checkUserSubscription();
        if (!isSubscribed) {
          setSubscriptionMessage(message); // Guardar el mensaje
          setShowSubscriptionPopup(true); // Mostrar el popup
          return;
        }

        /************************************************************************************/
        processText(inputValue);
        setIsLastMessageTypingComplete(false);
        setShowSuggestions(false);
        scrollToBottom();
      }

      // Enable the send button if recording was canceled
      if (isRecordingCanceled) {
        setIsRecordingCanceled(false);
        setIsLastMessageTypingComplete(true);
      }
    },
    [
      inputValue,
      processText,
      isSubmitting,
      isBotTyping,
      isBotResponding,
      isLastMessageTypingComplete,
      isRecording,
      isRecordingCanceled, // Include isRecordingCanceled in the dependencies
    ]
  );

  const formatText = (text) => {
    return text
      .split("**")
      .map((part, index) => (index % 2 === 0 ? part : `**${part}**`))
      .join("\n");
  };

  function capitalizeFirstLetter(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Dentro del componente ChatBot
  const setInputValueFromSidebar = (value) => {
    setInputValue(value);
  };

  return (
    <div className="body-pp">
      {isPaymentSuccess ? <CancelStripe /> : isPaymentCanceled && <CancelStripe />}  
      <div className="pp-row">
        <div className="column left">
          <Sidebar
            setInputValue={setInputValueFromSidebar}
            userSpaceFinance={userSpaceFinance}
          ></Sidebar>
          <div className="column left-bottom"></div>
        </div>
        <div className="column middle chat-container">
          <div className="chatbot-content">
            {showSurvey && (
              <SurveyPopup
                userSpaceFinance={userSpaceFinance}
                onClose={() => setShowSurvey(false)}
                onSubmit={handleSurveySubmit}
              />
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {isMaxWordLimitExceeded && (
              <p className="error-message">Max limit of 100 words exceeded.</p>
            )}
            {showTimeoutMessage && (
              <div className="timeout-message-container">
                <img src={Traffic} alt="Timeout" />
                {/*<p>La consulta ha tardado demasiado. Recargando...</p>*/}
              </div>
            )}
            <div className="chatbot-messages">
              {messages.map((message, index) => (
                <Message
                  key={message.id}
                  message={message}
                  dataType={dataType}
                  handleDataChange={setDataType}
                  isAudioConversionEnabled={isAudioConversionEnabled}
                  isLastMessage={index === messages.length - 1}
                  typingEffectComplete={() =>
                    setIsLastMessageTypingComplete(true)
                  }
                  setIsTypingMessage={setIsTypingMessage} // Pass the function
                  scrollToBottom={scrollToBottom}
                />
              ))}
              <div ref={messagesEndRef} />
            </div>
          </div>
          {/*<ChatBotUserProfile userSpaceFinance={userSpaceFinance} />*/}
        </div>
        <div className="column right">
          <div className="search-container">
            <SearchTicker onTickerSelected={handleTickerSelected} />
          </div>
        </div>
      </div>
      {showSuggestions && (
        <div className="suggestions-grid">
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
              key={index}
              suggestion={suggestion}
              onSelectSuggestion={handleSelectSuggestion}
            />
          ))}
        </div>
      )}
      {showLimitPopup && (
        <LimitPopup onClose={() => setShowLimitPopup(false)} />
      )}
      {showLimitWaitlist && (
        <WaitlistPopup
          userSpaceFinance={userSpaceFinance}
          onClose={() => setShowLimitWaitlist(false)}
        />
      )}
      {showSubscriptionPopup && (
        <SubscriptionPopup
          message={subscriptionMessage}
          onClose={() => setShowSubscriptionPopup(false)}
        />
      )}

      <footer>
        <div className="pp-row">
          <div className="column-footer left-footer"></div>
          <div className="footer-pp">
            <div className="column middle chat-container">
              <div className="chatbot-container">
                <div className="chatbot-footer">
                  {showScrollToBottomButton && (
                    <div
                      className="scrollToBottomButtonContainer"
                      style={{ bottom: buttonBottomPosition }}
                    >
                      <button
                        className="scrollToBottomButton"
                        onClick={scrollToBottom}
                        disabled={isTypingMessage}
                      >
                        <img src={arrowDownImage} alt="Scroll to bottom" />
                      </button>
                    </div>
                  )}
                  {/* Formulario de envío de mensaje */}
                  <div className="message-input-container">
                    <textarea
                      className="textarea-1"
                      rows="1"
                      ref={textareaRef}
                      value={inputValue}
                      onChange={(e) => {
                        if (e.target.value.length <= 250) {
                          setInputValue(e.target.value);
                          adjustTextarea();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSubmit(e);
                        }
                      }}
                      placeholder="Please use tickers"
                      maxLength={250}
                      disabled={isTypingMessage}
                    />
                    <button
                      className="button-1"
                      type="button"
                      onClick={handleSubmit}
                      disabled={
                        isSubmitting ||
                        inputValue.trim().length === 0 ||
                        isTypingMessage
                      }
                    >
                      {isSubmitting ? (
                        <PulseLoader
                          color="#133588"
                          loading={isSubmitting}
                          size={10}
                        />
                      ) : (
                        <img
                          src={send}
                          alt="Copy tickers"
                          className="imageSend"
                        />
                      )}
                    </button>
                  </div>

                  {/* Controles de audio */}
                  <div className="audio-controls">
                    {/*<button
                      className="audio-enableaudio-button"
                      onClick={toggleAudioConversion}
                      disabled={
                        isChatBusy ||
                        isTypingMessage ||
                        isAudioResponsePlaying ||
                        isBotTyping ||
                        isBotBusy ||
                        isRecording ||
                        !isLastMessageTypingComplete
                      }
                      style={{
                        backgroundImage: `url(${require("./assets/audioButtons/circulo.png")})`,
                        backgroundColor: isAudioConversionEnabled
                          ? "#4CF0EE"
                          : "#12127D",
                        backgroundPosition: isAudioConversionEnabled
                          ? "6px center"
                          : "calc(100% - 10px) center",
                        color: isAudioConversionEnabled ? "#259C9A" : "White",
                      }}
                    >
                      {isAudioConversionEnabled ? "Voice On" : "Voice Off"}
                    </button>
                    {/* Asumiendo que el AudioRecorder maneja su propio estado de visibilidad y estilo */}
                    <div className="audio-controls">
                      <AudioRecorder
                        onRecordingComplete={handleRecordingComplete}
                        onRecordingStart={() => setIsRecording(true)}
                        onRecordingCancel={() => setIsRecording(false)}
                        isStartRecordingButtonDisabled={
                          isChatBusy ||
                          isTypingMessage ||
                          isAudioResponsePlaying ||
                          isBotTyping ||
                          isBotBusy ||
                          isBotResponding ||
                          !isLastMessageTypingComplete
                        }
                        isStopRecordingButtonDisabled={!isRecording}
                        isCancelRecordingButtonDisabled={!isRecording}
                      />
                      {audioData && (
                        <AudioPlayer
                          src={audioData}
                          autoPlay
                          controls={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isSubmitting && (
                <div className="chatbot-spinner">
                  <RingLoader
                    color={"#133588"}
                    loading={isSubmitting}
                    size={150}
                  />
                </div>
              )}
              <p className="disclaimer-message">
                Space Finance can make mistakes. Please double check the
                results.
              </p>
            </div>
          </div>

          <div className="column right"></div>
        </div>
      </footer>
    </div>
  );
};

export default ChatBot;
