import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
//import './BarGraphCircles.css'; // Make sure to import your CSS

const BarGraphCircles = ({ data }) => {
  console.log("DATA PUNTITOS: ", data);
  const ref = useRef();
  const tooltipRef = useRef();
  // Estado para las dimensiones
  const [dimensions, setDimensions] = useState({ width: 930, height: 300 });

  useEffect(() => {
    // Actualizar las dimensiones al montar y al redimensionar la ventana
    const updateDimensions = () => {
      setDimensions({
        width: 930,
        height: 300, // Puedes hacer que la altura sea dinámica también si es necesario
      });
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions(); // Inicializar las dimensiones

    // Limpiar el escuchador de eventos al desmontar el componente
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(
    () => {
      if (dimensions.width === 0) return; // No ejecutar hasta que las dimensiones estén listas

      const svg = d3.select(ref.current);

      const tooltip = d3
      .select("body")
      .append("div")
      .attr("className", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "#238bb0")
      .style("color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("z-index", "10000");

      svg.selectAll("*").remove();

      // Set dimensions
      const margin = { top: 50, right: 30, bottom: 20, left: 30 };
      // Utilizar las dimensiones del estado para el ancho y alto
      const width = dimensions.width - margin.left - margin.right;
      const height = dimensions.height - margin.top - margin.bottom;

      // Create scales
      const xScale = d3
        .scaleBand()
        .domain(data.map((d) => d.label))
        .range([0, width - 150])
        .padding(0.2);

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.value)])
        .range([height, 0]);

      // Append group and apply transform to position graph within margins
      const g = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
      // Create the bars
      // Create the circles
      g.selectAll(".circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("className", "circle")
        .attr("cx", (d) => xScale(d.label) + xScale.bandwidth() / 2)
        .attr("cy", (d) => yScale(d.value))
        .attr("r", xScale.bandwidth() / 10)
        .attr("fill", "steelblue")

        .on("mouseover", function (event, d) {
          tooltip
            .html("Valor: " + d.value.toFixed(2)) // Asegúrate de acceder a la propiedad correcta
            .style("opacity", 1)
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY + 10 + "px");
        })
        .on("mousemove", function (event) {
          tooltip
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY + 10 + "px");
        })
        .on("mouseout", function () {
          tooltip.style("opacity", 0);
        });

      // Add x-axis
      g.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale));

      // Add y-axis
      g.append("g").call(d3.axisLeft(yScale));
    },
    [data],
    dimensions
  );

  return (
    <>
      <div
        style={{
          overflowX: "auto", // Permite desplazamiento horizontal
          width: "100%", // Ocupa el 100% del ancho disponible de su contenedor padre
        }}
      >
        {/* Establecer el ancho del SVG al 88% para que sea responsivo */}
        <svg
          ref={ref}
          width="930px"
          height={dimensions.height}
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          preserveAspectRatio="xMidYMid meet"
        ></svg>
      </div>
      <div
        ref={tooltipRef}
        className="tooltip"
        style={{
          position: "absolute",
          visibility: "hidden",
          backgroundColor: "#238bb0",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
          zIndex: "2",
          boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
          border: "1px solid #ddd",
          textAlign: "center",
        }}
      ></div>
    </>
  );
};

export default BarGraphCircles;
