import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

const LineChartDotsColors = ({ticker, texts, values, above, abovePercents, below, belowPercents}) => {
  
  const obtenerPercent = (dateText, dates, percents) => {
    const index = dates.indexOf(dateText);
    return (percents[index] * 100).toFixed(2) + " %";
  }

  const data = {
    labels: texts, // Etiquetas para el eje X
    datasets: [
      {
        label: ticker,
        data: values, // Datos para el gráfico
        fill: false, // Sin relleno bajo la línea
        borderColor: 'rgba(75, 192, 192, 1)', // Color de la línea
        tension: 0.1, // Curvatura de la línea
        // pointBackgroundColor: [
        //   'red', 
        //   'green', 
        // ], // Colores de los puntos individuales
        pointBackgroundColor: values.map((_, index) => {
          // Define colores solo para puntos específicos
          const date = texts[index];
          if (above.includes(date)) return 'green';
          if (below.includes(date)) return 'red';
          // if (index === 0) return 'red';   // Primer punto
          // if (index === 2) return 'green'; // Tercer punto
          return 'transparent';            // Puntos no visibles
        }),
        // pointBorderColor: 'rgba(75, 192, 192, 1)', // Color del borde de los puntos
        pointBorderColor: values.map((_, index) => {
          const date = texts[index];
          if (above.includes(date) || below.includes(date)) return 'rgba(75, 192, 192, 1)';
          // if (index === 0 || index === 2 || index === 4) return 'rgba(75, 192, 192, 1)';
          return 'transparent';
        }),
        pointRadius: 5, // Tamaño de los puntos
        // pointRadius: values.map((_, index) => {
        //   // Define el tamaño de los puntos para los específicos, y 0 para los demás
        //   if (index === 0 || index === 2 || index === 4) return 5;
        //   return 0;
        // }),
        pointBorderWidth: 2, // Ancho del borde de los puntos
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: '',
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
          autoSkip: false, // Deshabilitar el auto-skip para mostrar todas las etiquetas
        },
        min: 0, // Valor mínimo del eje X
        max: values.length, // Valor máximo del eje X (extiende el eje X)
      },
      y: {
        title: {
          display: true,
          text: '',
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
          callback: function(value) {
            return `${value}`; // Añade el símbolo de dólar a los valores del eje Y
          },
        },
        beginAtZero: true, // Asegura que el eje Y comience en cero
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            return `Date: ${tooltipItems[0].label}`;
          },
          label: function(tooltipItem) {
            // // return `${tooltipItem.raw}`;
            // return `${tooltipItem.label}`;

            if (above.includes(tooltipItem.label)) {
              return "% Change: " + obtenerPercent(tooltipItem.label, above, abovePercents);
            }

            return "% Change: " + obtenerPercent(tooltipItem.label, below, belowPercents);
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo del tooltip
        titleFont: {
          family: "'Arial', sans-serif",
          size: 14,
          weight: 'bold',
          color: '#fff',
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 12,
          color: '#fff',
        },
        displayColors: false, // Oculta la barra de color del dataset en el tooltip
      },
      legend: {
        display: true, // Muestra la leyenda
        labels: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Deshabilita mantener la relación de aspecto para permitir que el gráfico crezca
  };

  return (
    <div style={{ overflowX: 'auto', width: '100%' }}>
      <div style={{ minWidth: `${texts.length * 20}px`, height: '500px' }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default LineChartDotsColors;