import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const colors = [
    "#D97A2D",
    "#007ACC",
    "#20C9C0",
    "#00907C",
    "#9A29C9",
    "#08088A",
    "#D73688",
    "#D4D404",
    "#8B2222",
    "#696969",
    "#00CCCC",
    "#731EBF",
    "#005200",
    "#556B2F",
    "#8B0000",
    "#4682B4",
    "#2F4F4F",
    "#4B0082",
];

const InteractivePieChart = ({ names, values }) => {
  const [selectedSlice, setSelectedSlice] = useState(null);

  const data = {
    labels: names,//["Red", "Blue", "Yellow", "Green", "Purple"],
    datasets: [
      {
        label: "Colors",
        data: values,//[300, 50, 100, 40, 120],
        backgroundColor: colors,
        borderColor: colors,
        // backgroundColor: [
        //   "rgba(255, 99, 132, 0.6)",
        //   "rgba(54, 162, 235, 0.6)",
        //   "rgba(255, 206, 86, 0.6)",
        //   "rgba(75, 192, 192, 0.6)",
        //   "rgba(153, 102, 255, 0.6)",
        // ],
        // borderColor: [
        //   "rgba(255, 99, 132, 1)",
        //   "rgba(54, 162, 235, 1)",
        //   "rgba(255, 206, 86, 1)",
        //   "rgba(75, 192, 192, 1)",
        //   "rgba(153, 102, 255, 1)",
        // ],
        borderWidth: 1,
        hoverOffset: 50, // Desplazamiento al hacer hover
      },
    ],
  };

  const options = {
    cutout: '50%',  
    layout: {
      padding: {
        top: 0,
        bottom: 30, // Espacio inferior para canvas
        left: 0,
        right: 0,
      },
    },
    plugins: {
      datalabels: {
          display: false,
      },
      legend: {
        position: 'top',
        labels: {
          font: {
            family: "'Arial', sans-serif",
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || '';
            return `${label}: ${value}%`;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: {
          family: "'Arial', sans-serif",
          size: 16,
          weight: 'bold',
          color: '#fff',
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 14,
          color: '#fff',
        },
        displayColors: true,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const chart = elements[0];
        const index = chart.index;
        setSelectedSlice(index);
      }
    },
  };

  return (
    // <div style={{ position: 'relative', width: '50%', margin: '0 auto' }}>
    //   <Pie data={data} options={options} />
    //   {selectedSlice !== null && (
    //     <div style={{
    //       position: 'absolute',
    //       top: '50%',
    //       left: '50%',
    //       transform: 'translate(-50%, -50%)',
    //       padding: '10px',
    //       backgroundColor: 'rgba(0, 0, 0, 0.7)',
    //       color: 'white',
    //       borderRadius: '8px',
    //       pointerEvents: 'none',
    //     }}>
    //       Selected: {data.labels[selectedSlice]}
    //     </div>
    //   )}
    // </div>
    <div className="w-full max-w-lg mx-auto">
      <Pie data={data} options={options} />
    </div>
    //   <div className="w-full max-w-lg mx-auto mb-40" style={{ paddingBottom: '50px' }}>
    //   <Pie data={data} options={options} />
    // </div>
  //     <div className="w-full max-w-lg mx-auto mb-40" style={{ height: '500px' }}>
  //   <Pie data={data} options={options} />
  // </div>
  );
};

export default InteractivePieChart;
