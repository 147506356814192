import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DonutChart = ({ names, values }) => {
  const data = {
    labels: names,//["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "Colors",
        data: values,//[12, 19, 3, 5, 2, 3],
        backgroundColor: [
            "#D97A2D",
            "#007ACC",
            "#20C9C0",
            "#00907C",
            "#9A29C9",
            "#08088A",
            "#D73688",
            "#D4D404",
            "#8B2222",
            "#696969",
            "#00CCCC",
            "#731EBF",
            "#005200",
            "#556B2F",
            "#8B0000",
            "#4682B4",
            "#2F4F4F",
            "#4B0082",
        //   "rgba(255, 99, 132, 0.8)",
        //   "rgba(54, 162, 235, 0.8)",
        //   "rgba(255, 206, 86, 0.8)",
        //   "rgba(75, 192, 192, 0.8)",
        //   "rgba(153, 102, 255, 0.8)",
        //   "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
            "#D97A2D",
            "#007ACC",
            "#20C9C0",
            "#00907C",
            "#9A29C9",
            "#08088A",
            "#D73688",
            "#D4D404",
            "#8B2222",
            "#696969",
            "#00CCCC",
            "#731EBF",
            "#005200",
            "#556B2F",
            "#8B0000",
            "#4682B4",
            "#2F4F4F",
            "#4B0082",
        //   "rgba(255, 99, 132, 1)",
        //   "rgba(54, 162, 235, 1)",
        //   "rgba(255, 206, 86, 1)",
        //   "rgba(75, 192, 192, 1)",
        //   "rgba(153, 102, 255, 1)",
        //   "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: '50%', // Ajusta el tamaño del corte central para crear el efecto de donut
    plugins: {
      datalabels: {
        color: "#fff",
        anchor: "center",
        align: "center",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value, context) => {
        //   return `${context.chart.data.labels[context.dataIndex]}: ${value}`;
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}: ${value}%`; 
        },
      },
      legend: {
        display: true,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw || "";
            return `${label}: ${value}%`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  return (
    <div className="w-full max-w-lg mx-auto">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DonutChart;
