import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Tooltip } from "react-tooltip"; // Importar el componente Tooltip desde react-tooltip v5.x
import "./PresidentialTable.css";

const PresidentialTable = ({ data, ticker }) => {
  const days = [
    "30_days",
    "60_days",
    "90_days",
    "120_days",
    "180_days",
    "270_days",
    "360_days",
  ];

  const eleccionesData = [
    {
      Anio: 2020,
      Partido: "Demócrata",
      Presidente: "Donald Trump",
      FedChair: "Jerome Powell",
    },
    {
      Anio: 2016,
      Partido: "Republicano",
      Presidente: "Barack Obama",
      FedChair: "Janet Yellen",
    },
    {
      Anio: 2012,
      Partido: "Demócrata",
      Presidente: "Barack Obama",
      FedChair: "Ben Bernanke",
    },
    {
      Anio: 2008,
      Partido: "Demócrata",
      Presidente: "George W. Bush",
      FedChair: "Ben Bernanke",
    },
    {
      Anio: 2004,
      Partido: "Republicano",
      Presidente: "George W. Bush",
      FedChair: "Alan Greenspan",
    },
    {
      Anio: 2000,
      Partido: "Republicano",
      Presidente: "Bill Clinton",
      FedChair: "Alan Greenspan",
    },
  ];

  const tooltipStyle = {
    backgroundColor: "#438BBA",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "14px",
  };

  return (
    <>
      <table border="1" className="presidential-table">
        <thead>
          <tr>
            <th colSpan={days.length} style={{ textAlign: "center" }}>
              Before
            </th>
            <th>Year</th>
            <th colSpan={days.length} style={{ textAlign: "center" }}>
              After
            </th>
          </tr>
          <tr>
            {days.map((day, index) => (
              <th key={`backward-${index}`}>{day.replace("_", " ")}</th>
            ))}
            <th>Year</th>
            {days.map((day, index) => (
              <th key={`forward-${index}`}>{day.replace("_", " ")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((year, index) => {
            const electionInfo = eleccionesData.find(
              (el) => el.Anio == year.split("-")[0]
            );
            return (
              <tr key={index}>
                {days.map((day) => (
                  <td
                    key={`backward-${year}-${day}`}
                    className={"presidential-table"}
                  >
                    {data[year].backward[day]?.percent_change?.toFixed(2) ??
                      "N/A"}
                    %
                  </td>
                ))}
                <td
                  className={
                    electionInfo.Partido === "Republicano"
                      ? "percentage-change-table-rep"
                      : "percentage-change-table-dem"
                  }
                  data-tooltip-id={`tooltip-${year.split("-")[0]}`}
                  data-tooltip-content={`President: ${electionInfo.Presidente}, \nFed Chair: ${electionInfo.FedChair}`} // Usa data-tooltip-content para el contenido del tooltip
                >
                  {year.split("-")[0]}
                </td>
                {days.map((day) => (
                  <td
                    key={`forward-${year}-${day}`}
                    className={"presidential-table"}
                  >
                    {data[year].forward[day]?.percent_change?.toFixed(2) ??
                      "N/A"}
                    %
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Tooltip component */}
      {Object.keys(data).map((year, index) => (
        <Tooltip
          id={`tooltip-${year.split("-")[0]}`}
          key={index}
          place="top"
          effect="solid"
          style={tooltipStyle}
        />
      ))}
    </>
  );
};

export default PresidentialTable;
