import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
// Assume you've imported your BarChart component as well
import { Tooltip } from "react-tooltip";
import BarGraphCircles from "./BarGraphCircles";
import ScatterChart from "./ScatterChart";
import "./AnnualizedVolatility.css";

const AnnualizedVolatility = ({
  data,
  dataType,
  handleDataChange,
  adjustedVolatilityData,
  closeVolatilityData,
}) => {
  const tableRef = useRef(null);

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      // Create a temporary container for parsing the HTML string
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = data;

      const table = container
        .append("table")
        .attr("class", "annualizedvolatility-table");
      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Array.from(tempContainer.querySelectorAll("th")).map(
        (th) => th.textContent
      );
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d);

      // Append table rows
      const rows = Array.from(tempContainer.querySelectorAll("tr")).slice(1); // Exclude the header row
      tbody
        .selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .html((d) => d.innerHTML);

      return table;
    };
    renderTable(data);
  }, [data]);

  return (
    <div>
      <div className="annualizedvolatility-table-wrapper">
        <div ref={tableRef} />
      </div>

      {/* Render the appropriate BarChart based on dataType */}
      {dataType === "5. adjusted close" && adjustedVolatilityData ? (
        <>
        <BarGraphCircles data={adjustedVolatilityData} />
        <ScatterChart 
          pointsData={adjustedVolatilityData.map((obj) => ({x: obj.label, y: (obj.value * 100).toFixed(3), ticker: obj.label}))} 
          xLabel={""} 
          yLabel={""} />
        </>
      ) : dataType === "4. close" && closeVolatilityData ? (
        <>
        <BarGraphCircles data={closeVolatilityData} />
        <ScatterChart 
          pointsData={closeVolatilityData.map((obj) => ({x: obj.label, y: (obj.value * 100).toFixed(3), ticker: obj.label}))} 
          xLabel={""} 
          yLabel={""} />
        </>
      ) : null}

      <div className="annualizedvolatility-data-type-buttons">
        <button
          className={`annualizedvolatility-data-type-button ${
            dataType === "5. adjusted close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("5. adjusted close")}
        >
          Adjusted Close
        </button>
        <button
          className={`annualizedvolatility-data-type-button ${
            dataType === "4. close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("4. close")}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AnnualizedVolatility;
