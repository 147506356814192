import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ApiUrlStripe } from "../services/restAPI";
import RingLoader from "react-spinners/RingLoader";
import "./MyPlanPopup.css";

const MyPlanPopup = ({ onClose, userSpaceFinance }) => {
  const popupRef = useRef();
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState("");

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        // Fetch current subscription status
        const userName = userSpaceFinance.email || userSpaceFinance.name;  // Assuming you pass the user's email as `userName`
        const subscriptionResponse = await axios.get(`${ApiUrlStripe}/BillingCustomer/CustomerUserStateSuscription/${userName}`);
        const { item1: currentSub } = subscriptionResponse.data;
        setCurrentSubscription(currentSub);

        // Fetch available subscription plans
        const plansResponse = await axios.get(`${ApiUrlStripe}/BillingCustomer/SuscriptionProductsBilling`);
        const { item1: plans } = plansResponse.data;
        setAvailablePlans(plans);

      } catch (error) {
        setLoadError("Failed to load subscription data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [userSpaceFinance]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const getCurrentPlanDetails = () => {
    return availablePlans.find(plan => plan.name === currentSubscription?.suscriptionName);
  };

  const currentPlanDetails = getCurrentPlanDetails();

  return (
    <div
      className="my-plan-popup-overlay"
      onClick={(e) => {
        e.stopPropagation(); // Evitar propagación al overlay
        onClose(); // Cerrar el popup si se hace clic en el overlay
      }}
    >
      <div
        className="my-plan-popup"
        ref={popupRef}
        onClick={(e) => e.stopPropagation()} // Evitar propagación dentro del popup
      >
        {isLoading ? (
          <div className="chatbot-spinner">
            <RingLoader color={"#133588"} loading={isLoading} size={150} />
          </div>
        ) : loadError ? (
          <p>{loadError}</p>
        ) : (
          <>
            <div className="my-plan-header">
              <h2>Upgrade your plan</h2>
              <button onClick={onClose} className="close-btn">
                X
              </button>
            </div>
            <div className="my-plan-content">
              <div className="plan-details">
                <h3>{currentSubscription?.suscriptionName}</h3>
                <p className="plan-price">
                  {currentPlanDetails?.moneyType} {currentPlanDetails?.price}/{currentPlanDetails?.billingPeriod}
                </p>
                <p className="current-plan">Your current plan</p>
                <ul className="plan-features">
                  {currentPlanDetails?.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <div className="plan-actions">
                  <a href="https://billing.stripe.com/p/login/test_fZeaHc4iV7EW2Na145" className="manage-subscription">
                    Manage My Plan
                  </a>
                  {/*<a href="#" className="billing-help">
                    Need help with a billing issue?
                  </a>*/}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyPlanPopup;
