import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Tooltip } from "react-tooltip";
import TreemapChart from "./TreemapChart";
import "./MarketCap.css";

const MarketCap = ({ data, dataType, handleDataChange }) => {
  const tableRef = useRef(null);

  const tooltips = [
    { id: 'Ticker', text: 'Ticker' },
    { id: 'Market Cap', text: 'Total market value based on current price multiplied by outstanding shares or coin supply.' },
    { id: 'Shares Outstanding or Coin Supply', text: 'Number of shares or cryptocurrency tokens currently available.' },
    { id: 'Price', text: 'Current market value of the asset.' }
  ];

  const tooltipStyle = {
    backgroundColor: "#438BBA",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "14px"
  };

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      // Create a temporary container for parsing the HTML string
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = data;

      const table = container.append("table").attr("class", "marketcap");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Array.from(tempContainer.querySelectorAll("th")).map(
        (th) => th.textContent
      );
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d)
        .attr("data-tooltip-id", (d, i) => tooltips[i]?.id)
        .attr("data-tooltip-content", (d, i) => tooltips[i]?.text);

      // Append table rows
      const rows = Array.from(tempContainer.querySelectorAll("tr")).slice(1); // Exclude the header row
      tbody
        .selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .html((d) => d.innerHTML);

      return table;
    };

    renderTable(data);
  }, [data]);

  const dataMatriz = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const data = [];
    const rows = doc.querySelectorAll('tbody tr');

    rows.forEach(row => {
      const ticker = row.cells[0].innerText; 
      const marketCap = row.cells[1].innerText; 
      const coinSupply = row.cells[2].innerText; 
      const price = !isNaN(row.cells[3].innerText) ? parseFloat(row.cells[3].innerText) : 0; 

      data.push({
        ticker,
        marketCap,
        marketCapMount: marketCapConvert(marketCap),
        coinSupply,
        price
      });
    });

    return data;
  };

  const marketCapConvert = (mount) => {
    const arrayMount = mount.split(" ");

    if (!arrayMount[0] || !arrayMount[1]) {
      return 0;
    }

    let newMount = 0;
    switch (arrayMount[1]) {

      case "T":
        newMount = parseFloat(arrayMount[0]) * 1_000_000_000_000;
        break;
  
      case "B":
        newMount = parseFloat(arrayMount[0]) * 1_000_000_000;
        break; 

      case "M":
        newMount = parseFloat(arrayMount[0]) * 1_000_000;
        break; 
        
      default:
        newMount = parseFloat(arrayMount[0]) * 1_000_000;
    }

    return newMount;
  };
  const matriz = dataMatriz(data);

  return (
    <div className="marketcap-container">
      <div ref={tableRef} />
      {tooltips.map((tooltip) => (
        <Tooltip key={tooltip.id} id={tooltip.id} place="top" effect="solid" style={tooltipStyle} />
      ))}
      <TreemapChart treeData={matriz.map(m => ({category: m.ticker, value: m.marketCapMount, valueOriginal: m.marketCap}))} />
      {/*<div className="marketcap-data-type-buttons">
        <button
          className={`marketcap-data-type-button ${
            dataType === "5. adjusted close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("5. adjusted close")}
        >
          Adjusted Close
        </button>
        <button
          className={`marketcap-data-type-button ${
            dataType === "4. close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("4. close")}
        >
          Close
        </button>
        </div>*/}
    </div>
  );
};

export default MarketCap;
