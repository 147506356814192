import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import MultipleBarChartWithSelect from "./MultipleBarChartWithSelect";
import "./CashFlow.css";

const CashFlow = ({ data, handleDataChange }) => {
  const tableRef = useRef(null);
  const [frequency2, setFrequency2] = useState(null); // Set initial state to null
  const [dataFrequency, setDataFrequency] = useState(null);

  useEffect(() => {
    console.log("Data received:", data);
    console.log("Frequency:", frequency2);

    const preferredFrequency2 = data.preferred2 || "quarterly";

    if (frequency2 === null) {
      setFrequency2(preferredFrequency2);
    }

    const currentData =
      frequency2 === "quarterly"
        ? data.quarterly_dataframes2
        : data.annually_dataframes2;

    console.log("Current Data:", currentData);
    setDataFrequency(currentData);

    if (currentData) {
      renderTables(currentData);
    } else {
      d3.select(tableRef.current).selectAll("*").remove();
    }
  }, [data, frequency2]);

  const renderTables = (currentData) => {
    const container = d3.select(tableRef.current);
    container.selectAll("*").remove(); // Clear previous tables

    if (!currentData || Object.keys(currentData).length === 0) {
      container.append("p").text("No data available for selected frequency.");
      return;
    }

    Object.keys(currentData).forEach((ticker) => {
      const tableContainer = container.append("div").attr("class", "table-container");

      // Añadir título del ticker
      tableContainer
        .append("h2")
        .text(ticker)
        .attr("class", "ticker-header");

      const table = container.append("table").attr("class", "cash-flow-table");
      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Object.keys(currentData[ticker][0]); // Assumes data is non-empty
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d);

      // Append table rows
      const rows = currentData[ticker];
      tbody
        .selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .selectAll("td")
        .data((d) => Object.values(d))
        .enter()
        .append("td")
        .text((d) => d);
    });
  };

  return (
    <>
    <div className="cash-flow-container">
      <div ref={tableRef} />
      <div className="cash-flow-data-type-buttons">
        <button
          className={`cash-flow-data-type-button ${
            frequency2 === "quarterly" ? "active" : ""
          }`}
          onClick={() => {
            setFrequency2("quarterly");
            handleDataChange("quarterly");
          }}
        >
          Quarterly
        </button>
        <button
          className={`cash-flow-data-type-button ${
            frequency2 === "annually" ? "active" : ""
          }`}
          onClick={() => {
            setFrequency2("annually");
            handleDataChange("annually");
          }}
        >
          Annually
        </button>
      </div>
    </div>
    {dataFrequency && Object.entries(dataFrequency).map(([key, value]) => (
      <div key={key}>
        <br />
        <h2 className="peratio-title peratio-title-margin" style={{display: "flex"}}>{key}</h2>
        <MultipleBarChartWithSelect 
          ticker={key} 
          optionsSelect={value.map(v => v[key])} 
          values={value} 
          texts={Object.entries(value[0]).filter(([k, v]) => k !== key).map(([k, v]) => k)}
          isQuarterly={(frequency2 === "quarterly")} />
      </div>
    ))}
    </>
  );
};

export default CashFlow;
