import React from "react";
import { Scatter } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const ScatterChart = ({pointsData, xLabel, yLabel}) => {
  const data = {
    datasets: [
      {
        label: "Dataset 1",
        data: pointsData,
        backgroundColor: "rgba(54, 162, 235, 1)",
        pointRadius: 7,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: xLabel,
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
      y: {
        type: "linear",
        title: {
          display: true,
          text: yLabel,
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
          callback: function(value) {
            return `${value}%`; // Añade el símbolo de porcentaje a los valores del eje Y
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            // return `Custom Title: Point ${tooltipItems[0].dataIndex + 1}`;
            return `${tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].ticker}`;
          },
          label: function(tooltipItem) {
            return `${xLabel}: ${tooltipItem.raw.x} \n ${yLabel}: ${tooltipItem.raw.y}%`;
          },
          afterLabel: function(tooltipItem) {
            return '';//'Additional Info: e.g., Some details';
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)", // Fondo del tooltip
        titleFont: {
          family: "'Arial', sans-serif",
          size: 14,
          weight: 'bold',
          color: "#fff",
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 12,
          color: "#fff",
        },
        displayColors: false, // Oculta el color del dataset en el tooltip
      },
      legend: {
        display: false, // Oculta la leyenda de los labels de los datasets
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: '450px' }}>
      <Scatter data={data} options={options} />
    </div>
  );
};

export default ScatterChart;
