// // import React, { useEffect, useState } from "react";
// // import { Line } from "react-chartjs-2";
// // import {
// //   Chart as ChartJS,
// //   LineElement,
// //   PointElement,
// //   LinearScale,
// //   CategoryScale,
// //   Tooltip,
// //   Legend,
// // } from "chart.js";

// // ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend);

// // // const dataExample = {
// // //   NVDA: [
// // //     { Date: "1999-11-01", Price: 0.0449106323178426, Cumulative_Percent_Change: "nan" },
// // //     { Date: "1999-11-02", Price: 0.0477772684232368, Cumulative_Percent_Change: 6.38 },
// // //     { Date: "1999-11-03", Price: 0.0524403298213447, Cumulative_Percent_Change: 9.76 },
// // //   ],
// // //   MCD: [
// // //     { Date: "1999-11-01", Price: 22.42, Cumulative_Percent_Change: "nan" },
// // //     { Date: "1999-11-02", Price: 22.19, Cumulative_Percent_Change: -1.05 },
// // //     { Date: "1999-11-03", Price: 22.55, Cumulative_Percent_Change: 1.67 },
// // //     { Date: "1999-11-04", Price: 24.30, Cumulative_Percent_Change: 7.75 },
// // //     { Date: "1999-11-05", Price: 25.00, Cumulative_Percent_Change: 2.90 },
// // //   ],
// // // };

// // const colors = [
// //   "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
// //   "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
// //   "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
// // ];

// // const electionsData = [
// //   { Anio: 2020, Partido: "Demócrata", Presidente: "Joe Biden", FedChair: "Jerome Powell" },
// //   { Anio: 2016, Partido: "Republicano", Presidente: "Donald Trump", FedChair: "Janet Yellen" },
// //   { Anio: 2012, Partido: "Demócrata", Presidente: "Barack Obama", FedChair: "Ben Bernanke" },
// //   { Anio: 2008, Partido: "Demócrata", Presidente: "George W. Bush", FedChair: "Ben Bernanke" },
// //   { Anio: 2004, Partido: "Republicano", Presidente: "George W. Bush", FedChair: "Alan Greenspan" },
// //   { Anio: 2000, Partido: "Republicano", Presidente: "Bill Clinton", FedChair: "Alan Greenspan" },
// // ];

// // const LineChartMultipleAllStocks = ({dataExample}) => {
// //   const [chartData, setChartData] = useState(null);

// //   useEffect(() => {
// //     const generateChartData = () => {
// //       const datasets = [];
// //       let colorIndex = 0; // Índice de color

// //       // Verificar que dataExample no esté undefined o vacío
// //       if (!dataExample || Object.keys(dataExample).length === 0) {
// //         console.error("dataExample está vacío o no está definido");
// //         return;
// //       }

// //       // Iterar sobre cada ticker en dataExample
// //       Object.keys(dataExample).forEach((ticker) => {
// //         const tickerData = dataExample[ticker];

// //         // Validar si tickerData es un array antes de mapear
// //         if (Array.isArray(tickerData)) {
// //           const dataPoints = tickerData.map((entry) => ({
// //             x: entry.Date,
// //             y: isNaN(entry.Cumulative_Percent_Change) ? 0 : entry.Cumulative_Percent_Change, // Si no es numérico, asignar 0
// //           }));

// //           const color = colors[colorIndex % colors.length]; // Ciclar colores
// //           colorIndex++;

// //           datasets.push({
// //             label: ticker,
// //             data: dataPoints,
// //             borderColor: color,
// //             fill: false,
// //             pointRadius: 0,
// //             pointBorderWidth: 0,
// //             pointBackgroundColor: "transparent",
// //           });
// //         } else {
// //           console.warn(`tickerData para ${ticker} no es un array válido`);
// //         }
// //       });

// //       if (datasets.length > 0) {
// //         setChartData({
// //           datasets: datasets,
// //         });
// //       } else {
// //         console.error("No se generaron datasets válidos.");
// //       }
// //     };

// //     generateChartData();
// //   }, []);

// //   // Evitar renderizar el gráfico si chartData no está disponible
// //   if (!chartData) {
// //     return <div>Cargando datos...</div>;
// //   }

// //   return (
// //     <div style={{ overflowX: 'auto', width: '100%', marginTop: '30px' }}>
// //       {/* <div style={{ minWidth: `${barData.length * 20}px`, height: '500px' }}> */}
// //       <div style={{ minWidth: `30000px`, height: '500px' }}>
// //       <Line
// //         data={chartData}
// //         options={{
// //           scales: {
// //             x: {
// //               type: "category",
// //               title: {
// //                 display: true,
// //                 text: "Date",
// //               },
// //             },
// //             y: {
// //               title: {
// //                 display: true,
// //                 text: "Percent Change",
// //               },
// //             },
// //           },
// //           plugins: {
// //             datalabels: { display: false },
// //           },
// //           responsive: true,
// //           maintainAspectRatio: false,
// //         }}
// //       />
// //       </div>
// //     </div>
// //     // <div>
// //     //   <h2>Line Chart - Multiple</h2>
// //     //   <Line
// //     //     data={chartData}
// //     //     options={{
// //     //       scales: {
// //     //         x: {
// //     //           type: "category",
// //     //           title: {
// //     //             display: true,
// //     //             text: "Date",
// //     //           },
// //     //         },
// //     //         y: {
// //     //           title: {
// //     //             display: true,
// //     //             text: "Percent Change",
// //     //           },
// //     //         },
// //     //       },
// //     //       plugins: {
// //     //         datalabels: { display: false },
// //     //       },
// //     //       responsive: true,
// //     //       maintainAspectRatio: true,
// //     //     }}
// //     //   />
// //     // </div>
// //   );
// // };

// // export default LineChartMultipleAllStocks;









// import React, { useEffect, useState } from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   LinearScale,
//   CategoryScale,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import annotationPlugin from 'chartjs-plugin-annotation';

// ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, annotationPlugin);

// // const dataExample = {
// //   NVDA: [
// //     { Date: "1999-11-01", Price: 0.0449106323178426, Cumulative_Percent_Change: "nan" },
// //     { Date: "1999-11-02", Price: 0.0477772684232368, Cumulative_Percent_Change: 6.38 },
// //     { Date: "1999-11-03", Price: 0.0524403298213447, Cumulative_Percent_Change: 9.76 },
// //   ],
// //   MCD: [
// //     { Date: "1999-11-01", Price: 22.42, Cumulative_Percent_Change: "nan" },
// //     { Date: "1999-11-02", Price: 22.19, Cumulative_Percent_Change: -1.05 },
// //     { Date: "1999-11-03", Price: 22.55, Cumulative_Percent_Change: 1.67 },
// //     { Date: "1999-11-04", Price: 24.30, Cumulative_Percent_Change: 7.75 },
// //     { Date: "1999-11-05", Price: 25.00, Cumulative_Percent_Change: 2.90 },
// //   ],
// // };

// const colors = [
//   "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
//   "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
//   "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
// ];

// const electionsData = [
//   { Anio: 2020, Partido: "Demócrata", Presidente: "Joe Biden", FedChair: "Jerome Powell" },
//   { Anio: 2016, Partido: "Republicano", Presidente: "Donald Trump", FedChair: "Janet Yellen" },
//   { Anio: 2012, Partido: "Demócrata", Presidente: "Barack Obama", FedChair: "Ben Bernanke" },
//   { Anio: 2008, Partido: "Demócrata", Presidente: "George W. Bush", FedChair: "Ben Bernanke" },
//   { Anio: 2004, Partido: "Republicano", Presidente: "George W. Bush", FedChair: "Alan Greenspan" },
//   { Anio: 2000, Partido: "Republicano", Presidente: "Bill Clinton", FedChair: "Alan Greenspan" },
// ];

// const LineChartMultipleAllStocks = ({dataExample}) => {
//   const [chartData, setChartData] = useState(null);

//   useEffect(() => {
//     const generateChartData = () => {
//       const datasets = [];
//       let colorIndex = 0;

//       if (!dataExample || Object.keys(dataExample).length === 0) {
//         console.error("dataExample está vacío o no está definido");
//         return;
//       }

//       Object.keys(dataExample).forEach((ticker) => {
//         const tickerData = dataExample[ticker];

//         if (Array.isArray(tickerData)) {
//           const dataPoints = tickerData.map((entry) => ({
//             x: entry.Date,
//             y: isNaN(entry.Cumulative_Percent_Change) ? 0 : entry.Cumulative_Percent_Change,
//           }));

//           const color = colors[colorIndex % colors.length];
//           colorIndex++;

//           datasets.push({
//             label: ticker,
//             data: dataPoints,
//             borderColor: color,
//             fill: false,
//             pointRadius: 0,
//             pointBorderWidth: 0,
//             pointBackgroundColor: "transparent",
//           });
//         } else {
//           console.warn(`tickerData para ${ticker} no es un array válido`);
//         }
//       });

//       if (datasets.length > 0) {
//         setChartData({
//           datasets: datasets,
//         });
//       } else {
//         console.error("No se generaron datasets válidos.");
//       }
//     };

//     generateChartData();
//   }, []);

//   // Crear las anotaciones de franjas de elecciones
//   const generateAnnotations = () => {
//     const annotations = {};

//     // Buscar las fechas que coincidan con noviembre de un año electoral
//     Object.keys(dataExample).forEach((ticker) => {
//       const tickerData = dataExample[ticker];
//       tickerData.forEach((entry) => {
//         const date = new Date(entry.Date);
//         const year = date.getFullYear();
//         const month = date.getMonth() + 1; // Los meses en JS son 0-11

//         // Si el año coincide con un año electoral y el mes es noviembre (mes 11)
//         if (electionsData.some(election => election.Anio === year) && month === 11) {
//           const startDate = `${year}-11-01`;
//           const endDate = `${year}-11-30`;

//           annotations[`election-${year}`] = {
//             type: 'box',
//             xScaleID: 'x',
//             yScaleID: 'y',
//             xMin: startDate,
//             xMax: endDate,
//             backgroundColor: 'rgba(128, 128, 128, 0.2)', // Color de la franja
//             borderColor: 'rgba(128, 128, 128, 1)',
//             borderWidth: 1,
//           };
//         }
//       });
//     });

//     return annotations;
//   };
//   console.log("DATAVIENE: ", dataExample);
//   console.log("ANNOTATIONSSS: ", generateAnnotations());
//   if (!chartData) {
//     return <div>Cargando datos...</div>;
//   }

//   return (
//     <div style={{ overflowX: 'auto', width: '100%', marginTop: '30px' }}>
//       <div style={{ minWidth: `30000px`, height: '500px' }}>
//         <Line
//           data={chartData}
//           options={{
//             scales: {
//               x: {
//                 type: "category",
//                 title: {
//                   display: true,
//                   text: "Date",
//                 },
//               },
//               y: {
//                 title: {
//                   display: true,
//                   text: "Percent Change",
//                 },
//               },
//             },
//             plugins: {
//               datalabels: { 
//                 display: false 
//               },
//               annotation: {
//                 annotations: generateAnnotations(), // Agregar las franjas de elecciones
//               },
//             },
//             responsive: true,
//             maintainAspectRatio: false,
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default LineChartMultipleAllStocks;





import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, annotationPlugin);

// const dataExample = {
//   NVDA: [
//     { Date: "1999-11-01", Price: 0.0449106323178426, Cumulative_Percent_Change: "nan" },
//     { Date: "1999-11-02", Price: 0.0477772684232368, Cumulative_Percent_Change: 6.38 },
//     { Date: "1999-11-03", Price: 0.0524403298213447, Cumulative_Percent_Change: 9.76 },
//   ],
//   MCD: [
//     { Date: "1999-11-01", Price: 22.42, Cumulative_Percent_Change: "nan" },
//     { Date: "1999-11-02", Price: 22.19, Cumulative_Percent_Change: -1.05 },
//     { Date: "1999-11-03", Price: 22.55, Cumulative_Percent_Change: 1.67 },
//     { Date: "1999-11-04", Price: 24.30, Cumulative_Percent_Change: 7.75 },
//     { Date: "1999-11-05", Price: 25.00, Cumulative_Percent_Change: 2.90 },
//   ],
// };

const colors = [
  "#D97A2D", "#007ACC", "#20C9C0", "#00907C", "#9A29C9", "#08088A",
  "#D73688", "#D4D404", "#8B2222", "#696969", "#00CCCC", "#731EBF",
  "#005200", "#556B2F", "#8B0000", "#4682B4", "#2F4F4F", "#4B0082",
];

const electionsData = [
  { Anio: 2020, Partido: "Demócrata", Presidente: "Joe Biden", FedChair: "Jerome Powell" },
  { Anio: 2016, Partido: "Republicano", Presidente: "Donald Trump", FedChair: "Janet Yellen" },
  { Anio: 2012, Partido: "Demócrata", Presidente: "Barack Obama", FedChair: "Ben Bernanke" },
  { Anio: 2008, Partido: "Demócrata", Presidente: "George W. Bush", FedChair: "Ben Bernanke" },
  { Anio: 2004, Partido: "Republicano", Presidente: "George W. Bush", FedChair: "Alan Greenspan" },
  { Anio: 2000, Partido: "Republicano", Presidente: "Bill Clinton", FedChair: "Alan Greenspan" },
];

const LineChartMultipleAllStocks = ({dataExample}) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const generateChartData = () => {
      const datasets = [];
      let colorIndex = 0;

      if (!dataExample || Object.keys(dataExample).length === 0) {
        console.error("dataExample está vacío o no está definido");
        return;
      }

      Object.keys(dataExample).forEach((ticker) => {
        const tickerData = dataExample[ticker];

        if (Array.isArray(tickerData)) {
          const dataPoints = tickerData.map((entry) => ({
            x: entry.Date,
            y: isNaN(entry.Cumulative_Percent_Change) ? 0 : entry.Cumulative_Percent_Change,
          }));

          const color = colors[colorIndex % colors.length];
          colorIndex++;

          datasets.push({
            label: ticker,
            data: dataPoints,
            borderColor: color,
            fill: false,
            pointRadius: 0,
            pointBorderWidth: 0,
            pointBackgroundColor: "transparent",
          });
        } else {
          console.warn(`tickerData para ${ticker} no es un array válido`);
        }
      });

      if (datasets.length > 0) {
        setChartData({
          datasets: datasets,
        });
      } else {
        console.error("No se generaron datasets válidos.");
      }
    };

    generateChartData();
  }, []);

  // Crear las anotaciones de franjas de elecciones
  const generateAnnotations = () => {
    const annotations = {};

    // Recorrer los datos de ejemplo
    Object.keys(dataExample).forEach((ticker) => {
      const tickerData = dataExample[ticker];

      // Iterar por cada entrada de dataExample
      electionsData.forEach((election) => {
        const novemberDates = tickerData
          .map((entry) => new Date(entry.Date))
          .filter((date) => date.getFullYear() === election.Anio && date.getMonth() + 1 === 11); // Mes de noviembre es 11

        // Si hay al menos una fecha en noviembre
        if (novemberDates.length > 0) {
          const startDate = novemberDates.reduce((min, curr) => curr < min ? curr : min); // Fecha menor de noviembre
          const endDate = novemberDates.reduce((max, curr) => curr > max ? curr : max);   // Fecha mayor de noviembre

          // Formatear fechas a "YYYY-MM-DD"
          const formattedStartDate = startDate.toISOString().split("T")[0];
          const formattedEndDate = endDate.toISOString().split("T")[0];

          // Crear la anotación para esa franja
          annotations[`election-${election.Anio}`] = {
            type: 'box',
            xScaleID: 'x',
            yScaleID: 'y',
            xMin: formattedStartDate,
            xMax: formattedEndDate,
            backgroundColor: 'rgba(128, 128, 128, 0.2)', // Color de la franja
            borderColor: 'rgba(128, 128, 128, 1)',
            borderWidth: 1,
          };
        }
      });
    });

    return annotations;
  };
  console.log("DATAVIENE: ", dataExample);
  console.log("ANNOTATIONSSS: ", generateAnnotations());
  if (!chartData) {
    return <div>Cargando datos...</div>;
  }

  return (
    <div style={{ overflowX: 'auto', width: '100%', marginTop: '30px' }}>
      <div style={{ minWidth: `30000px`, height: '500px' }}>
        <Line
          data={chartData}
          options={{
            scales: {
              x: {
                type: "category",
                title: {
                  display: true,
                  text: "Date",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Percent Change",
                },
              },
            },
            plugins: {
              datalabels: { 
                display: false 
              },
              annotation: {
                annotations: generateAnnotations(), // Agregar las franjas de elecciones
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
    </div>
  );
};

export default LineChartMultipleAllStocks;
