import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";
import { faL } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  BarController
);

const TrendsBarChartWithSelect = ({
  data,
  selectedYears,
  selectedMonths,
  selectedWeeks,
  selectedDays,
  groupByYear,
  colorDictionary,
}) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    let labels = [];
    if (groupByYear) {
      labels = ["Selected Years"];
    } else if (selectedWeeks.length > 0) {
      labels = selectedWeeks;
    } else if (selectedDays.length > 0) {
      labels = selectedDays
        .map((day) => `Day ${parseInt(day, 10)}`) // Eliminar ceros iniciales
        .sort((a, b) => parseInt(a.split(' ')[1], 10) - parseInt(b.split(' ')[1], 10)); // Ordenar numéricamente
    } else if (selectedMonths.length > 0) {
      labels = selectedMonths.map((month) => month.slice(0, 3));
    }

    const datasets = selectedYears.flatMap((year) => {
      const yearData = data.filter((d) => d.Date.includes(year));
      const groupedByTicker = yearData.reduce((acc, curr) => {
        if (!acc[curr.Ticker]) acc[curr.Ticker] = Array(labels.length).fill(0);

        const percentChangeRaw =
          typeof curr["% Change"] === "string"
            ? curr["% Change"].replace("%", "")
            : curr["% Change"];
        const percentChange = !isNaN(parseFloat(percentChangeRaw))
          ? parseFloat(percentChangeRaw) * 100
          : 0;

        if (groupByYear) {
          acc[curr.Ticker][0] += percentChange;
        }
        if (selectedDays.length > 0) {
          const dayMatch = curr.Date.match(/(\d{4})-(\d{2})-(\d{2})/);

          if (dayMatch) {
              const [fullDate, year, month, day] = dayMatch;
              const parsedDay = parseInt(day, 10); // Eliminar ceros iniciales
              //console.log(`Día capturado sin ceros: ${parsedDay}`);
      
              const dayIndex = labels.indexOf(`Day ${parsedDay}`);
              if (dayIndex !== -1) {
                  //console.log(`Asignando valor de %Change para el día ${parsedDay}`);
                  acc[curr.Ticker][dayIndex] += percentChange;
              } else {
                  console.log(`No se encontró el índice del día: ${parsedDay} en las etiquetas.`);
              }
          } else {
              console.log(`No se pudo capturar la fecha correctamente para: ${curr.Date}`);
          }
        } else if (selectedWeeks.length > 0) {
          const weekMatch = curr.Date.match(/\((Week \d+)/);
          if (weekMatch) {
            const weekIndex = labels.indexOf(weekMatch[1]);
            if (weekIndex !== -1) {
              acc[curr.Ticker][weekIndex] += percentChange;
            }
          }
        } else if (selectedMonths.length > 0) {
          const monthMatch = curr.Date.match(/\(([A-Za-z]+)/);
          if (monthMatch) {
            const monthIndex = labels.indexOf(monthMatch[1].slice(0, 3));
            if (monthIndex !== -1) {
              acc[curr.Ticker][monthIndex] += percentChange;
            }
          }
        }
        return acc;
      }, {});

      return Object.keys(groupedByTicker).map((ticker) => ({
        label: `${ticker} (${year})`,        
        data: groupedByTicker[ticker],
        backgroundColor: colorDictionary[year],
        borderColor: colorDictionary[year],
        borderWidth: 1,
      }));
    });

    setChartData({ labels, datasets });
    //console.log("chartData antes de renderizar la gráfica:", chartData);
  }, [
    data,
    selectedYears,
    selectedMonths,
    selectedDays,
    selectedWeeks,
    groupByYear,
    colorDictionary,
  ]);

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: groupByYear
            ? "Years"
            : selectedWeeks.length > 0
            ? "Weeks"
            : "Months",
          font: {
            family: "'Inter', sans-serif",
            size: 12,
            weight: "bold",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "% Change",
          font: {
            family: "'Inter', sans-serif",
            size: 12,
            weight: "bold",
          },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const matchingData = data.filter((d) => {
              const percentChangeRaw =
                typeof d["% Change"] === "string"
                  ? d["% Change"].replace("%", "")
                  : d["% Change"];
              const percentChange = !isNaN(parseFloat(percentChangeRaw))
                ? parseFloat(percentChangeRaw) * 100
                : 0;
              const year = d.Date.split("-")[0];
          
              if (groupByYear) {
                return (
                  Math.abs(percentChange - tooltipItem.raw) < 0.01 &&
                  selectedYears.includes(year)
                );
              } else if (selectedWeeks.length > 0) {
                const weekMatch = d.Date.match(/\((Week \d+)/);
                return (
                  Math.abs(percentChange - tooltipItem.raw) < 0.01 &&
                  weekMatch &&
                  selectedWeeks.includes(weekMatch[1]) &&
                  tooltipItem.label === weekMatch[1]
                );
              } else if (selectedDays.length > 0) {
                const dayMatch = d.Date.match(/\d{4}-(\d{2})-(\d{2})/);
                if (dayMatch) {
                  const parsedDay = parseInt(dayMatch[2], 10); // Eliminar ceros iniciales
                  const dayLabel = `Day ${parsedDay}`;
                  return tooltipItem.label === dayLabel;
                }
                return false;
              } else if (selectedMonths.length > 0) {
                const monthMatch = d.Date.match(/\(([A-Za-z]+)/);
                return (
                  Math.abs(percentChange - tooltipItem.raw) < 0.01 &&
                  monthMatch &&
                  selectedMonths.includes(monthMatch[1])
                );
              }
              return false;
            });
          
            if (matchingData.length > 0) {
              return `Ticker: ${matchingData[0].Ticker}`;
            }
            return "No data";
          },          
          afterLabel: function (tooltipItem) {
            const matchingData = data.find((d) => {
              const percentChangeRaw =
                typeof d["% Change"] === "string"
                  ? d["% Change"].replace("%", "")
                  : d["% Change"];
              const percentChange = !isNaN(parseFloat(percentChangeRaw))
                ? parseFloat(percentChangeRaw) * 100
                : 0;
              const year = d.Date.split("-")[0];
          
              if (groupByYear) {
                return (
                  Math.abs(percentChange - tooltipItem.raw) < 0.01 &&
                  selectedYears.includes(year)
                );
              } else if (selectedWeeks.length > 0) {
                const weekMatch = d.Date.match(/\((Week \d+)/);
                return (
                  Math.abs(percentChange - tooltipItem.raw) < 0.01 &&
                  weekMatch &&
                  selectedWeeks.includes(weekMatch[1]) &&
                  tooltipItem.label === weekMatch[1]
                );
              } else if (selectedDays.length > 0) {
                const dayMatch = d.Date.match(/\d{4}-(\d{2})-(\d{2})/);
                if (dayMatch) {
                  const parsedDay = parseInt(dayMatch[2], 10); // Eliminar ceros iniciales
                  const dayLabel = `Day ${parsedDay}`;
                  return tooltipItem.label === dayLabel;
                }
                return false;
              } else if (selectedMonths.length > 0) {
                const monthMatch = d.Date.match(/\(([A-Za-z]+)/);
                return (
                  Math.abs(percentChange - tooltipItem.raw) < 0.01 &&
                  monthMatch &&
                  selectedMonths.includes(monthMatch[1])
                );
              }
              return false;
            });
          
            if (matchingData) {
              const [startDate, endDateWithRest] = matchingData.Date.split(" to ");
              const endDate = endDateWithRest ? endDateWithRest.split(" ")[0] : startDate;
          
              const startPrice =
                selectedDays.length > 0
                  ? "N/A" // Para días no mostramos Start Price
                  : matchingData["Start Price"] !== undefined
                  ? matchingData["Start Price"].toFixed(2)
                  : "N/A";
          
              const endPrice =
                selectedDays.length > 0 && matchingData["Price"] !== undefined
                  ? matchingData["Price"].toFixed(2) // Para días, usamos Price como End Price
                  : matchingData["End Price"] !== undefined
                  ? matchingData["End Price"].toFixed(2)
                  : "N/A";
          
              const change =
                tooltipItem.raw !== undefined ? tooltipItem.raw.toFixed(2) : "N/A";
          
              return [
                `Year: ${matchingData.Date.split("-")[0]}`,
                `Start Date: ${startDate}`,
                `End Date: ${endDate}`,
                `Start Price: ${startPrice}`, // Mantener Start Price para años, meses y semanas
                `End Price: ${endPrice}`, // Usar Price como End Price para días
                `% Change: ${change}%`,
              ];
            }
            return ["No data available"];
          },                  
        },
      },

      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            family: "'Inter', sans-serif",
            size: 10,
          },
        },
      },
      datalabels: {
        display: function (context) {
          // Verificar si la cantidad de años, meses, semanas o días seleccionados es mayor a 5
          const isTooManySelections = 
            selectedYears.length > 5 ||
            selectedMonths.length > 5 ||
            selectedWeeks.length > 5 ||
            selectedDays.length > 5;
      
          // Mostrar los datalabels solo si el valor no es 0 y no hay más de 5 selecciones
          const value = context.dataset.data[context.dataIndex];
          return value !== 0 && !isTooManySelections;
        },
        align: "end",
        anchor: "end",
        formatter: function (value, context) {
          const datasetIndex = context.datasetIndex;
          const dataset = context.chart.data.datasets[datasetIndex];
          const tickerWithYear = dataset.label;
          
          // Eliminar el año entre paréntesis del label
          const ticker = tickerWithYear.replace(/\s\(\d{4}\)/, ""); // Remueve el año entre paréntesis
          
          // Verifica la cantidad de años, meses, semanas o días seleccionados
          if (
            selectedYears.length > 3 ||
            selectedMonths.length > 3 ||
            selectedWeeks.length > 3 ||
            selectedDays.length > 3
          ) {
            // Si hay más de 3 años, 4 meses, 4 semanas o 4 días seleccionados
            const formattedTicker = ticker.split("").join("\n"); // Mostrar con saltos de línea
            return formattedTicker;
          }           
          else {
            // Mostrar el formato original sin año
            return ticker;
          }
        },
        font: {
          family: "'Inter', sans-serif",
          size: selectedYears.length > 3 ||
                selectedMonths.length > 3 ||
                selectedWeeks.length > 3 ||
                selectedDays.length > 3 ? 7 : 8, // Cambiar tamaño de fuente según selección
        },
        color: "#000",
      },           
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        height: "500px",
        width: "100%",
        overflow: "auto",
        scrollbarWidth: "thin",
      }}
    >
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TrendsBarChartWithSelect;
