import React from "react";
import AboveBelowTable from "./AboveBelowTable";
import BarGraph3 from "./BarGraph3"; // Import your BarGraph component
import MonthAboveBelow from "./MonthAboveBelow"; // Import your BarGraph component
import AboveBelowDataFrames from "./AboveBelowDataFrames"; // Import your BarGraph component
//import WeekDayAboveBelowProb from './WeekDayAboveBelowProb';// Import your BarGraph component
//import MonthAboveBelowProb from './MonthAboveBelowProb';// Import your BarGraph component
import "./Times.css";
import AboveBelowTableSection from "./AboveBelowTableSection";
import LineChartDotsColors from "./LineChartDotsColors";
import LineChartLinear from "./LineChartLinear"

// ... your imports ...

function Times(props) {
  const { dataInfo, data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11 } =
    props;
    console.log("INFOOOO 10: ", data10)
    console.log("INFOOOO 11: ", data11)
    console.log("INFOOOO  4: ", data4)
  const tickersSet = new Set([
    ...Object.keys(data2 || {}),
    ...Object.keys(data3 || {}),
    ...Object.keys(data4 || {}),
    ...Object.keys(data5 || {}),
    ...Object.keys(data6 || {}),
  ]);

  const uniqueTickers = [...tickersSet];

  // Filtrar tickers que tienen data para AboveBelowTable
  const tickersWithAboveBelowTable = uniqueTickers.filter(
    (ticker) => (data1 && data1[ticker]) || (data6 && data6[ticker])
  );

  // Verificar si hay datos en data1 o data6
  const data1Length = Object.keys(data1 || {}).length;
  const data6Length = Object.keys(data6 || {}).length;

  // Condición modificada para mostrar AboveBelowTableSection
  const shouldShowAboveBelowTableSection =
    data1Length + data6Length > 1 &&
    !(data1Length === 1 && data6Length === 0) &&
    !(data1Length === 0 && data6Length === 1);

  const aboveStyle = { color: '#47C714' };
  const belowStyle = { color: '#F94040' };

  const formatAboveBelowDate = (date) => {
    const splitDateIni = date?.split(" to ");
    const splitDateIniSplit = splitDateIni[0]?.split("-");
    
    const splitDateEnd = splitDateIni[1]?.split(" ");
    const splitDateEndSplit = splitDateEnd[0]?.split("-");

    return {
      // ini: new Date(splitDateIni[0]),
      // end: new Date(splitDateEnd[0]),
      ini: new Date(Date.UTC(splitDateIniSplit[0], (parseInt(splitDateIniSplit[1]) - 1), splitDateIniSplit[2], 12, 0, 0)),
      end: new Date(Date.UTC(splitDateEndSplit[0], (parseInt(splitDateEndSplit[1]) - 1), splitDateEndSplit[2], 12, 0, 0)),
      extra: splitDateEnd[1]
    };
  };

  // const accumulateValues = (arrayValues) => {
  //   const result = arrayValues.reduce((acc, curr, index) => {
  //     acc.push((1 + curr) * (index === 0 ? 1 : acc[index - 1]) - 1);
  //     return acc;
  //   }, []);

  //   return result;
  // };

  const accumulateValues = (arrayValues) => {
    const result = arrayValues.reduce((acc, curr, index) => {
      acc.push((1 + curr) * (index === 0 ? 1 : acc[index - 1]));
      return acc;
    }, []);
    return result;
  };

  return (
    <div className="times-table">
      <h2></h2>

      {/* Renderiza la sección de la tabla utilizando la clase AboveBelowTableSection */}
      {shouldShowAboveBelowTableSection && (
        <AboveBelowTableSection
          tickersWithAboveBelowTable={tickersWithAboveBelowTable}
          data1={data1}
          data6={data6}
        />
      )}

      {uniqueTickers.map((ticker) => (
        <div className="ticker-section" key={ticker}>
          {data1 && data1[ticker] && (
            <div className="times-section">
              <h3>Times that happend {ticker}</h3> {/* Title for new table */}
              <AboveBelowTable data={data1[ticker]} />
            </div>
          )}
          {data6 && data6[ticker] && (
            <div className="times-section">
              <h3>Times that happend {ticker}</h3> {/* Title for new table */}
              <AboveBelowTable data={data6[ticker]} />
            </div>
          )}

          {(data2 && data2[ticker]) ||
          (data3 && data3[ticker]) ||
          (data4 && data4[ticker]) ||
          (data5 && data5[ticker]) ? (
            <h3>Data for {ticker}</h3>
          ) : null}

          {data2 && data2[ticker] && (
            <div className="times-section">              
              <MonthAboveBelow data={{ [ticker]: data2[ticker] }} />
            </div>
          )}

          {data7 && data7[ticker] && (
            <div className="times-section">
              <h4><span style={aboveStyle}>Above</span> and <span style={belowStyle}>Below</span> Days - {ticker}</h4>
              <MonthAboveBelow data={{ [ticker]: data7[ticker] }} />
            </div>
          )}

          {data3 && data3[ticker] && (
            <div className="times-section">
              <h4></h4>
              <BarGraph3 data={{ [ticker]: data3[ticker] }} />
            </div>
          )}

          {data9 && data9[ticker] && (
            <div className="times-section">
              <BarGraph3 data={{ [ticker]: data9[ticker] }} isCrypto={true} />
            </div>
          )}

          {data4 && data4[ticker] && (
            <div className="times-section">
              <h3><span style={aboveStyle}>Above</span> and <span style={belowStyle}>Below</span> Days - {ticker}</h3>
              <AboveBelowDataFrames data={{ [ticker]: data4[ticker] }} />
            </div>
          )}

          {data8 && data8[ticker] && (
            <div className="times-section">
              <h4><span style={aboveStyle}>Above</span> and <span style={belowStyle}>Below</span> Days - {ticker}</h4>
              <AboveBelowDataFrames
                data={{ [ticker]: data8[ticker] }}
                isCrypto={true}
              />
            </div>
          )}

          {data10 && data10[ticker] && data4 && data4[ticker] && (
            <div className="times-section">
              {dataInfo.days == 1 && !dataInfo.monthly && !dataInfo.weekly ?
              <LineChartDotsColors
                ticker={ticker}
                texts={Object.keys(data10[ticker]).map(text => text?.split(" ")[0] || '')}
                values={Object.values(data10[ticker]).map(valor => valor === "nan" ? 0 : parseFloat(valor))}
                above={data4[ticker].above_days_df.map(ab => ab.Date)}
                abovePercents={data4[ticker].above_days_df.map(ab => ab["% Change"])}
                below={data4[ticker].below_days_df.map(be => be.Date)}
                belowPercents={data4[ticker].below_days_df.map(be => be["% Change"])}
              />
              :
              <LineChartLinear
                ticker={ticker}
                texts={Object.keys(data10[ticker]).map(text => text?.split(" ")[0] || '')}
                values={Object.values(data10[ticker]).map(valor => valor === "nan" ? 0 : parseFloat(valor))}
                // values={accumulateValues(Object.values(data10[ticker]).map(valor => valor === "nan" ? 0 : parseFloat(valor)))}
                above={data4[ticker].above_days_df.map(ab => formatAboveBelowDate(ab.Date))}
                abovePercents={data4[ticker].above_days_df.map(ab => ab["% Change"])}
                below={data4[ticker].below_days_df.map(be => formatAboveBelowDate(be.Date))}
                belowPercents={data4[ticker].below_days_df.map(be => be["% Change"])}
               />
              }
            </div>
          )}

          {data11 && data11[ticker] && data8 && data8[ticker] && (
            <div className="times-section">
              {dataInfo.days == 1 && !dataInfo.monthly && !dataInfo.weekly ?
              <LineChartDotsColors
                ticker={ticker}
                texts={Object.keys(data11[ticker]).map(text => text?.split(" ")[0] || '')}
                values={Object.values(data11[ticker]).map(valor => valor === "nan" ? 0 : parseFloat(valor))}
                above={data8[ticker].above_days_df1.map(ab => ab.Date)}
                abovePercents={data8[ticker].above_days_df1.map(ab => ab["% Change"])}
                below={data8[ticker].below_days_df1.map(be => be.Date)}
                belowPercents={data8[ticker].below_days_df1.map(be => be["% Change"])}
              />
              :
              <LineChartLinear 
                ticker={ticker}
                texts={Object.keys(data11[ticker]).map(text => text?.split(" ")[0] || '')}
                values={Object.values(data11[ticker]).map(valor => valor === "nan" ? 0 : parseFloat(valor))}
                // values={accumulateValues(Object.values(data11[ticker]).map(valor => valor === "nan" ? 0 : parseFloat(valor)))}
                above={data8[ticker].above_days_df1.map(ab => formatAboveBelowDate(ab.Date))}
                abovePercents={data8[ticker].above_days_df1.map(ab => ab["% Change"])}
                below={data8[ticker].below_days_df1.map(be => formatAboveBelowDate(be.Date))}
                belowPercents={data8[ticker].below_days_df1.map(be => be["% Change"])}
              />
              }
            </div>
          )}

          
        </div>
      ))}
    </div>
  );
}
export default Times;
