import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import LineeChart from "./LineeChart";
import "./HistoricalPrices.css";

const HistoricalPrices = ({ dataString, cryptoData }) => {
  const tableRef = useRef(null);
  const stockTableRef = useRef(null);
  const cryptoTableRef = useRef(null);
  const [groupedTotalData, setGroupedTotalData] = useState({});

  useEffect(() => {
  const parseData = (dataStr) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(dataStr, "text/html");
    const headers = Array.from(htmlDoc.querySelectorAll("th")).map(
      (th) => th.textContent
    );
    const rows = Array.from(htmlDoc.querySelectorAll("tbody tr")).map((tr) =>
      Array.from(tr.querySelectorAll("td")).map((td) => td.textContent)
    );

    return { headers, rows };
  };

    // Function to render table
    const renderTable = (data, headers, containerRef) => {
      const container = d3.select(containerRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      const table = container
        .append("table")
        .attr("class", "historical-prices-table");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d);

      // Append table rows
      tbody
        .selectAll("tr")
        .data(data)
        .enter()
        .append("tr")
        .selectAll("td")
        .data((d) => d)
        .enter()
        .append("td")
        .text((d) => d);
    };

    const infoAggregator = (data) => {
      const variable = data.reduce((acc, row) => {
        const name = row[0];
        
        if (name === "------") {
          return acc; 
        }
        if (!acc[name]) {
          acc[name] = [];  // Si no existe una clave para este nombre, la creamos con un array vacío.
        }
        acc[name].push(row);  // Añadimos la fila al array correspondiente a este nombre.
        return acc;
      }, {}); 
      return variable;
    };
    
    let groupedData = [];
    let groupedCryptoData = [];
    // let InfoAggregator = {};
    // Render stock data
    if (dataString) {
      const { headers: stockHeaders, rows: stockRows } = parseData(dataString);
      renderTable(stockRows, stockHeaders, stockTableRef);
      groupedData = infoAggregator(stockRows.map((obj) => ([obj[0], obj[1], obj[6]])));
    }

    // Render crypto data
    if (cryptoData) {
      const { headers: cryptoHeaders, rows: cryptoRows } =
        parseData(cryptoData);
      renderTable(cryptoRows, cryptoHeaders, cryptoTableRef);
      groupedCryptoData = infoAggregator(cryptoRows.map((obj) => ([obj[0], obj[1], obj[3]])));
    }

    setGroupedTotalData({...groupedData, ...groupedCryptoData});
    
  }, [dataString, cryptoData]);

  return (
    <>
      <div ref={stockTableRef} />
      <div ref={cryptoTableRef} />
      {
        Object.entries(groupedTotalData).map(([key, val]) => (
        <>
        {/* <h3 style={{textAlign: "center"}}>{val[0][0]}</h3> */}
        <LineeChart lineData={{date: val.map((obj, index) => ([obj[1]])), value: val.map((obj, index) => (parseFloat([obj[2]])))}} ticker={val[0][0]} />
          </>
        ))
      }
    </>
  );
};

export default HistoricalPrices;
