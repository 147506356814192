import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Tooltip } from "react-tooltip";
import BarGraphCircles from "../tickerAnnualizedVolatility/BarGraphCircles";
// import ScatterPlot from "./ScatterPlotGraph";
import ScatterChart from "./ScatterChart";
import HorizontalBarChart from "./HorizontalBarChart";
import "./PERatio.css";

const PERatio = ({ data, dataType, handleDataChange, adjustedVolatilityData }) => {
  const tableRef = useRef(null);

  const tooltips = [
    { id: 'Ticker', text: 'Ticker' },
    { id: 'PERatio', text: 'Price-to-earnings ratio.' },
    { id: 'ForwardPE', text: 'Forward PE ratio.' },
    { id: 'DividendYield', text: 'Dividend yield.' }
  ];

  const tooltipStyle = {
    backgroundColor: "#438BBA",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "14px"
  };

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      // Create a temporary container for parsing the HTML string
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = data;

      const table = container.append("table").attr("class", "peratio");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Array.from(tempContainer.querySelectorAll("th")).map(
        (th) => th.textContent
      );
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d)
        .attr("data-tooltip-id", (d, i) => tooltips[i]?.id)
        .attr("data-tooltip-content", (d, i) => tooltips[i]?.text);

      // Append table rows
      const rows = Array.from(tempContainer.querySelectorAll("tr")).slice(1); // Exclude the header row
      tbody
        .selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .html((d) => d.innerHTML);

      return table;
    };

    renderTable(data);
  }, [data]);
  
  const dataMatriz = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const data = [];
    const rows = doc.querySelectorAll('tbody tr');

    rows.forEach(row => {
      const ticker = row.cells[0].innerText; // P/E Ratio está en la columna 1
      const peRatio = !isNaN(row.cells[1].innerText) ? parseFloat(row.cells[1].innerText) : 0; // P/E Ratio está en la columna 1
      const forwardPeRatio = !isNaN(row.cells[2].innerText) ? parseFloat(row.cells[2].innerText) : 0; // P/E Ratio está en la columna 1
      const dividendYield = !isNaN(row.cells[3].innerText.replace("%", "")) ? parseFloat(row.cells[3].innerText.replace("%", "")) : 0; // Dividend Yield está en la columna 3

      data.push({
        ticker,
        peRatio,
        forwardPeRatio,
        dividendYield
      });
    });
    return data;
  };
  const matriz = dataMatriz(data);

  return (
    <div className="peratio-container">
      <div ref={tableRef}   />
      {tooltips.map((tooltip) => (
        <Tooltip key={tooltip.id} id={tooltip.id} place="top" effect="solid" style={tooltipStyle} />
      ))}
        <h2 className="peratio-title peratio-title-margin">Dividend Yield</h2>
        <div className="p-4">
          <ScatterChart 
            pointsData={matriz.map((obj) => ({x: obj.peRatio, y: obj.dividendYield, ticker: obj.ticker}))} 
            xLabel={"P/E Ratio"} 
            yLabel={"Dividend Yield"} />
        </div>
        
        <h2 className="peratio-title peratio-title-margin">Forward P/E Ratio</h2>
        <HorizontalBarChart barData={matriz.map((obj) => ({name: obj.ticker, value: obj.forwardPeRatio}))} />
      {/*<div className="peratio-data-type-buttons">
        <button
          className={`peratio-data-type-button ${
            dataType === "5. adjusted close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("5. adjusted close")}
        >
          Adjusted Close
        </button>
        <button
          className={`peratio-data-type-button ${
            dataType === "4. close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("4. close")}
        >
          Close
        </button>
        </div>*/}
    </div>
  );
};

export default PERatio;
