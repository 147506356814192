import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, Tooltip, Legend);

const LineeChart = ({lineData, ticker}) => {
  const data = {
    labels: lineData.date,// Etiquetas para el eje X
    datasets: [
      {
        label: ticker,
        data: lineData.value,// Datos para el gráfico
        fill: false, // Sin relleno bajo la línea
        borderColor: 'rgba(75, 192, 192, 1)', // Color de la línea
        // tension: 0.9, // Curvatura de la línea
        pointRadius: 1, // Ocultar los puntos
        pointHoverRadius: 5, // Mostrar los puntos al hacer hover
        pointBackgroundColor: 'rgba(75, 192, 192, 1)', // Color de fondo del punto
        pointBorderColor: 'rgba(75, 192, 192, 1)', // Color del borde del punto
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
        //   text: 'Month',
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
        },
      },
      y: {
        title: {
          display: true,
        //   text: 'Sales',
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            family: "'Arial', sans-serif",
            size: 12,
          },
          callback: function(value) {
            return `${value}`; // Añade el símbolo de dólar a los valores del eje Y
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            return `${tooltipItems[0].raw}`;
          },
          label: function(tooltipItem) {
            return `${tooltipItem.label}`;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo del tooltip
        titleFont: {
          family: "'Arial', sans-serif",
          size: 14,
          weight: 'bold',
          color: '#fff',
        },
        bodyFont: {
          family: "'Arial', sans-serif",
          size: 12,
          color: '#fff',
        },
        displayColors: false, // Oculta la barra de color del dataset en el tooltip
      },
      legend: {
        display: false, // Muestra la leyenda
        labels: {
          font: {
            family: "'Arial', sans-serif",
            size: 20,
          },
        },
      },
    },
    interaction: {
      mode: 'nearest', // Detección del punto más cercano
      axis: 'x', // Sólo activa la interacción en el eje X
      intersect: false, // Permite que el tooltip se active cuando está cerca del punto
    },
    hover: {
      mode: 'nearest', // Detección del punto más cercano al mouse
      intersect: false, // Permite que el punto se muestre cuando el mouse está cerca
    },
  };

  return (
    <Line data={data} options={options} />
  );
};

export default LineeChart;
