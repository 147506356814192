import React, { useEffect } from 'react';
import './SuccessStripe.css'; // Asegúrate de tener los estilos adecuados

const SuccessStripe = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/'; // Redirige a la página principal o a otra parte después de unos segundos
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="success-popup-overlay">
      <div className="success-popup">
        <h2>Payment Successful!</h2>
        <p>Your subscription has been activated successfully.</p>
        {/*<button onClick={() => window.location.href = '/'}>Close</button>*/}
      </div>
    </div>
  );
};

export default SuccessStripe;
