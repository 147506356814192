import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const HalvingToNow = ({ data }) => {
  console.log("DATAAAAA: ", data);
  const svgRef = useRef();
  const [tooltipData, setTooltipData] = useState(null); // Estado para los datos del tooltip
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 }); // Estado para la posición del tooltip

  useEffect(() => {
    if (!data || data.length === 0) return;

    const margin = { top: 20, right: 30, bottom: 30, left: 60 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3.select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const yDomain = [d3.min(data, d => d["Percentage Change"]) > 0 ? 0 : d3.min(data, d => d["Percentage Change"]), d3.max(data, d => d["Percentage Change"])];
    const y = d3.scaleLinear().domain(yDomain).nice().range([height, 0]);
    const x = d3.scaleBand().range([0, width]).domain(data.map(d => d.Ticker)).padding(0.2);

    svg.append("g").attr("transform", `translate(0,${height})`).call(d3.axisBottom(x).tickSize(0));    
    svg.append("g").call(d3.axisLeft(y).tickFormat(d => `${d}%`).tickSize(0));

    svg.selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", d => x(d.Ticker) + x.bandwidth() / 4)
      .attr("y", d => Math.min(y(0), y(d["Percentage Change"])))
      .attr("width", x.bandwidth() / 2)
      .attr("height", d => Math.abs(y(d["Percentage Change"]) - y(0)))
      .attr("fill", d => d["Percentage Change"] < 0 ? "red" : "#133588")
      .attr("rx", 10)
      .on("mouseover", (event, d) => {
        setTooltipData(d); // Establece los datos para mostrar en el tooltip
        setTooltipPos({ x: event.pageX, y: event.pageY }); // Ajusta la posición basada en la posición del cursor
      })
      .on("mouseout", () => {
        setTooltipData(null); // Limpia los datos del tooltip para ocultarlo
      });
  }, [data]);

  return (
    <>
      <svg ref={svgRef}></svg>
      {tooltipData && (
        <div
          style={{
            position: "absolute",
            left: `${tooltipPos.x}px`,
            top: `${tooltipPos.y}px`,
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            pointerEvents: "none", // Asegura que el div no interfiera con el mouseover
            transform: "translate(-50%, -100%)", // Ajusta para que el tooltip no se corte en los extremos
          }}
        >
          <div><b>Ticker:</b> {tooltipData.Ticker}</div>
          <div><b>Start Date:</b> {tooltipData["Start Date"]}</div>
          <div><b>End Date:</b> {tooltipData["End Date"]}</div>
          <div><b>Start Price:</b> {tooltipData["Start Price"].toFixed(2)}</div>
          <div><b>End Price:</b> {tooltipData["End Price"].toFixed(2)}</div>
          <div><b>% Change:</b> {tooltipData["Percentage Change"].toFixed(2)}%</div>
        </div>
      )}
    </>
  );
};

export default HalvingToNow;
