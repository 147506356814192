import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

const LineChart = ({ticker, texts, values}) => {
  console.log("values123123:VALUES "+ticker, values)
  console.log("values123123:TEXT "+ticker, texts)
  const data = {
    labels: texts,//["January", "February", "March", "April", "May", "June", "July"], // Etiquetas para el eje X
    datasets: [
      {
        label: ticker, //"Sales 2024", // Etiqueta del dataset
        data: values,//[65, 59, 80, 81, 56, 55, 40], // Datos para el gráfico
        fill: false, // No rellenar el área bajo la línea
        borderColor: "rgba(75, 192, 192, 1)", // Color de la línea
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Color de fondo de los puntos
        pointBorderColor: "rgba(75, 192, 192, 1)", // Color del borde de los puntos
        // pointBackgroundColor: "#fff", // Color de fondo de los puntos
        // pointBorderWidth: 2, // Ancho del borde de los puntos
        // pointRadius: 5, // Tamaño de los puntos
        // tension: 0.4, // Curvatura de la línea

        pointRadius: 1, // Desactiva los puntos
        pointHoverRadius: 5, // Mostrar los puntos al hacer hover
        pointBorderWidth: 0, // Desactiva el borde de los puntos
        pointBackgroundColor: "rgba(75, 192, 192, 1)", //"transparent", // Hace transparente el fondo de los puntos
      
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true, // Muestra la leyenda
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `Sales: ${tooltipItem.raw.toFixed(2)}%`;
          },
        },
      },
    },
    interaction: {
      mode: 'nearest', // Detección del punto más cercano
      axis: 'x', // Sólo activa la interacción en el eje X
      intersect: false, // Permite que el tooltip se active cuando está cerca del punto
    },
    hover: {
      mode: 'nearest', // Detección del punto más cercano al mouse
      intersect: false, // Permite que el punto se muestre cuando el mouse está cerca
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months', // Título del eje X
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Sales (in thousands)', // Título del eje Y
          font: {
            family: "'Arial', sans-serif",
            size: 14,
            weight: 'bold',
          },
        },
        beginAtZero: true, // Asegura que el eje Y comience en cero
      },
    },
  };

  return (
    // <div className="w-full max-w-lg mx-auto">
    //   <Line data={data} options={options} />
    // </div>
    <div style={{ width: '100%' }}>
      <div style={{ height: '450px' }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default LineChart;
