import React, { useEffect, useRef } from "react";
import LineChart from "./LineChart"
import * as d3 from "d3";
import "./PercentageChangeTable.css";


const PercentageChangeTable = ({ 
  data, 
  dataType, 
  handleDataChange,
  cryptoData,
  stockData }) => {
    console.log("PERCENTEJE CHANGE: ", data);
    console.log("PERCENTEJE CHANGE:cryp ", cryptoData);
    console.log("PERCENTEJE CHANGE:stock ", stockData);
  const tableRef = useRef(null);

  useEffect(() => {
    const renderTable = (data) => {
      const container = d3.select(tableRef.current);

      // Clear previous table
      container.selectAll("*").remove();

      // Create a temporary container for parsing the HTML string
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = data;

      const table = container
        .append("table")
        .attr("class", "percentage-change-table");

      const thead = table.append("thead");
      const tbody = table.append("tbody");

      // Append table headers
      const headers = Array.from(tempContainer.querySelectorAll("th")).map(
        (th) => th.textContent
      );
      thead
        .append("tr")
        .selectAll("th")
        .data(headers)
        .enter()
        .append("th")
        .text((d) => d);

      // Append table rows
      const rows = Array.from(tempContainer.querySelectorAll("tr")).slice(1); // Exclude the header row
      tbody
        .selectAll("tr")
        .data(rows)
        .enter()
        .append("tr")
        .html((d) => d.innerHTML);

      return table;
    };

    renderTable(data);
  }, [data]);

  return (
    <div className="percentage-change-table-container">
      <div ref={tableRef} />
      <div className="percentage-change-data-type-buttons">
        <button
          className={`percentage-change-data-type-button ${
            dataType === "5. adjusted close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("5. adjusted close")}
        >
          Adjusted Close
        </button>
        <button
          className={`percentage-change-data-type-button ${
            dataType === "4. close" ? "active" : ""
          }`}
          onClick={() => handleDataChange("4. close")}
        >
          Close
        </button>
      </div>
      {/* <LineChart /> */}
      
      {Object.entries(stockData).map(([key, value]) => (
        <LineChart 
          ticker={key} 
          texts={Object.keys(value).map((key) => key.split(" ")[0] || '')}
          values={Object.values(value).map(valor => valor === "nan" ? 0 : (parseFloat(valor) * 100))}
          />
      ))}
      {Object.entries(cryptoData).map(([key, value]) => (
        // <LineChart 
        //   ticker={key} 
        //   texts={Object.keys(value).map(text => text || '')}
        //   values={Object.values(value).map(valor => valor === "nan" ? 0 : parseFloat(valor))}
        //   />
          <LineChart 
            ticker={key} 
            texts={Object.keys(value).map((key) => key.split(" ")[0] || '')}
            values={Object.values(value).map(valor => valor === "nan" ? 0 : (parseFloat(valor) * 100))}
            />
      ))}
    </div>
  );
};

export default PercentageChangeTable;
